<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="page-title-text">
          <h2>Log In</h2>
          <ul>
            <li><a routerLink="/">Home</a></li>
            <li><i class="icofont-simple-right"></i></li>
            <li>Log In</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="login-area pt-5">
  <div class="container">
    <h2>Login To Account</h2>

    <div class="login-wrap">
      <div class="row">
        <div class="col-sm-6 col-lg-6">
          <div class="jobseeker-item" [ngClass]="isCandidateSelected ? 'is-selected' : ''">
            <div class="jobseeker-icon">
              <i class="flaticon-job-search"></i>
            </div>

            <div class="jobseeker-inner">
              <span>As a Candidate</span>
              <h3>Find jobs quickly</h3>
            </div>

            <a (click)="setSelected()">
              <i class="icofont-money-bag"></i>
            </a>
          </div>
        </div>

        <div class="col-sm-6 col-lg-6">
          <div class="jobseeker-item" [ngClass]="isCandidateSelected ? '' : 'is-selected'">
            <div class="jobseeker-icon">
              <i class="flaticon-recruitment"></i>
            </div>

            <div class="jobseeker-inner">
              <span>As an Employer</span>
              <h3>Find candidates quickly</h3>
            </div>

            <a (click)="setSelected()">
              <i class="icofont-search-job"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="text-center">
          <div class="form-group">
            <input type="text" class="form-control" placeholder="Enter Email" formControlName="email">
            <div *ngIf="submitted && f.email.errors" class="is-invalid">
              <div *ngIf="f.email.errors.required">Email required</div>
              <div *ngIf="f.email.errors.email ||
                       f.email.errors.pattern"> Email must be a valid email address </div>
            </div>
          </div>
          <div class=" form-group">
            <input type="password" class="form-control" placeholder="Enter Password" formControlName="password">
            <div *ngIf="submitted && f.password.errors" class="is-invalid">
              <div *ngIf="f.password.errors.required">Password required</div>
            </div>
          </div>
        </div>
      </div>
      <div class="login-sign-in">
        <a routerLink="/authenticate/forgot-password">Forgot Password?</a>

        <ul>
          <li>Don’t Have Account ?</li>
          <li><a routerLink="/authenticate/create-account">Sign Up Here</a></li>
        </ul>

        <div class="text-center">
          <button type="submit" class="btn login-btn">Sign In</button>
        </div>
      </div>
    </form>
  </div>
</div>
