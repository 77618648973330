<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="page-title-text">
          <h2>Candidate Dashboard</h2>
          <ul>
            <li><a routerLink="/">Home</a></li>
            <li><i class="icofont-simple-right"></i></li>
            <li>Candidate Dashboard</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="dashboard-area pt-5">
  <div class="container">
    <div *ngIf="!user?.is_active" class="alert alert-warning" role="alert">
      <i class="icofont-warning-alt"></i>
      Your profile is not yet visible to <b>employers</b>, because it is still under review.
    </div>
    <form
      [formGroup]="candidateDashboardForm"
      (ngSubmit)="onSubmit()"
      (keydown.enter)="$event.preventDefault()"
    >
      <div class="row align-items-center">
        <div class="col-lg-4">
          <div class="dashboard-img">
            <img src="{{ user.image }}" alt="Dashboard">
            <div
              class='status-circle'
              [ngClass]="user?.is_active === 1 ? 'online' : 'offline'">
            </div>
            <h3>{{ user.firstname + ' ' + user.lastname }}</h3>
            <p>{{ user.job_title }}</p>
          </div>
        </div>

        <div class="col-lg-8">
          <div class="dashboard-nav">
            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="pills-home-tab"
                  data-toggle="pill"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                  (click)="goToProfile()"
                >
                  My Profile
                </a>
              </li>

              <li class="nav-item">
                <a
                  class="nav-link"
                  id="pills-profile-tab"
                  data-toggle="pill"
                  routerLink="/settings"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                >
                  Change Password
                </a>
              </li>

              <!-- TODO: Shall decide whether still needed
              <!--li class="nav-item">
                <a class="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">Saved Jobs</a>
              </li-->

              <li class="nav-item">
                <a
                  class="nav-link"
                  id="pills-logout-tab"
                  data-toggle="pill"
                  role="tab"
                  aria-controls="pills-logout"
                  aria-selected="false"
                  (click)="onLogout()"
                >
                  Logout
                </a>
              </li>
            </ul>
          </div>

          <div class="create-skills">
            <div class="create-skills-wrap">
              <div class="create-skills-left">
                <h3>Applications ({{jobsAppliedTo?.length}})</h3>
              </div>
            </div>
            <div class="row title-container">
              <div class="col-lg-4">
                <div class="form-group">
                  <p>Job Title</p>
                </div>
              </div>
              <div class="col-lg-5">
                <div class="form-group">
                  <p>Company</p>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <p>Status</p>
                </div>
              </div>
            </div>
            <ul>
              <li *ngFor="let job of jobsAppliedTo; let i = index;">
                <div *ngIf="job" class="row">
                  <div class="col-lg-4">
                    <div class="form-group">
                      <div class="mobile-element title" [ngClass]="{'reviewed-application': job.applicationStatus === 'Reviewed'}">{{(i + 1) + '. ' + job.title}}</div>
                      <label [ngClass]="{'reviewed-application': job.applicationStatus === 'Reviewed'}">
                        {{(i + 1) + '. ' + job.title}}
                      </label>
                    </div>
                  </div>

                  <div class="col-lg-5">
                    <div class="form-group">
                      <label [ngClass]="{'reviewed': job.applicationStatus === 'Reviewed'}">
                        {{job.company}}
                      </label>
                    </div>
                  </div>

                  <div class="col-lg-3">
                    <div class="form-group">
                      <label [ngClass]="{'reviewed-application': job.applicationStatus === 'Reviewed'}">
                        <i
                          [ngClass]="job.applicationStatus === 'Reviewed' ? 'icofont-check-circled' : 'icofont-clock-time'"
                        >
                        </i> {{job.applicationStatus}}</label>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
          <div class="create-information">
            <h3>Basic Information</h3>

              <div class="create-information-btn">
                <a (click)="uploadCV()">Upload New CV</a>
                <span>{{cvImage}}</span>
                <i *ngIf="uploadedCV" class="icofont-close remove-cv-file" (click)="onRemoveCV()"></i>
                <a *ngIf="!uploadedCV" href="{{ user.cv }}" target="_blank">View current CV</a>
                <span *ngIf="submitted && f.cv.errors?.required" class="is-invalid">
                  CV required
                </span>
                <input
                  type="file"
                  class="form-control cv-upload"
                  accept="application/doc, application/pdf"
                  formControlName="cv"
                  (change)="onCVChange($event)"
                >
              </div>

              <div class="row availability">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label>Availability</label>
                    <app-select
                      [defaultText]="'--- Select Availability ---'"
                      [selectTexts]="selectLabels"
                      [invalidInput]="submitted && f.availability.invalid"
                      formControlName="availability"
                      [formGroup]="candidateDashboardForm"
                      (valueChangedEvent)="setSelectValue($event)"
                    ></app-select>
                    <div *ngIf="submitted && f.availability?.errors?.required" class="is-invalid">
                      Availability required
                    </div>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="form-group">
                    <label>Feature Account</label>
                    <input
                      type="text"
                      class="form-control"
                      [attr.disabled]="true"
                      formControlName="feature"
                    >
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label>Your Name</label>
                    <input
                      type="text"
                      class="form-control"
                      [attr.disabled]="true"
                      formControlName="fullName"
                    >
                    <div *ngIf="submitted && f.fullName.errors" class="is-invalid">
                      <div *ngIf="f.fullName.errors.required">Name required</div>
                    </div>
                  </div>
              </div>

              <div class="col-lg-6">
                <div class="form-group">
                  <label>Your Email</label>
                    <input
                      type="email"
                      class="form-control"
                      [attr.disabled]="true"
                      formControlName="email"
                    >
                </div>
              </div>

              <div class="col-lg-6">
                <div class="form-group">
                  <label>Date of Birth</label>
                  <input
                    type="text"
                    class="form-control"
                    [attr.disabled]="true"
                    formControlName="birthDate"
                  >
                </div>
              </div>

              <div class="col-lg-6">
                <div class="form-group">
                  <label>Your Telephone</label>
                  <ngx-intl-tel-input
                    [preferredCountries]="preferredCountries"
                    [enableAutoCountrySelect]="true"
                    [enablePlaceholder]="true"
                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                    [selectedCountryISO]="CountryISO.Uganda"
                    [phoneValidation]="true"
                    [customPlaceholder]="772123456"
                    [numberFormat]="772123456"
                    name="phone"
                    formControlName="telephone">
                  </ngx-intl-tel-input>
                  <div *ngIf="submitted && f.telephone.errors" class="is-invalid">
                    <div *ngIf="f.telephone.errors.required">telephone required</div>
                    <div *ngIf="f.telephone.errors?.validatePhoneNumber">Invalid Number</div>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="form-group">
                  <label>Profession</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="profession"
                  >
                  <div *ngIf="submitted && f.profession.errors" class="is-invalid">
                    <div *ngIf="f.profession.errors.required">Profession required</div>
                    <div *ngIf="f.profession.errors.minlength">Must be at least 6 characters</div>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="form-group">
                  <label>Address:</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="address"
                  >
                  <div *ngIf="submitted && f.address.errors" class="is-invalid">
                    <div *ngIf="f.address.errors.required">Address required</div>
                  </div>
                </div>
              </div>

              <div class="col-lg-12">
                <div class="form-group">
                  <label>About Me</label>
                  <textarea
                    id="your_message"
                    class="form-control"
                    rows="8"
                    formControlName="aboutMe"
                  >
                  </textarea>
                  <div *ngIf="submitted && f.aboutMe.errors" class="is-invalid">
                    <div *ngIf="f.aboutMe.errors.required">About Me required</div>
                    <div *ngIf="f.aboutMe.errors.minlength">Must be at least 100 characters</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- TODO: Decide whether this is still needed or not -->
          <!--div class="create-education create-education-two">
            <div class="create-education-wrap">
              <div class="create-education-left">
                <h3>Education</h3>
              </div>
            </div>
            <div
              class="education-container"
              formArrayName="education"
              *ngFor="let education of education.controls; let i = index"
            >
            <div> {{'Education ' + (i + 1) }} </div>
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label>Title</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="{{'Enter education title ' + (i + 1)}}"
                      formControlName="educationTitle"
                    >
                    <div *ngIf="submitted && f.educationTitle.status === 'INVALID'" class="is-invalid">
                      {{'Education title ' + (i + 1) + ' required ' }}
                    </div>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="form-group">
                    <label>Degree</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="{{'Enter degree ' + (i + 1)}}"
                      formControlName="degree"
                    >
                    <div *ngIf="submitted && f.degree.status === 'INVALID'" class="is-invalid">
                      {{'Degree ' + (i + 1) + ' required ' }}
                    </div>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="form-group">
                    <label>Institute</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="{{'Enter institute ' + (i + 1)}}"
                      formControlName="institute"
                    >
                    <div *ngIf="submitted && f.institute.status === 'INVALID'" class="is-invalid">
                      {{'Institute ' + (i + 1) + ' required ' }}
                    </div>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="form-group">
                    <label>Year</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="{{'Enter year ' + (i + 1)}}"
                      formControlName="year"
                    >
                    <div *ngIf="submitted && f.year.status === 'INVALID'" class="is-invalid">
                      {{'Year ' + (i + 1) + ' required ' }}
                    </div>
                  </div>
                </div>
                <button *ngIf="i > 0" class="remove-btn" (click)="removeElement(i, 'education')">Remove</button>

              </div>
            </div>
            <button class="add-btn" (click)="addElement($event, 'education')">Add Item</button>

          </div-->

          <div class="create-education">
            <div class="create-education-wrap">
              <div class="create-education-left">
                <h3>Highest Education</h3>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-6">
                <div class="form-group">
                  <label>Title: *</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="educationTitle"
                  >
                  <div *ngIf="submitted && f.educationTitle.errors" class="is-invalid">
                    <div *ngIf="f.educationTitle.errors.required">Education Title required</div>
                    <div *ngIf="f.educationTitle.errors.minlength">Must be at least 6 characters</div>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="form-group">
                  <label>Qualification: *</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="qualification"
                  >
                  <div *ngIf="submitted && f.qualification.errors" class="is-invalid">
                    <div *ngIf="f.qualification.errors.required">Qualification required</div>
                    <div *ngIf="f.qualification.errors.minlength">Must be at least 6 characters</div>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="form-group">
                  <label>Institute: *</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="institute"
                  >
                  <div *ngIf="submitted && f.institute.errors" class="is-invalid">
                    <div *ngIf="f.institute.errors.required">Institute required</div>
                    <div *ngIf="f.institute.errors.minlength">Must be at least 6 characters</div>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="form-group">
                  <label>Year: *</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="year"
                  >
                  <div *ngIf="submitted && f.year.errors" class="is-invalid">
                    <div *ngIf="f.year.errors.required">Year required</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="create-skills">
            <div class="create-skills-wrap">
              <div class="create-skills-left">
                <h3>Skill</h3>
              </div>
            </div>
            <div
              class="skill-qualification-container"
              formArrayName="skills"
              *ngFor="let skill of skills.controls; let i = index"
            >
              <div class="row" [formGroup]="skill">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label>{{'Title ' + (i + 1)}}</label>
                    <div class="skill-qualification" >
                      <input
                        type="text"
                        class="form-control"
                        placeholder="{{'Enter skill ' + (i + 1)}}"
                        formControlName="skillTitle"
                      >
                      <div *ngIf="submitted && f.skills?.status === 'INVALID'" class="is-invalid">
                        {{'Skill title ' + (i + 1) + ' required' }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="skill-level">
                    <label>{{'Skill Level ' + (i + 1)}}</label>
                    <app-slider
                      [id]="'skillLevelSlider' + i"
                      [bubbleId]="'skillLevelId' + i"
                      [minLabel]="sliderMin"
                      [maxLabel]="sliderMax"
                      [min]="sliderMin"
                      [max]="sliderMax"
                      [value]="sliderMin"
                      formControlName="skillLevel"
                    >
                    </app-slider>
                  </div>
                </div>
                <div class="remove-btn-container">
                  <button
                    *ngIf="i > 0"
                    class="white-to-green-simulated-hover-btn"
                    (click)="removeElement(i, 'skills')"
                  >
                    <i class="icofont-ui-close"></i> Remove
                  </button>
                </div>
              </div>
            </div>
            <button class="green-white-simulated-hover-btn" (click)="addElement($event, 'skills')">
              <i class="icofont-ui-add"></i> Add Skill
            </button>
          </div>
          <div *ngIf="submitted && candidateDashboardForm.invalid" class="alert alert-danger" role="alert">
            <i class="icofont-warning-alt"></i>
            The form contains one or more invalid input.
          </div>
          <div class="text-left">
            <button type="submit" class=" btn green-gradient-hover"> Save </button>
          </div>
        </div>

        <!-- TODO: implement later -->
        <!-- <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
          <div class="change-password-area">
            <h2>Change Your Password</h2>

            <div class="form-group">
              <label>Current Password:</label>
              <input type="password" class="form-control">
            </div>

            <div class="form-group">
              <label>New Password:</label>
              <input type="password" class="form-control">
            </div>

            <div class="form-group">
              <label>Confirm Password:</label>
              <input type="password" class="form-control">
            </div>

            <div class="text-left">
              <button type="submit" class="btn change-pass-btn">Save</button>
            </div>
          </div>
        </div> -->

        <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
          <div class="dashboard-saved-job">
            <div class="row">
              <div class="col-lg-6">
                <div class="job-item wow fadeInUp" data-wow-delay=".3s">
                  <div class="job-inner align-items-center">
                    <div class="job-inner-left">
                      <h3>UI/UX Designer</h3>
                      <a routerLink="/company-details">Winbrans.com</a>
                      <ul>
                        <li><i class="icofont-money-bag"></i> $20k - $25k</li>
                        <li><i class="icofont-location-pin"></i> Location 210-27 Quadra, Market Street, Victoria Canada</li>
                      </ul>
                    </div>

                    <div class="job-inner-right">
                      <ul>
                        <li><a routerLink="/create-account">Apply</a></li>
                        <li><span>Full Time</span></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="job-item wow fadeInUp" data-wow-delay=".4s">
                  <img src="assets/img/home-1/jobs/2.png" alt="Job">

                  <div class="job-inner align-items-center">
                    <div class="job-inner-left">
                      <h3>Android Developer</h3>
                      <a routerLink="/company-details">Infiniza.com</a>
                      <ul>
                        <li><i class="icofont-money-bag"></i> $20k - $25k</li>
                        <li><i class="icofont-location-pin"></i> Location 210-27 Quadra, Market Street, Victoria Canada</li>
                      </ul>
                    </div>

                    <div class="job-inner-right">
                      <ul>
                        <li><a routerLink="/create-account">Apply</a></li>
                        <li><span>Part Time</span></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="job-item wow fadeInUp" data-wow-delay=".3s">
                  <img src="assets/img/home-1/jobs/3.png" alt="Job">

                  <div class="job-inner align-items-center">
                    <div class="job-inner-left">
                      <h3>Senior Manager</h3>
                      <a routerLink="/company-details">Glovibo.com</a>
                      <ul>
                        <li><i class="icofont-money-bag"></i> $20k - $25k</li>
                        <li><i class="icofont-location-pin"></i> Location 210-27 Quadra, Market Street, Victoria Canada</li>
                      </ul>
                    </div>

                    <div class="job-inner-right">
                      <ul>
                        <li><a routerLink="/create-account">Apply</a></li>
                        <li><span>Intern</span></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="job-item wow fadeInUp" data-wow-delay=".4s">
                  <img src="assets/img/home-1/jobs/4.png" alt="Job">

                  <div class="job-inner align-items-center">
                    <div class="job-inner-left">
                      <h3>Product Designer</h3>
                      <a routerLink="/company-details">Bizotic.com</a>
                      <ul>
                        <li><i class="icofont-money-bag"></i> $20k - $25k</li>
                        <li><i class="icofont-location-pin"></i> Location 210-27 Quadra, Market Street, Victoria Canada</li>
                      </ul>
                    </div>

                    <div class="job-inner-right">
                      <ul>
                        <li><a routerLink="/create-account">Apply</a></li>
                        <li><span>Part Time</span></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="tab-pane fade" id="pills-logout" role="tabpanel" aria-labelledby="pills-logout-tab">
          <div class="login-area dashboard-logout-area">
            <div class="login-wrap">
              <div class="row">
                <div class="col-sm-6 col-lg-6">
                  <div class="jobseeker-item">
                    <div class="jobseeker-icon">
                      <i class="flaticon-job-search"></i>
                    </div>

                    <div class="jobseeker-inner">
                      <span>Candidate</span>
                      <h3>Login as a Candidate</h3>
                    </div>

                    <a routerLink="/login">Get Started
                      <i class="icofont-arrow-right"></i>
                    </a>
                  </div>
                </div>

                <div class="col-sm-6 col-lg-6">
                  <div class="jobseeker-item">
                    <div class="jobseeker-icon">
                      <i class="flaticon-recruitment"></i>
                    </div>

                  <div class="jobseeker-inner">
                    <span>Employer</span>
                    <h3>Login as a Employer</h3>
                  </div>

                  <a routerLink="/login">Get Started
                    <i class="icofont-arrow-right"></i>
                  </a>
                  </div>
                </div>
              </div>
            </div>

            <form>
              <div class="form-group">
                <input type="text" class="form-control" placeholder="Username, Phone Number or Email">
              </div>

              <div class="form-group">
                <input type="password" class="form-control" placeholder="Password">
              </div>
            </form>

            <div class="login-sign-in">
              <a href="#">Forgot Password?</a>
              <ul>
                <li>Don’t Have Account ?</li>
                <li><a routerLink="/create-account">Sign Up Here</a></li>
              </ul>
              <div class="text-center">
                <button type="submit" class="btn login-btn">Sign In</button>
              </div>
            </div>

            <div class="login-social">
              <a href="#" target="_blank"><i class="icofont-facebook"></i> Login With Facebook</a>
              <a class="login-google" href="#" target="_blank"><i class="icofont-google-plus"></i> Login With Google</a>
            </div>
          </div>
        </div> -->
      </div>
    </form>
  </div>
</div>
