<footer>
  <div class="container">
    <div class="subscribe-area">
      <div class="section-title">
        <h2>Subscribe to Our Newsletter</h2>
      </div>

      <div class="subscribe-shape">
        <img src="assets/img/shape/1.png" alt="Shape">
        <img src="assets/img/shape/1.png" alt="Shape">
        <img src="assets/img/shape/1.png" alt="Shape">
        <img src="assets/img/shape/1.png" alt="Shape">
        <img src="assets/img/shape/2.png" alt="Shape">
      </div>

      <div class="subscribe-item">
        <form class="newsletter-form" [formGroup]="newsletterForm" (ngSubmit)="onSubmit()">
          <input
            type="email"
            class="form-control"
            placeholder="Enter Your Email"
            name="EMAIL"
            required
            autocomplete="off"
            formControlName="email"
          >
          <div *ngIf="submitted && f.email.errors" class="is-invalid">
            <div *ngIf="f.email.errors.required">Email required</div>
            <div *ngIf="f.email.errors.email || f.email.errors.pattern">
              Email must be a valid email address</div>
          </div>
          <button class="btn subscribe-btn" type="submit">Subscribe</button>
        </form>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6 col-lg-3">
        <div class="footer-item">
          <div class="footer-logo">
            <a routerLink="/"><img src="assets/img/footer-logo.png" alt="Logo"></a>
            <p>We help you to find better Jobs or Candidates to fill you open positions quickly and in the most reliable way</p>
            <ul>
              <li><a href="https://www.youtube.com/watch?v=yacIXAFwkKc" target="_blank"><i class="icofont-youtube-play"></i></a></li>

              <!-- TODO: Shall uncomment after creating Skillcrawler facebook page -->
              <!--li><a href="#" target="_blank"><i class="icofont-facebook"></i></a></li-->

              <li><a href="https://www.instagram.com/skillcrawler/?igshid=YmMyMTA2M2Y%3D" target="_blank"><i class="icofont-instagram"></i></a></li>
              <li><a href="https://twitter.com/skillcrawler" target="_blank"><i class="icofont-twitter"></i></a></li>
            </ul>
          </div>
        </div>
      </div>

      <div class="col-sm-6 col-lg-3">
        <div class="footer-item">
          <div class="footer-category">
            <h3>Category</h3>

            <ul>
              <li>
                <a (click)="handleCategory('IT & Technology')">
                  <i class="icofont-simple-right"></i>
                  IT & Technology
                </a>
              </li>
              <li>
                <a (click)="handleCategory('Technical Support')"y>
                  <i class="icofont-simple-right"></i>
                  Technical Support
                </a>
              </li>
              <li>
                <a (click)="handleCategory('Restaurant Services')">
                  <i class="icofont-simple-right"></i>
                  Restaurant Services
                </a>
              </li>
              <li>
                <a (click)="handleCategory('Real Estate Services')">
                  <i class="icofont-simple-right"></i>
                  Real Estate Services
                </a>
              </li>
              <li>
                <a (click)="handleCategory('Finance & Banking')">
                  <i class="icofont-simple-right"></i>
                  Finance & Banking
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="col-sm-6 col-lg-3">
        <div class="footer-item">
          <div class="footer-category">
            <h3>Quick Links</h3>

            <ul>
              <li><a routerLink="/"><i class="icofont-simple-right"></i> Home</a></li>
              <li><a routerLink="/about"><i class="icofont-simple-right"></i> About Us</a></li>
              <li><a routerLink="/faq"><i class="icofont-simple-right"></i> FAQ</a></li>
              <li><a routerLink="/privacy-policy"><i class="icofont-simple-right"></i> Privacy Policy</a></li>
              <li><a routerLink="/terms-and-conditions"><i class="icofont-simple-right"></i> Terms and Conditions</a></li>
            </ul>
          </div>
        </div>
      </div>

      <div class="col-sm-6 col-lg-3">
        <div class="footer-item">
          <div class="footer-find">
            <h3>Find Us</h3>

            <ul>
              <li><i class="icofont-location-pin"></i> Kisakye Complex Spring Rd Bugolobi, Kampala</li>
              <li><i class="icofont-ui-call"></i>Tel: +256-759078095 or <div>Tel:  +256-759399558</div></li>

              <li><i class="icofont-envelope"></i> <a href="mailto:info@meshincentre.com"> info@meshincentre.com</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="copyright-area">
      <div class="row">
        <div class="col-lg-6">
          <div class="copyright-item">
            <p>Copyright @{{copyYear}} <a href="https://meshincentre.com/" target="_blank">Mesh Innovation Centre. </a> All
              Rights Reserved.</p>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="copyright-item copyright-right">
            <ul>
              <li><a routerLink="/terms-and-conditions" target="_blank">Terms & Conditions</a></li>
              <li><span>-</span></li>
              <li><a routerLink="/privacy-policy" target="_blank">Privacy Policy</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
