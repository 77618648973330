<div class="account-pages p-5">
  <div class="container">
    <div class="row justify-content-center p-5">
      <div class="col-xl-5 col-lg-6 col-md-8">
        <app-card [bodyClass]="'p-4'">
          <div body>
            <div class="text-center">
              <h6 class="h5 mb-0 mt-4"><i class="icofont-check-circled"></i> Confirm your email</h6>
              <p class="text-muted mt-3 mb-3">Your account has been successfully registered. To
                complete the verification process, please check your email for a validation request.
              </p>
            </div>
          </div>
        </app-card>
        <!-- end row -->

      </div> <!-- end col -->
    </div>
    <!-- end row -->
  </div>
  <!-- end container -->
</div>
<!-- end page -->
