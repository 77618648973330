import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UtilsService } from '../../services/utils.service';
import { AuthenticationService } from '../../services/auth.service';
import { Observable } from 'rxjs/index';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {
  isCandidate: Observable<boolean>;
  isAdmin: Observable<boolean>;
  isLoggedIn: Observable<boolean>;
  isEmployer: boolean = true;

  constructor(
    private router: Router,
    private utilsService: UtilsService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.isLoggedIn = this.authenticationService.isLoggedIn();
    this.isCandidate = this.authenticationService.isCandidateLoggedIn();
    this.isAdmin = this.authenticationService.isAdminLoggedIn();
  }

  formatPrice(price: number) {
    return this.utilsService.formatMoney(price)
  }

  goToContact(id: string) {
    const subject = document.getElementById(id).innerText;
    this.router.navigate(['contact'], {
      state: {data: subject}
    });
  }

  onSwitchChange() {
    this.isEmployer = !this.isEmployer;
  }
}
