<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="page-title-text">
          <h2>Settings</h2>
          <ul>
            <li><a routerLink="/">Home</a></li>
            <li><i class="icofont-simple-right"></i></li>
            <li>Settings</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="create-account-area pt-5 pb-100">
  <div class="container">
    <form [formGroup]="settingsForm" (ngSubmit)="onSubmit()">
      <div class="create-information">

        <div class="row">

          <div class="account-type">
            <div class="form-group">
              <div>
                <p>Application Status:  </p>
              </div>
              <span class="dot online" *ngIf="isOnline"></span>
              <label *ngIf="isOnline" class="online-label">Online</label>
              <div class="dot offline" *ngIf="!isOnline"></div>
              <label *ngIf="!isOnline" class="offline-label">In review</label>
            </div>
          </div>

          <div class="account-type">
            <div class="form-group">
              <div>
                <p>Receive News Letters: </p>
              </div>
              <div>
                <p>Accepting new letters keeps you informed about the most recent jobs that match your profile and our latest features</p>
              </div>
              <input
                type="radio"
                name="newsletters"
                id="yes"
                value="yes"
                formControlName="newsletters"
              >
              <label>Yes</label>
              <input
                type="radio"
                name="newsletters"
                id="no"
                value="no"
                formControlName="newsletters"
              >
              <label>No</label>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label>Current Password: *</label>
              <input
                type="password"
                class="form-control"
                formControlName="currentPassword"
              >
              <div *ngIf="submitted && f.currentPassword.errors" class="is-invalid">
                <div *ngIf="f.currentPassword.errors.required">Current Password required</div>
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label>Confirm Current Password: *</label>
              <input
                type="password"
                class="form-control"
                formControlName="confirmCurrentPassword"
              >
              <div *ngIf="submitted && (f.confirmCurrentPassword.invalid || settingsForm.hasError('isCurrentPasswordNotSame'))"
              class="is-invalid">
                Passwords should match
              </div>
            </div>
          </div>

          <p>Change Password</p>
          <div class="col-lg-6">
            <div class="form-group">
              <label>New Password: *</label>
              <input
                type="password"
                class="form-control"
                formControlName="newPassword"
              >
              <div *ngIf="submitted && f.newPassword.errors" class="is-invalid">
                <div *ngIf="f.newPassword.errors.required">New Password required</div>
                <div *ngIf="f.newPassword.errors.minlength">Must be at least 6 characters</div>
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label>Confirm New Password: *</label>
              <input
                type="password"
                class="form-control"
                formControlName="confirmNewPassword"
              >
              <div *ngIf="submitted && (f.confirmNewPassword.invalid || settingsForm.hasError('isNewPasswordNotSame'))"
                   class="is-invalid">
                Passwords should match
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-left">
        <button type="submit" class="btn create-ac-btn">Save</button>
      </div>
    </form>
  </div>
</div>
