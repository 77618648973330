import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../guards/auth.guard';
import { Role } from '../models/role.model';
import {
  HomeComponent,
  AboutComponent,
  JobListComponent,
  JobResultsComponent,
  FavouriteJobComponent,
  JobDetailsComponent,
  PostAJobComponent,
  EditJobComponent,
  CandidateListComponent,
  CandidateDetailsComponent,
  SingleResumeComponent,
  SubmitResumeComponent,
  PricingComponent,
  CandidateDashboardComponent,
  EmployerDashboardComponent,
  CompanyListComponent,
  EmployerDetailsComponent,
  ProfileComponent,
  SingleProfileComponent,
  FaqComponent,
  TermsAndConditionsComponent,
  PrivacyPolicyComponent,
  ContactComponent,
  BlogDetailsComponent,
  BlogComponent,
  SettingsComponent,
  EditUserComponent,
  AdminComponent,
} from './index';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'about', component: AboutComponent },
  { path: 'job-list', component: JobListComponent },
  { path: 'job-results', component: JobResultsComponent },
  { path: 'job-details', component: JobDetailsComponent },
  {
    path: 'post-a-job',
    component: PostAJobComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Employer] }
  },
  {
    path: 'edit-job', component: EditJobComponent
  },
  {
    path: 'candidate-list',
    component: CandidateListComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Employer] }
  },
  { path: 'candidate-details', component: CandidateDetailsComponent },
  { path: 'pricing', component: PricingComponent },
  {
    path: 'candidate-dashboard',
    component: CandidateDashboardComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Candidate] }
  },
  {
    path: 'employer-dashboard',
    component: EmployerDashboardComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Employer] }
  },
  { path: 'profile', component: ProfileComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'terms-and-conditions', component: TermsAndConditionsComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },

  /* TODO: Shall uncomment after fixing blog and comments display issues */
 /* { path: 'blog', component: BlogComponent },
  { path: 'blog-details', component: BlogDetailsComponent },*/


  { path: 'contact', component: ContactComponent },
  { path: 'settings', component: SettingsComponent },
  {
    path: 'admin', component: AdminComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin] }
  },
  { path: 'edit-user', component: EditUserComponent },
  { path: 'admin', component: AdminComponent },
  { path: 'employer-details',
    component: EmployerDetailsComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Employer] }
  },

  // TODO: Shall check if still needed
  /*{
    path: 'company-list',
    component: CompanyListComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Candidate] }
  },
  { path: 'single-resume', component: SingleResumeComponent },
  { path: 'submit-resume', component: SubmitResumeComponent },
  { path: 'single-profile', component: SingleProfileComponent },
  { path: 'favourite-job', component: FavouriteJobComponent },
  */
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule {
}


