<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="page-title-text">
          <h2>FAQ</h2>
          <ul>
            <li><a routerLink="/">Home</a></li>
            <li><i class="icofont-simple-right"></i></li>
            <li>FAQ</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<section class="faq-area pt-5">
  <div class="container">
    <div class="row faq-wrap">
      <div class="col-lg-12">
        <div class="faq-head">
          <h2>About Job</h2>
        </div>

        <div class="faq-item">
          <app-accordion>
            <ul class="accordion">
              <li class="wow fadeInUp" data-wow-delay=".3s">
                <a>What is skillcrawler?</a>
                <p>Skillcrawler is a one stop site for planning your career goals. It is carefully designed to help candidates and recruiters maximize their growth through career guidance and accurate job recommendations.</p>
              </li>

              <li class="wow fadeInUp" data-wow-delay=".4s">
                <a>How do I start using skillcrawler?</a>
                <p>There is no requirements for joining skillcrawler. Anyone who wants stay up-to-date with the latest jobs can register on skillcralwer. Registration is fast and easy. It is advised that one completes one's profile to find relevant jobs and courses or to increase chances of finding employment.</p>
              </li>

              <li class="wow fadeInUp" data-wow-delay=".5s">
                <a>Can we help you find a job?</a>
                <p>Yes. Skillcrawler offers a variety of tools to help you in your job search, including: </p>
                <p><i class="icofont-star-alt-1 fadeInUp"></i> A list of Job Opportunities.</p>
                <p><i class="icofont-star-alt-1 fadeInUp"></i> We match you with the best Opportunities basing on your skills.</p>
                <p>However, Skillcrawler does not directly present candidates to employers unless that employer is an active client with a job opportunity that matches your skill.</p>
              </li>

              <li class="wow fadeInUp" data-wow-delay=".6s">
                <a>How do I apply?</a>
                <p>Visit our Job list page and view our current open positions. After reviewing the requirements, if your skills meet the specific requirements for one or more positions, please click “Apply” and complete all fields on the application. If no position meets your current interest, you can Submit Resume to us for future consideration.</p>
              </li>

              <li class="wow fadeInUp" data-wow-delay=".7s">
                <a>I saw multiple positions on the website, can I apply for all?</a>
                <p>Yes. Each position has a dedicated recruiter assigned to it. When you apply to a position, the recruiter is notified of your interest and will follow up with you in the event your skills match the requirements of the position.</p>
              </li>

              <li class="wow fadeInUp" data-wow-delay=".8s">
                <a>What should I do if I haven't heard back from an application?</a>
                <p>Due to the volume of applicants that skillcrawler receives for our positions, we appreciate your patience while our recruiters review your background. You will be contacted if there is a fundamental match with an active open position.</p>
              </li>

              <li class="wow fadeInUp" data-wow-delay=".9s">
                <a>How do I ensure that I be considered for current and future positions?</a>
                <p>We recommend applying online for Career Opportunities that are a match for your skills.
                  If no position meets your objectives, Submit Resume directly to us and we will put it in our database which is searchable by recruiters when future positions arise.</p>
              </li>
            </ul>
          </app-accordion>
        </div>
      </div>
    </div>

        <!-- <div class="row faq-wrap">
            <div class="col-lg-12">
                <div class="faq-head">
                    <h2>Web Development</h2>
                </div>

                <div class="faq-item">
                    <ul class="accordion">
                        <li class="wow fadeInUp" data-wow-delay=".3s">
                            <a>What are the advantages of HTTP 2.0 over HTTP 1.1?</a>
                            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Provident porro laboriosam magni labore quaerat, aspernatur iusto error ducimus adipisci, et corporis recusandae illum tenetur hic? Earum excepturi porro fuga ipsum? Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut fugiat, expedita vero et quia saepe debitis exercitationem labore consequatur fuga ab, laudantium sed maiores velit reiciendis. Ullam illo laborum sequi.</p>
                        </li>

                        <li class="wow fadeInUp" data-wow-delay=".4s">
                            <a> What are the new form elements introduced in HTML5?</a>
                            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Provident porro laboriosam magni labore quaerat, aspernatur iusto error ducimus adipisci, et corporis recusandae illum tenetur hic? Earum excepturi porro fuga ipsum? Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sint natus ipsa accusantium saepe dolorem a mollitia tempora consequatur totam modi pariatur nulla, aspernatur dolore consequuntur temporibus unde minima repellendus laboriosam.</p>
                        </li>

                        <li class="wow fadeInUp" data-wow-delay=".5s">
                            <a>What is Frontend?</a>
                            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Provident porro laboriosam magni labore quaerat, aspernatur iusto error ducimus adipisci, et corporis recusandae illum tenetur hic? Earum excepturi porro fuga ipsum? Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sint natus ipsa accusantium saepe dolorem a mollitia tempora consequatur totam modi pariatur nulla, aspernatur dolore consequuntur temporibus unde minima repellendus laboriosam.</p>
                        </li>

                        <li class="wow fadeInUp" data-wow-delay=".6s">
                            <a>What’s the difference between Canvas and SVG?</a>
                            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Provident porro laboriosam magni labore quaerat, aspernatur iusto error ducimus adipisci, et corporis recusandae illum tenetur hic? Earum excepturi porro fuga ipsum? Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sint natus ipsa accusantium saepe dolorem a mollitia tempora consequatur totam modi pariatur nulla, aspernatur dolore consequuntur temporibus unde minima repellendus laboriosam.</p>
                        </li>

                        <li class="wow fadeInUp" data-wow-delay=".7s">
                            <a>What is Backend?</a>
                            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Provident porro laboriosam magni labore quaerat, aspernatur iusto error ducimus adipisci, et corporis recusandae illum tenetur hic? Earum excepturi porro fuga ipsum? Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sint natus ipsa accusantium saepe dolorem a mollitia tempora consequatur totam modi pariatur nulla, aspernatur dolore consequuntur temporibus unde minima repellendus laboriosam.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div> -->

        <!-- <div class="row faq-wrap">
            <div class="col-lg-12">
                <div class="faq-head">
                    <h2>UX/UI</h2>
                </div>

                <div class="faq-item">
                    <ul class="accordion">
                        <li class="wow fadeInUp" data-wow-delay=".3s">
                            <a>What made me go into UX design?</a>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi quis modi ullam amet debitis libero veritatis enim repellat optio natus eum delectus deserunt, odit expedita eos molestiae ipsa totam quidem? Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel, quos mollitia voluptatum aliquam repellendus similique iure fuga aspernatur amet odit! At vitae dicta excepturi quasi? Veritatis, pariatur excepturi! Illum, ut?3</p>
                        </li>

                        <li class="wow fadeInUp" data-wow-delay=".4s">
                            <a>Why do you want to work here?</a>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi quis modi ullam amet debitis libero veritatis enim repellat optio natus eum delectus deserunt, odit expedita eos molestiae ipsa totam quidem? Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel, quos mollitia voluptatum aliquam repellendus similique iure fuga aspernatur amet odit! At vitae dicta excepturi quasi? Veritatis, pariatur excepturi! Illum, ut?3</p>
                        </li>

                        <li class="wow fadeInUp" data-wow-delay=".5s">
                            <a>How will I define UX?</a>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi quis modi ullam amet debitis libero veritatis enim repellat optio natus eum delectus deserunt, odit expedita eos molestiae ipsa totam quidem? Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel, quos mollitia voluptatum aliquam repellendus similique iure fuga aspernatur amet odit! At vitae dicta excepturi quasi? Veritatis, pariatur excepturi! Illum, ut?3</p>
                        </li>

                        <li class="wow fadeInUp" data-wow-delay=".6s">
                            <a>How does UX differ from other design disciplines?</a>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi quis modi ullam amet debitis libero veritatis enim repellat optio natus eum delectus deserunt, odit expedita eos molestiae ipsa totam quidem? Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel, quos mollitia voluptatum aliquam repellendus similique iure fuga aspernatur amet odit! At vitae dicta excepturi quasi? Veritatis, pariatur excepturi! Illum, ut?3</p>
                        </li>

                        <li class="wow fadeInUp" data-wow-delay=".7s">
                            <a>What’s my design process?</a>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi quis modi ullam amet debitis libero veritatis enim repellat optio natus eum delectus deserunt, odit expedita eos molestiae ipsa totam quidem? Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel, quos mollitia voluptatum aliquam repellendus similique iure fuga aspernatur amet odit! At vitae dicta excepturi quasi? Veritatis, pariatur excepturi! Illum, ut?3</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div> -->
    </div>
</section>
