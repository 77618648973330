import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2';
import { Job } from '../../models/job.model';
import { UserProfileService } from '../../services/user.service';
import { AuthenticationService } from '../../services/auth.service';
import { ApplicationService } from '../../services/application.service';
import { User } from '../../models/user.model';
import { first, mergeMap } from 'rxjs/operators';
import { EMPTY } from 'rxjs'
import { Observable } from 'rxjs/index';

@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.scss']
})
export class JobDetailsComponent implements OnInit{
  job: Job;
  employer: User;
  isCandidate: Observable<boolean>;

  constructor(
    private router: Router,
    private userProfileService: UserProfileService,
    private authenticationService: AuthenticationService,
    private applicationService: ApplicationService
  ) {}

  ngOnInit(): void {
    const filteredJobs = history.state.data;
    if (filteredJobs) {
      this.job = JSON.parse(filteredJobs);
      this.userProfileService.getJobEmployer(this.job.user_id)
        .pipe(first()).subscribe(response => this.employer = response['user'][0])
    } else {
      this.router.navigate(['/job-list']);
    }
    this.isCandidate = this.authenticationService.isCandidateLoggedIn();
  }

  onApply() {
    Swal.fire({
      title: 'Do you want to apply now?',
      text: 'Automatic application, no form filling.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#38a745',
      cancelButtonColor: '#808291',
      confirmButtonText: 'Yes, apply'
    }).then((result) => {
      if (result.isConfirmed) {
        this.authenticationService.isLoggedIn().pipe(
          mergeMap(isUser =>
            (isUser ? this.applicationService.postApplication(this.job) : EMPTY)
          )).pipe(first()).subscribe(response => {
            Swal.fire({
              title: 'Application sent!',
              text: 'You will be contacted shortly.',
              icon: 'success',
              confirmButtonColor: '#38a745',
            }).then(() => this.router.navigate(['authenticate/candidate-dashboard']))
          },
          error => {
            this.router.navigate(['authenticate/login']);
          });
      }
    });
  }
}
