import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserProfileService } from '../../services/user.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {
  resetForm: FormGroup;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private userProfileService: UserProfileService,
  ) { }

  ngOnInit() {
    this.resetForm = this.formBuilder.group({
      newPassword: ['', [Validators.required, Validators.minLength(8)]],
      confirmNewPassword: ['', Validators.required],
    }, {validator: this.checkPasswords});
  }

  /**
   * Checks for same passwords
   * @param formGroup element
   * @return notSame: boolean
   */
  checkPasswords(formGroup: FormGroup) {
    const newPass = formGroup.get('newPassword').value;
    const confirmNewPass = formGroup.get('confirmNewPassword').value;
    return newPass === confirmNewPass ? null : { notSame: true };
  }

  get f() { return this.resetForm.controls; }

  /**
   * On submit form
   */
  onSubmit() {
    this.submitted = true;
    if (this.resetForm.invalid) {
      return;
    }
    this.userProfileService.resetPassword({
      password: this.f.newPassword.value,
      password_confirmation: this.f.confirmNewPassword.value,
      token: this.activatedRoute.snapshot.params['token'],
      email: this.activatedRoute.snapshot.queryParams['email']
    })
      .subscribe(
        data => {
          Swal.fire({
            title: 'Password  has been reset!',
            text: 'Login with the new password to access your account',
            icon: 'success',
            confirmButtonColor: '#38a745',
          }
          ).then(() => this.router.navigateByUrl('/authenticate/login'));
        },
        error => {
          Swal.fire({
            title: 'Oops...',
            html: 'Something went wrong!',
            icon: 'error',
            confirmButtonColor: '#f37b7b',
          });
        });
  }
}
