import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../../models/user.model';

@Component({
  selector: 'app-candidate-details',
  templateUrl: './candidate-details.component.html',
  styleUrls: ['./candidate-details.component.scss']
})
export class CandidateDetailsComponent implements OnInit, AfterViewInit {
  user: User;

  constructor(private router: Router) { }

  ngOnInit(): void {
    const filterUsers = history.state.data;
    if (filterUsers) {
      this.user = JSON.parse(filterUsers);
    } else {
      this.router.navigate(['authenticate/candidate-list']);
    }
  }

  ngAfterViewInit() {
    if (this.user) {
      const { skills } = this.user;
      skills.forEach((skill, index) => {
        const element = document.getElementsByClassName('skill-bar')[index] as HTMLElement;
        element.style.width = (skill.skillLevel).toString() + '%';
      });
    }
  }
}
