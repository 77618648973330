import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class BlogService {
  url = 'https://meshincentre.com/wp-json/wp/v2';

  constructor(
    private http: HttpClient,
  ) {}

  /**
   * Get all blog posts
   */
  getBlogPosts() {
    return this.http.get(
      `${this.url}/posts?_embed&categories=81`)
      .pipe(map(response => response));
  }

  /**
   * Get a blog post
   */
  getBlogPost(id: number) {
    return this.http.get(
      `${this.url}/posts/${id}?_embed`)
      .pipe(map(response => response));
  }

  /**
   * Post a comment
   * @param commentData of the blog post
   */
  postComment(commentData: any) {
    return this.http.post(
      `${this.url}/comments`,
      commentData)
      .pipe(map(response => response));
  }

  /**
   * Get a comments for a specific blog post
   * @param id of the blog post
   */
  getComments(id: number) {
    return this.http.get(
      `${this.url}/comments?post=${id}&per_page=20`)
      .pipe(map(response => response));
  }
}
