import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../../services/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  returnUrl: string;
  isCandidateSelected = true;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', [
        Validators.required,
        Validators.email,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      password: ['', Validators.required],
    });
    this.authenticationService.logout();
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    setInterval(() => this.setSelected(), 25000)
  }

  get f() {
    return this.loginForm.controls;
  }

  setSelected() {
    this.isCandidateSelected = !this.isCandidateSelected;
  }

  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.authenticationService.login(this.f.email.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        data => {
          if (this.authenticationService.isCandidate()) {
            if (this.returnUrl !== '/') {
              this.router.navigate([this.returnUrl]);
            } else {
              this.router.navigate(['candidate-dashboard']);
            }
          } else if (this.authenticationService.isAdmin()) {
            this.router.navigate(['admin']);
          } else {
            if (this.returnUrl !== '/') {
              this.router.navigate([this.returnUrl]);
            } else {
              this.router.navigate(['employer-dashboard']);
            }
          }
        },
        error => {
          Swal.fire({
            title: 'An error occurred',
            text: 'email or password invalid',
            icon: 'error',
            confirmButtonColor: '#f37b7b',
          });
        });
  }
}
