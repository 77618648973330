<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="page-title-text">
          <h2>Job Details</h2>
          <ul>
            <li><a routerLink="/">Home</a></li>
            <li><i class="icofont-simple-right"></i></li>
            <li>Job Details</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="job-details-area pt-5">
  <div class="container">
    <div *ngIf="job?.status === 'In review'" class="alert alert-warning" role="alert">
      <i class="icofont-warning-alt"></i>
      This job is not yet visible to <b>candidates</b>, because it is still under review.
    </div>
    <div class="row">
      <div class="col-lg-8">
        <div class="job-details-item">

          <div class="job-description">
            <h2>Title</h2>
            <p>{{job?.title}}</p>
          </div>

          <div class="job-description">
            <h2>Job Description</h2>
            <p>{{job?.description}}</p>
          </div>

          <div class="job-knowledge">
            <h2>Required Knowledge, Skills, and Abilities</h2>

            <ul *ngFor="let skillName of job?.skills">
              <li><i class="icofont-hand-drawn-right"></i> {{skillName.skill}}</li>
            </ul>
          </div>

          <div class="job-knowledge">
            <h2>Education or Qualification</h2>

            <ul *ngFor="let qualificationName of job?.qualifications">
              <li><i class="icofont-hand-drawn-right"></i> {{qualificationName.qualification}}</li>
            </ul>
          </div>

          <div *ngIf="(isCandidate | async)" class="text-center">
            <button class="green-btn btn" (click)="onApply()">Apply Now</button>
          </div>
        </div>
      </div>

      <div class="col-lg-4">
        <div class="job-details-item">
          <div class="job-overview">
            <h3>Overview</h3>

            <ul>
              <li>
                <i class="icofont-dollar"></i>
                <p>Offered Salary</p>
                <span>{{job?.salary ? job?.salary : 'N/A'}}</span>
              </li>

              <li>
                <i class="icofont-building"></i>
                <p>Job Type</p>
                <span>{{ job?.type}}</span>
              </li>

              <!-- TODO: Decide if still needed -->
              <!--li>
                <i class="icofont-badge"></i>
                <p>Experience</p>
                <span>2 Years</span>
              </li-->

              <li>
                <i class="icofont-location-pin"></i>
                <p>Location</p>
                <span>{{job?.location}}</span>
              </li>
              <li>
                <i class="icofont-institution"></i>
                <p>Place of Work</p>
                <span>{{job?.place_of_work}}</span>
              </li>
            </ul>
          </div>

          <div class="job-overview job-company">
            <h3>Company Details</h3>

            <ul>
              <li>
                <i class="icofont-tack-pin"></i>
                <span>Posted By: {{employer?.firstname}}</span>
              </li>

              <li>
                <i class="icofont-location-pin"></i>
                <span>Address : {{employer?.address}}</span>
              </li>

              <!-- <li>
                <i class="icofont-ui-email"></i>
                <a href="mailto:hello@peof.com">{{employer?.email}}</a>
                </li> -->
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
