import {
  Component,
  EventEmitter,
  Input,
  Output,
  AfterViewInit, forwardRef
} from '@angular/core';
import { FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'app-slider',
    templateUrl: './slider.component.html',
    styleUrls: ['./slider.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SliderComponent),
    }
  ]
})
export class SliderComponent implements AfterViewInit {
  @Input() id: string;
  @Input() bubbleId: string;
  @Input() minLabel: number;
  @Input() maxLabel: number;
  @Input() min: number;
  @Input() max: number;
  @Input() value: number = 0;
  @Input() form: FormGroup;
  @Output() valueChangedEvent = new EventEmitter<any>();
  input: number;

  ngAfterViewInit() {
    const slider = document.getElementById(this.id);
    const bubbleId = '#' + this.bubbleId + ' span';
    const bubble = document.querySelector(bubbleId);
    this.setBubbleValue(slider, bubble);
    slider.addEventListener('input', () => {
      bubble.parentElement.classList.remove('isHidden');
      this.setBubbleValue(slider, bubble);
      this.valueChangedEvent.emit((<HTMLInputElement>slider).value);
    });
  }

  setBubbleValue(range, bubble) {
    const
      newValue = Number( (this.input - range.min) * 100 / (range.max - range.min) ),
      newPosition = 10 - (newValue * 0.2);
    bubble.textContent = this.input;
    bubble.parentElement.style.left = `calc(${newValue}% + (${newPosition}px))`;
    bubble.parentElement.classList.remove('isHidden');
  }

  valueChanged(value: any) {
    this.onChange(value);
    this.onTouch();
  }

  onChange: any = () => {};
  onTouch: any = () => {};
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(input: number) {
    this.input = input;
  }
}
