import { Component, OnInit } from '@angular/core';
import { User } from '../../models/user.model';
import { UserProfileService } from '../../services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.scss']
})
export class CompanyListComponent implements OnInit {
  employers: User[];
  p: number = 1;
  collection: any[];

  constructor(
    private userProfileService: UserProfileService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.userProfileService.getEmployers().subscribe( response => {
      this.employers = response['users'];
    })
  }

  goToCompanyDetails(user) {
    this.router.navigate(['company-details'], {
      state: {data: JSON.stringify(user)}
    });
  }

}
