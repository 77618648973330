<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="page-title-text">
          <h2>Job List</h2>
          <ul>
            <li><a routerLink="/">Home</a></li>
            <li><i class="icofont-simple-right"></i></li>
            <li>Job List</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="mt-5 page-title-text search">
  <div class="container">
    <form [formGroup]="searchForm">
      <div class="row search-form-area form-group common-btn">
        <div class="ng-autocomplete form-control search-input-area">
          <ng-autocomplete
            [data]="filteredJobs"
            [searchKeyword]="keyword"
            placeholder="Find a job"
            (selected)='onSelectItem($event)'
            (inputChanged)='onChangeSearch($event)'
            (inputCleared)='onCleared()'
            [itemTemplate]="itemTemplate"
          >
          </ng-autocomplete>
          <ng-template #itemTemplate let-item>
            <a [innerHTML]="item.title"></a>
          </ng-template>
        </div>
        <a class="green-white-simulated-hover" (click)="onSubmit()"><i class="icofont-ui-search"></i> Search </a>
      </div>

      <div class="row" class="search-filter">
        <app-select
          *ngIf="selectLocation.length > 0"
          class="btn search-filter-select"
          [defaultText]="locationDefaultText"
          [selectTexts]="selectLocation"
          formControlName="location"
          [formGroup]="searchForm"
          [selectClass]="'location'"
          [isSelectedBackground]="true"
          (valueChangedEvent)="onHandleSelect($event, 'location')"
        >
        </app-select>
        <app-select
          #selectElement
          class="btn search-filter-select"
          [defaultText]="categoryDefaultText"
          [selectTexts]="selectCategory"
          formControlName="category"
          [formGroup]="searchForm"
          [selectClass]="'category'"
          [isSelectedBackground]="true"
          (valueChangedEvent)="onHandleSelect($event, 'category')"
        >
        </app-select>
        <app-select
          class="btn search-filter-select"
          [defaultText]="jobTypeDefaultText"
          [selectTexts]="selectJobType"
          formControlName="jobType"
          [formGroup]="searchForm"
          [selectClass]="'jobType'"
          [isSelectedBackground]="true"
          (valueChangedEvent)="onHandleSelect($event, 'jobType')"
        >
        </app-select>
        <app-select
          class="btn search-filter-select"
          [defaultText]="placeOfWorkDefaultText"
          [selectTexts]="selectPlaceOfWork"
          formControlName="placeOfWork"
          [formGroup]="searchForm"
          [selectClass]="'placeOfWork'"
          [isSelectedBackground]="true"
          (valueChangedEvent)="onHandleSelect($event, 'placeOfWork')"
        >
        </app-select>
        <i class="icofont-refresh" (click)="onReset()"> Refresh</i>
      </div>
    </form>

  </div>
</section>
<section class="job-area job-area-two pt-5">
  <div class="container">
    <div *ngIf="isFromAnotherPage" class="search-results-labels">
      <h4>Search Results: </h4>
      <span> {{ 'Hits: ' + filteredJobs.length }} </span>
    </div>
    <div class="row">
      <div class="col-lg-6" *ngFor="let job of filteredJobs | paginate: { itemsPerPage: 10, currentPage: p }">
        <div class="job-item" [ngClass]="{'is-applied': isApplied(job)}">
          <img src="{{getLogo(job) ? getLogo(job)['image'] : 'assets/img/default-image.png'}}" alt="job image">
          <div *ngIf="job.is_featured" class="featured">FEATURED</div>
          <div class="job-inner align-items-center">
            <div class="posted-on">{{'Posted on: ' + (job.created_at | date: 'longDate')}}</div>
            <div class="job-inner-left">
              <h3>{{job.title}}</h3>
              <a (click)="goToCompanyDetails(job.user_id)">{{job.company}}</a>
              <ul>
                <li><i class="icofont-money-bag"></i>{{job.salary ? job.salary : 'N/A'}}</li>
                <li><i class="icofont-location-pin"></i>{{job.location}}</li>
                <li>
                  <i class="icofont-ui-calendar"></i>
                  {{getDifferenceInDays(job)}}
                </li>
              </ul>
            </div>

            <div class="job-inner-right">
              <ul>
                <li *ngIf="(isCandidate | async)">
                  <a
                    *ngIf="!isApplied(job)"
                    class="apply-btn white-to-green-simulated-hover"
                    (click)="goToJobDetails(job)"
                  >
                    Apply
                  </a>
                  <a
                    *ngIf="isApplied(job)"
                    class="apply-btn applied"
                  >
                    <i class="icofont-check-circled"></i>
                    Applied
                  </a>

                </li>
                <li><span>{{job.type}}</span></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div *ngIf="filteredJobs?.length === 0" class="col-lg-12 no-match-found">
        No match found
      </div>
    </div>
    <pagination-controls (pageChange)="p = $event"></pagination-controls>
  </div>
</section>
