import { Component, OnInit, AfterViewInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/auth.service';
import { UserProfileService } from '../../services/user.service';
import { ApplicationService } from '../../services/application.service';
import { User } from '../../models/user.model';
import { Application } from '../../models/application.model';
import { PhonePipe } from '../../pipes/phone.pipe';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray
} from '@angular/forms';
import { JobService } from '../../services/job.service';
import { Job, JobsAppliedTO } from '../../models/job.model';
import { first, mergeMap } from "rxjs/operators";
import Swal from 'sweetalert2';
import { CountryISO } from 'ngx-intl-tel-input';
import { SearchCountryField } from 'ngx-intl-tel-input';
import { PhoneNumberFormat } from 'ngx-intl-tel-input';
import { SelectLabelsService } from '../../services/selectLabels.service';

declare const $;

@Component({
  selector: 'app-candidate-dashboard',
  templateUrl: './candidate-dashboard.component.html',
  styleUrls: ['./candidate-dashboard.component.scss'],
  providers: [DatePipe]
})
export class CandidateDashboardComponent implements OnInit, AfterViewInit {
  candidateDashboardForm: FormGroup;
  submitted = false;
  cvImage: string;
  uploadedCV: File;
  user: User;
  jobsAppliedTo: Job[];
  applications: Application[];
  isUser: boolean;
  sliderMin: number = 0;
  sliderMax: number = 100;
  selectLabels = [];
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.Uganda
  ];

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private phonePipe: PhonePipe,
    private datePipe: DatePipe,
    private authenticationService: AuthenticationService,
    private userProfileService: UserProfileService,
    private jobService: JobService,
    private applicationService: ApplicationService,
    private selectLabelsService: SelectLabelsService,
  ) { }

  ngOnInit(): void {
    this.selectLabels = this.selectLabelsService.getAvailability();
    this.authenticationService.isLoggedIn().subscribe(isUser => {
      if (isUser && this.authenticationService.isCandidate()) {
        this.isUser = true;
        this.user = this.authenticationService.currentUser()['user'];
        if (this.user) {
          this.candidateDashboardForm = this.formBuilder.group({
            cv: [''],
            availability: [this.user.availability, [Validators.required]],
            fullName: [this.user.firstname + ' ' + this.user.lastname],
            email: [this.user.email],
            feature: [this.user?.is_featured ? 'Featured' : 'Not featured'],
            birthDate: [this.datePipe.transform(this.user.birth_date, 'longDate')],
            telephone: [this.phonePipe.transform(this.user.telephone), Validators.required],
            profession: [this.user.profession, [Validators.required, Validators.minLength(6)]],
            address: [this.user.address, Validators.required],
            aboutMe: [this.user.about_me, [Validators.required, Validators.minLength(100)]],
            educationTitle: [this.user.education_title, [Validators.required]],
            institute: [this.user.education_institute, [Validators.required]],
            year: [this.user.education_year, [Validators.required]],
            qualification: [this.user.education_qualification, [Validators.required]],
            skills: this.formBuilder.array([this.newElement()]),
          });
          this.user.skills?.forEach((skill, i) => {
            if (i > 0) {
              this.addElement(null, 'skills');
            }
            this.skills.controls[i].setValue({
              skillTitle: skill['skillTitle'],
              skillLevel: skill['skillLevel']
            })
          });
        }
        this.setSelectValue($('select').val());
      }
    });

    if (this.isUser) {
      this.applicationService.getMyApplications().pipe(
        mergeMap(response => {
          this.applications = response['applications'];
          return this.jobService.getJobs()
        })
      ).subscribe(res =>
        this.jobsAppliedTo = this.getAppliedJobs(res['jobs'], this.applications))
    }
  }

  ngAfterViewInit() {
    $('select').val(this.f.availability.value).niceSelect('update');
  }

  setSelectValue(data) {
    if (data) {
      this.f.availability.setValue(data.value);
    } else {
      this.f.availability.setValue(this.f.availability.value);
    }
  }

  get f() {
    return this.candidateDashboardForm.controls;
  }

  getAppliedJobs(jobs: JobsAppliedTO[], applications: Application[]) {
    return applications.map(application => {
      return jobs.find(job => {
        if (job.application_ids && job.application_ids.some(id => id === application.id)) {
          job.applicationStatus = application.status;
          return job;
        }
      });
    });
  }

  uploadCV() {
    const link = document.querySelector('.cv-upload') as HTMLElement;
    link.click();
  }

  onRemoveCV() {
    this.cvImage = null;
    this.uploadedCV = null;
  }

  newElement(): FormGroup {
    return this.formBuilder.group({
      skillTitle: ['', [Validators.required]],
      skillLevel: [50]
    })
  }

  removeElement(index: number, element: string) {
    this[element].removeAt(index);
  }

  addElement(e, element: string) {
    if (e) {
      e.preventDefault();
    }
    this[element].push(this.newElement());
  }

  get skills(): FormArray {
    return this.candidateDashboardForm.controls['skills'] as FormArray;
  }

  onCVChange(event: any): void {
    if (event.target.files && event.target.files[0]) {
      this.cvImage = event.target.files[0].name;
      this.uploadedCV = event.target.files[0];
    }
  }

  onSubmit() {
    const formData = Object.assign({}, this.candidateDashboardForm.value);
    this.submitted = true;
    if (this.candidateDashboardForm.invalid) {
      return;
    }
    formData.firstName = this.user.firstname;
    formData.lastName = this.user.lastname;
    formData.telephone = this.f.telephone.value.e164Number;
    formData.is_active = this.user.is_active;
    formData.is_featured = this.f.feature.value === 'Featured' ? 1 : 0;
    delete formData['fullName'];
    delete formData['feature'];
    if (this.uploadedCV) {
      const formInfo = new FormData();
      formInfo.append('cv', this.uploadedCV, this.uploadedCV.name);
      this.userProfileService.updateDocuments(formInfo, this.user.id).pipe(
        mergeMap(() =>
          this.userProfileService.editUser(formData, this.user.id)
        )).pipe(first()).subscribe(response => {
          this.handleResponse(true);
        },
          error => {
            this.handleResponse(false);
            console.error('error', error)
          });
    } else {
      this.userProfileService.editUser(formData, this.user.id)
        .pipe(first()).subscribe(response => {
          this.handleResponse(true);
        },
          error => {
            this.handleResponse(false);
            console.error('error', error)
          });
    }
  }

  handleResponse(isSuccess: boolean) {
    if (isSuccess) {
      Swal.fire({
        title: 'Your Account has been updated',
        text: 'Please login to continue',
        icon: 'success',
        confirmButtonColor: '#38a745',
      }).then(() => {
        this.onLogout();
        this.router.navigate(['authenticate/login']);
      });
    } else {
      Swal.fire({
        title: 'An error occurred',
        html: 'Please contact us at: <b>info@meshincentre.com</b> or via the contact form',
        icon: 'error',
        confirmButtonColor: '#f37b7b',
      });
    }
  }

  goToProfile() {
    this.router.navigate(['candidate-details'], {
      state: { data: JSON.stringify(this.user) }
    });
  }

  onLogout() {
    this.authenticationService.logout();
  }
}
