import { Component, OnInit, ViewChild } from '@angular/core';
// import { JobService } from '../../services/job.service';
import { Job } from '../../models/job.model';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';

// declare const $;

@Component({
  selector: 'app-job-results',
  templateUrl: './job-results.component.html',
  styleUrls: ['./job-results.component.scss']
})
export class JobResultsComponent implements OnInit {
  @ViewChild('selectElement') select;
  filteredJobs: any;
  jobs: Job[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    // private jobService: JobService,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.jobs = JSON.parse(params.data);
      this.filteredJobs = this.jobs;
    });

    console.log('results',this.jobs);

    // this.getTopJobs();
  }

  // getTopJobs() {
  //   this.jobService.getJobs().subscribe(response => {
  //     this.jobs = response['jobs'];
  //     this.filteredJobs = this.jobs;
  //   });
  // }

  goToJobDetails(job) {
  //   const navigationExtras: NavigationExtras = {
  //     queryParams: {
  //       data: JSON.stringify(job)
  //     }
  //   };
  //   this.router.navigate(['job-details'], navigationExtras);
    this.router.navigate(['job-details'], {
      state: {data: JSON.stringify(job)}
    });
    this.router.navigate(['job-details'])
  }
}
