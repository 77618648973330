<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>Blog Details</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>Blog Details</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="blog-details-area pt-5 pb-5">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div id="blog-details-item" class="blog-details-item">
                    <div class="blog-details-img">
                        <img
                          *ngIf="blogPost?._embedded"
                          src="{{blogPost?._embedded['wp:featuredmedia'][0].source_url}}"
                          alt="Blog image"
                        >
                        <h2>{{blogPost.title?.rendered}}</h2>
                        <ul>
                            <li>
                              <i class="icofont-calendar"></i>
                              {{blogPost.date | date: 'longDate'}}
                            </li>
                            <li><i class="icofont-user-alt-7"></i><a routerLink="/blog">Skillcrawler</a></li>
                        </ul>
                        <p [innerHtml]="blogPost.content?.rendered"></p>
                      <div>{{blogPost.terms?.category.name}}</div>
                    </div>

                  <!-- TODO: Shall decide if this is still needed -->
                    <!--div class="blog-details-social">
                        <span>Share:</span>

                        <ul>
                            <li><a href="#" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-instagram"></i></a></li>
                        </ul>
                    </div>

                    <div class="blog-details-nav">
                        <div class="previous-left">
                            <a routerLink="/blog-details">Previous</a>
                        </div>

                        <div class="previous-right">
                            <a routerLink="/blog-details">Next</a>
                        </div>
                    </div-->

                    <div class="blog-details-nav">
                      <h4>{{commentsTotal + ' Comments'}}</h4>
                      <ul>
                        <ng-container *ngFor="let comment of comments; let i = index;">
                          <app-comment
                            [comment]="comment"
                            (replyClicked)="onToggleForm($event)"
                          >
                          </app-comment>
                        </ng-container>
                      </ul>
                    </div>

                    <div id="blog-details-form" class="blog-details-form">
                        <h3>{{isPost ? 'Post a Comment' : 'Reply to ' + authorName}}</h3>
                        <a
                          *ngIf="!isPost"
                          class="cancel-reply"
                          (click)="onToggleForm({isPost: true, commentData: null})"
                        >
                          Cancel reply
                        </a>
                        <form id="contactForm" [formGroup]="commentForm" (ngSubmit)="onPostComment()">
                            <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  required
                                  placeholder="Enter Name"
                                  formControlName="name"
                                >
                              <div *ngIf="submitted && f.name.errors" class="is-invalid">
                                <div *ngIf="f.name.errors.required">Name required</div>
                              </div>
                            </div>

                            <div class="form-group">
                                <input
                                  type="email"
                                  class="form-control"
                                  required
                                  placeholder="Enter Email"
                                  formControlName="email"
                                >
                              <div *ngIf="submitted && f.email.errors" class="is-invalid">
                                <div *ngIf="f.email.errors.required">Email required</div>
                                <div *ngIf="f.email.errors.email || f.email.errors.pattern">
                                  Email must be a valid email address
                                </div>
                              </div>
                            </div>

                            <div class="form-group">
                                <textarea
                                  name="message"
                                  class="form-control"
                                  cols="30"
                                  rows="8"
                                  required
                                  placeholder="Enter Comment"
                                  formControlName="comment"
                                >
                                </textarea>
                              <div *ngIf="submitted && f.comment.errors" class="is-invalid">
                                <div *ngIf="f.comment.errors.required">Comment required</div>
                              </div>
                            </div>

                            <div class="text-left">
                                <button type="submit" class="btn blog-details-btn">Post</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

          <div class="col-lg-4">
            <div class="blog-details-item">
              <div class="single-resume-category">
                <h3>Job Categories</h3>
                <ul>
                  <li
                    *ngFor="let category of categoryTitles; let i = index;"
                    (click)="handleRoute('category', category)"
                  >
                    <a>{{formatTitle(category)}}</a>
                    <span>({{getSize('category', category)}})</span>
                  </li>
                </ul>
              </div>

              <div class="single-resume-category single-resume-types">
                <h3>Job Locations</h3>
                <ul >
                  <li
                    *ngFor="let location of locationTitles; let i = index;"
                    (click)="handleRoute('location', location)"
                  >
                    <div *ngIf="i < 6">
                      <a>{{location}}</a>
                      <span>({{getSize('location', location)}})</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
    </div>
</div>
