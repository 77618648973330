<div class="navbar-area fixed-top">

  <div class="mobile-nav">
    <a routerLink="/" class="logo"><img src="assets/img/logo-mobile.png" alt="Logo"></a>
    <img *ngIf="(isLoggedIn | async)" class="profile-image" src="{{user.image}}" alt="profile image">
  </div>

  <div class="main-nav">
    <div class="container">
      <nav class="navbar navbar-expand-md navbar-light">
        <a class="navbar-brand" routerLink="/"><img src="assets/img/logo.png" alt="Logo"></a>

        <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a routerLink="/home" class="nav-link" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">Home</a>
            </li>

            <li class="nav-item">
              <a
                routerLink="/about"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}"
              >
                About
              </a>
            </li>

            <li *ngIf="(isLoggedIn | async) && (isCandidate | async) && !(isAdmin | async)" class="nav-item">
              <a routerLink="/candidate-dashboard" class="nav-link" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">
                Dashboard
              </a>
            </li>
            <li *ngIf="(isLoggedIn | async) && !(isCandidate | async) && !(isAdmin | async)" class="nav-item">
              <a routerLink="/employer-dashboard" class="nav-link" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">
                Dashboard
              </a>
            </li>

            <li *ngIf="(isLoggedIn | async) && !(isCandidate | async) && !(isAdmin | async)" class="nav-item">
              <a routerLink="/post-a-job" class="nav-link" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">
                Post A Job
              </a>
            </li>

            <li *ngIf="!(isLoggedIn | async) || (isCandidate | async) || (isAdmin | async)" class="nav-item">
              <a
                routerLink="/job-list"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}"
              >
                Jobs
              </a>
            </li>

            <li *ngIf="(isLoggedIn | async) && !(isCandidate | async) && !(isAdmin | async)" class="nav-item">
              <a class="nav-link dropdown-toggle" href="javascript:void(0)">
                Jobs
                <i class="icofont-simple-down"></i>
              </a>
              <ul class="dropdown-menu">
                <li class="nav-item">
                  <a routerLink="/job-list"
                     class="nav-link"
                     routerLinkActive="active"
                     [routerLinkActiveOptions]="{exact: true}"
                  >
                    Jobs Posted
                  </a>
                </li>
                <li class="nav-item">
                  <a routerLink="/candidate-list" class="nav-link" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">
                    Candidates
                  </a>
                </li>
                <!-- TODO: Shall decided if still needed -->
                <!--li *ngIf="(isLoggedIn | async) && (isCandidate | async)" class="nav-item">
                  <a
                    routerLink="/company-list"
                    class="nav-link"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}"
                  >
                    Companies
                  </a>
                </li-->
              </ul>
            </li>

            <li class="nav-item">
              <a routerLink="/pricing"
                 class="nav-link"
                 routerLinkActive="active"
                 [routerLinkActiveOptions]="{exact: true}"
              >
                Pricing
              </a>
            </li>

            <!-- TODO: Shall uncomment after fixing blog and comments display issues -->
            <!--li class="nav-item">
              <a
                class="nav-link"
                routerLink="/blog"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}"
              >
                Blog
              </a>
            </li-->

            <li class="nav-item">
              <a
                class="nav-link"
                routerLink="/contact"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}"
              >
                Contact
              </a>
            </li>

            <li *ngIf="(isLoggedIn | async) && (isAdmin | async)" class="nav-item">
              <a class="nav-link" routerLink="/admin" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">
                Admin
              </a>
            </li>

            <li *ngIf="(isLoggedIn | async)" class="nav-item is-mobile">
              <a
                class="nav-link"
                routerLink="/settings"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}"
              >
                Settings
              </a>
            </li>

            <li *ngIf="(isLoggedIn | async)" class="nav-item is-mobile">
              <a
                class="nav-link"
                routerLink="/authenticate/login"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}"
              >
                Log out
              </a>
            </li>

            <!-- TODO: Shall decide if still needed during clean up

            <!--li class="nav-item">
              <a href="javascript:void(0)" class="nav-link dropdown-toggle">Jobs <i class="icofont-simple-down"></i></a>

              <ul class="dropdown-menu">
                <li class="nav-item"><a routerLink="/job-list" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Job List</a></li>

                <li class="nav-item"><a routerLink="/favourite-job" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Favourite Jobs</a></li>

                <li class="nav-item"><a routerLink="/job-details" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Job Details</a></li>

                <li class="nav-item"><a routerLink="/post-a-job" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Post A Job</a></li>
              </ul>
            </li-->




            <!--li class="nav-item">
              <a href="javascript:void(0)" class="nav-link dropdown-toggle">Candidates <i
                class="icofont-simple-down"></i></a>

              <ul class="dropdown-menu">
                <li class="nav-item"><a routerLink="/candidate-list" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Candidate List</a></li>

                <li class="nav-item"><a routerLink="/candidate-details" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Candidate Details</a></li>

                <li class="nav-item"><a routerLink="/single-resume" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Single Resume</a></li>

                <li class="nav-item"><a routerLink="/submit-resume" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Submit Resume</a></li>

                <li class="nav-item"><a routerLink="/pricing" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Pricing</a></li>

                <li class="nav-item"><a routerLink="/candidate-dashboard" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Candidate dashboard</a></li>
                <li class="nav-item"><a routerLink="/employer-dashboard" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Employer dashboard</a></li>
              </ul>
            </li-->



            <!--li class="nav-item">
              <a href="javascript:void(0)" class="nav-link dropdown-toggle">Pages <i
                class="icofont-simple-down"></i></a>

              <ul class="dropdown-menu">
                <li class="nav-item"><a routerLink="/company-list" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Company List</a></li>

                <li class="nav-item"><a routerLink="/company-details" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Company Details</a></li>

                <li class="nav-item"><a routerLink="/login" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Login Page</a></li>

                <li class="nav-item"><a routerLink="/create-account" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Create Account Page</a></li>

                <li class="nav-item"><a routerLink="/profile" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Profile</a></li>

                <li class="nav-item"><a routerLink="/single-profile" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Single Profile</a></li>

                <li class="nav-item"><a routerLink="/404" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">404 Error</a></li>

                <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">FAQ</a></li>
              </ul>
            </li-->



            <!--li class="nav-item">
              <a href="javascript:void(0)" class="nav-link dropdown-toggle">Blogs <i
                class="icofont-simple-down"></i></a>

              <ul class="dropdown-menu">
                <li class="nav-item"><a routerLink="/blog" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Blog</a></li>

                <li class="nav-item"><a routerLink="/blog-details" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>
              </ul>
            </li-->

          </ul>

          <div class="common-btn" *ngIf="!(isLoggedIn | async)">
            <a
              class="white-to-green-simulated-hover"
              routerLink="/authenticate/login"
            >
              <i class="icofont-plus-square"></i>
              Login
            </a>
            <a
              class="green-white-simulated-hover"
              routerLink="/authenticate/create-account"
            >
              <i class="icofont-user-alt-4"></i>
              Sign Up
            </a>
          </div>

          <div *ngIf="(isLoggedIn | async)" class="profile">
            <div class="profile-img">
              <ul class="navbar-nav">
                <li class="nav-item">
                  <a href="javascript:void(0)" class="nav-link dropdown-toggle">{{user.firstname}}<i
                    class="icofont-simple-down"></i></a>

                  <ul class="dropdown-menu">
                    <li class="nav-item">
                      <a
                        routerLink="/profile"
                        class="nav-link"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact: true}"
                      >
                        Profile
                      </a>
                    </li>

                    <li *ngIf="(isLoggedIn | async) && !(isAdmin | async)" class="nav-item">
                      <a
                        routerLink="/settings"
                        class="nav-link"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact: true}"
                      >
                        Settings
                      </a>
                    </li>

                    <li class="nav-item">
                      <a
                        routerLink=""
                        class="nav-link"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact: true}"
                        (click)="onLogout()"
                      >
                        Log out
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="nav-item"><img src="{{user.image}}" alt="profile image"></li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>

</div>
