import {Component, Input, forwardRef, Output, EventEmitter} from '@angular/core';
import { FormGroup, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => InputTextComponent),
    }
  ]
})
export class InputTextComponent implements ControlValueAccessor {
  @Input() name: string;
  @Input() textLabel: string;
  @Input() placeHolder: string;
  @Input() type: string;
  @Input() form: FormGroup;
  @Input() isDisabled: boolean;
  @Input() invalidInput: boolean;
  @Input() isIconDisplayed: boolean;
  @Input() inputIcon: string;
  @Output() inputChange = new EventEmitter<any>();
  input: string;

  onChange: any = () => this.inputChange.emit(this.input);
  onTouch: any = () => {};
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  writeValue(input: string) {
    this.input = input;
  }
}
