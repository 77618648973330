<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="page-title-text">
          <h2>Search Results</h2>
          <ul>
            <li>Search Results: </li>
            <li><i class="icofont-simple-right"></i></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<section class="job-area job-area-two pt-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-6" *ngFor="let job of filteredJobs">
        <div class="job-item">

          <div class="job-inner align-items-center">
            <div class="job-inner-left">
              <h3>{{job.title}}</h3>
              <a routerLink="/company-details">{{job.company}}</a>
              <ul>
                <li><i class="icofont-money-bag"></i>{{job.salary}}</li>
                <li><i class="icofont-location-pin"></i>{{job.location}}</li>
              </ul>
            </div>

            <div class="job-inner-right">
              <ul>
                <li><a class="apply-btn" (click)="goToJobDetails(job)">Apply</a></li>
                <li><span>{{job.type}}</span></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="row">
      <div *ngIf="filteredJobs?.length === 0" class="col-lg-12 no-match-found">
        No match found
      </div>
    </div> -->
  </div>
</section>
