import { Pipe } from '@angular/core';

@Pipe({
  name: 'phone'
})
export class PhonePipe {
  transform(value) {
    return (value && value.indexOf('+') > -1) ? value.substring(4) : value;
  }
}
