import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BlogService } from '../../services/blog.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Job } from '../../models/job.model';
import { JobService } from '../../services/job.service';
import { first } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent implements OnInit {
  commentForm: FormGroup;
  blogId: number;
  blogPost: any = {};
  comments: any[] = [];
  commentsTotal: number;
  submitted = false;
  isPost: boolean = true;
  authorName: string;
  parentId: number;
  id: number;
  jobs: Job[];
  categoryTitles = [];
  locationTitles = [];

  constructor(
    private formBuilder: FormBuilder,
    private blogService: BlogService,
    private jobService: JobService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.blogId = history.state.data;
    this.commentForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [
        Validators.required,
        Validators.email,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      comment: [''],
    });
    if (this.blogId) {
      this.blogService.getBlogPost(this.blogId)
        .pipe(first()).subscribe(post => {
          this.blogPost = post;
          this.getComments();
      });
    } else {
      this.router.navigate(['blog']);
    }
    this.jobService.getJobs().pipe(first())
      .subscribe(response => {
        this.jobs = response['jobs'];
        this.categoryTitles = [...new Set(this.jobs.map(job => job.category))];
        this.categoryTitles.push(this.categoryTitles.splice(this.categoryTitles.indexOf('Other'), 1)[0]);
        this.locationTitles = [...new Set(this.jobs.map(job => job.location))];
      });
  }

  get f() {
    return this.commentForm.controls;
  }

  formatTitle(category) {
    return category.indexOf('Services') > -1 ? category.split('Services')[0] : category;
  }

  getSize(type: string, title: string) {
    return this.jobs.filter(job => job[type] === title).length;
  }

  handleRoute(type: string, title: string) {
    const jobs = this.jobs.filter(job => job[type] === title);
    this.router.navigate(['job-list'], {
      state: {data: jobs}
    });
  }

  getComments() {
    this.blogService.getComments(this.blogId)
      .pipe(first()).subscribe(response => {
        const comments = (response as any[]).reverse();
        this.commentsTotal = comments.length;
        this.comments = comments.map(comment => {
          const parent = comments.find(parentComment => parentComment.id === comment.parent);
          if (parent && parent.child) {
            parent.child.push(comment);
          } else if (parent && !parent.child) {
            parent.child = [];
            parent.child.push(comment);
          }
          return comment
        }).filter(comment => comment.parent === 0);
    });
  }

  onPostComment() {
    const formData = {
      post: this.blogId,
      author_name: this.f.name.value,
      author_email: this.f.email.value,
      content: this.f.comment.value,
      parent: !this.isPost ? this.parentId : 0
    };
    this.submitted = true;
    if (this.commentForm.invalid) {
      return;
    }
    formData['post'] = this.blogId;
    this.blogService.postComment(formData).pipe(first())
      .subscribe(response => {
      Swal.fire({
        title: 'Comment posted!',
        text: 'It will be online after moderation',
        icon: 'success',
        confirmButtonColor: '#38a745',
      }).then(() => {
        this.commentForm.reset();
        this.setFormValidation({'incorrect': true});
        this.f.name.setValue('');
        this.f.email.setValue('');
        this.f.comment.setValue('');
        this.submitted = false;
      });
    }, error => {
      Swal.fire({
        title: 'An error occurred',
        html: 'Please contact us at: <b>info@meshincentre.com</b> or via the contact form',
        icon: 'error',
        confirmButtonColor: '#f37b7b',
      }).then(() =>
        this.router.navigateByUrl('contact')
      );
    });
  }

  setFormValidation(setting: any) {
    Object.keys(this.commentForm.controls).forEach(key => {
      this.commentForm.controls[key].setErrors(setting);
    });
  }

  onToggleForm(event: any) {
    this.isPost = event.isPost;
    if (!this.isPost) {
      this.authorName = event.commentData.author_name;
      this.parentId = event.commentData.id;
      this.scrollToForm(event);
    }
  }

  scrollToForm(data: any) {
    const commentSection = document.getElementById('blog-details-form');
    const topPos = commentSection.offsetTop;
    window.scrollBy({
      top: topPos - data.event.pageY,
      left: 0,
      behavior: 'smooth'
    });
  }
}
