<li class="comment-container">
  <img class="author-image" src="assets/img/default-image.png" alt="author default image">
  <div class="comment">
    <i
      class="icofont-reply"
      (click)="onReply({isPost: false, commentData: comment, event: $event})"
    >
      Reply
    </i>
    <div class="name">{{comment?.author_name + ' ' + comment.id}}</div>
    <div class="date">{{comment?.date | date: 'medium'}}</div>
    <div class="content" [innerHtml]="comment?.content.rendered"></div>
  </div>
  <ul *ngFor="let item of comment.child">
    <app-comment
      [comment]="item"
      (replyClicked)="onReply($event)"
    >
    </app-comment>
  </ul>
</li>
