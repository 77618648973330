<div class="banner-area banner-area-three">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="banner-text">
          <h1>Want a <span>Better</span> Job or Candidate?</h1>
          <p><b>Skillcrawler</b> turns your dream into reality</p>

          <div class="banner-form-area">
            <form [formGroup]="searchForm">
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label><i class="icofont-search-1"></i></label>
                    <!-- <input type="text" class="form-control" placeholder="Job Title"> -->
                    <div class="ng-autocomplete form-control search-input-area">
                      <ng-autocomplete
                        #title
                        [data]="filteredJobs"
                        [searchKeyword]="titleKeyword"
                        placeholder="Job Title"
                        (selected)="onSelect($event, 'title')"
                        (inputChanged)='onChangeSearch($event)'
                        (inputCleared)='onCleared()'
                        [itemTemplate]="itemTemplate"
                        formControlName="title"
                      >
                      </ng-autocomplete>
                      <ng-template #itemTemplate let-item>
                        <a [innerHTML]="item.title"></a>
                      </ng-template>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="form-group">
                    <label><i class="icofont-location-pin"></i></label>
                    <!-- <input type="text" class="form-control" placeholder="City or State"> -->
                    <div class="ng-autocomplete form-control search-input-area">
                      <ng-autocomplete
                        #location
                        [data]="filteredJobs"
                        [searchKeyword]="cityKeyword"
                        placeholder="City"
                        (selected)="onSelect($event, 'location')"
                        (inputChanged)='onChangeSearchLocation($event)'
                        (inputCleared)='onCleared()'
                        [itemTemplate]="itemTemplate2"
                        formControlName="location"
                      >
                      </ng-autocomplete>
                      <ng-template #itemTemplate2 let-item>
                        <!-- <span>{{ 'test' + item.location }}</span> -->
                        <a [innerHTML]="item.location"></a>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </div>

              <button type="submit" (click)="onSubmit()" class="btn banner-form-btn">Search</button>
            </form>
          </div>
        </div>

        <div class="banner-img">
          <img src="assets/img/man-standing.png" alt="Banner">
        </div>
      </div>
    </div>
  </div>
</div>

<div class="create-area">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-8">
        <div class="create-item">
          <h2 *ngIf="!(isLoggedIn | async) || (isAdmin | async)">
            Find a Better Job or Candidate Today!
          </h2>
          <h2 *ngIf="(isLoggedIn | async) && (isCandidate | async)">
            Find a Better Job Today!
          </h2>
          <h2 *ngIf="(isLoggedIn | async) && !(isCandidate | async) && !(isAdmin | async)">
            Find a Suitable Candidate Today!
          </h2>
        </div>
      </div>

      <div class="col-lg-4">
        <div class="create-item">
          <div class="create-btn">
            <a *ngIf="!(isLoggedIn | async) || (isAdmin | async)" routerLink="/authenticate/create-account">Create Account</a>
            <a *ngIf="(isLoggedIn | async) && (isCandidate | async)" routerLink="/job-list">Find a Job</a>
            <a *ngIf="(isLoggedIn | async) && !(isCandidate | async) && !(isAdmin | async)" routerLink="/candidate-list">Find a Candidate</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<section class="category-area ptb-100">
  <div class="container">
    <div class="section-title">
      <h2>Choose a Job Category</h2>
    </div>

    <div class="row">
      <div class="col-md-3 category-border">
        <div class="category-item" (click)="onClickCategory('IT & Technology')">
          <i class="flaticon-neural"></i>
          <a (click)="onClickCategory('IT & Technology')">Information Technology</a>
        </div>
      </div>

      <div class="col-md-3 category-border">
        <div class="category-item category-two" (click)="onClickCategory('Technical Support')">
          <i class="flaticon-settings"></i>
          <a (click)="onClickCategory('Technical Support')">Technical support </a>
        </div>
      </div>

      <div class="col-md-3 category-border">
        <div class="category-item category-three" (click)="onClickCategory('Real Estate Services')">
          <i class="flaticon-house"></i>
          <a (click)="onClickCategory('Real Estate Services')">Real Estate Business</a>
        </div>
      </div>

      <div class="col-md-3 category-border">
        <div class="category-item category-four" (click)="onClickCategory('Finance & Banking')">
          <!-- <i class="flaticon-analysis"></i> -->
          <i class="flaticon-enterprise"></i>
          <a (click)="onClickCategory('Finance & Banking')">Finance and Banking</a>
        </div>
      </div>

      <div class="col-md-3 category-border-two">
        <div class="category-item category-five" (click)="onClickCategory('Hospitality')">
          <i class="flaticon-sun"></i>
          <a (click)="onClickCategory('Hospitality')">Hospitality </a>
        </div>
      </div>

      <div class="col-md-3 category-border-two">
        <div class="category-item category-six" (click)="onClickCategory('Home Support Services')">
          <i class="flaticon-hand"></i>
          <a (click)="onClickCategory('Home Support Services')">Home support</a>
        </div>
      </div>

      <div class="col-md-3 category-border-two">
        <div class="category-item category-seven" (click)="onClickCategory('Business Management Services')">
          <i class="flaticon-layers"></i>
          <a (click)="onClickCategory('Business Management Services')">Business</a>
        </div>
      </div>

      <div class="col-md-3 category-border-two">
        <div class="category-item category-seven" (click)="onClickCategory('Other')">
          <i class="flaticon-analysis"></i>
          <a (click)="onClickCategory('Other')">Others</a>
        </div>
      </div>

      <!-- <div class="col-sm-3 col-lg-3 category-border-two">
        <div class="category-item category-eight">
          <i class="flaticon-dish"></i>
          <a routerLink="/">Restaurant Services</a>
        </div>
      </div> -->

      <!-- <div class="col-sm-3 offset-sm-3 offset-lg-0 col-lg-3 category-border-two">
        <div class="category-item category-nine">
          <i class="icofont-fire-burn"></i>
          <a routerLink="/">Defence & Fire Service</a>
        </div>
      </div> -->

      <!-- <div class="col-sm-3 col-lg-3">
        <div class="category-item category-ten">
          <i class="flaticon-truck"></i>
          <a routerLink="/">Home Delivery Services</a>
        </div>
      </div> -->
    </div>
  </div>
</section>

<div class="portal-area pb-70">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="portal-item">
          <div class="row">
            <div class="col-lg-7">
              <img src="assets/img/home-1.jpeg" alt="Portal">
            </div>

            <div class="col-lg-5">
              <img src="assets/img/home-2.jpeg" alt="Portal">
            </div>
          </div>

          <div class="portal-trusted">
            <span>100% Trusted</span>
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="portal-item portal-right">
          <h2>Trusted & Popular Job Portal</h2>
          <p>Many promise a quick and easy process to get a job or find a suitable employee for an opening,
            but all in vain. We don't preach what we don't practise, just try it for yourself and you
            will not regret your decision.</p>
          <div class="common-btn">
            <a
              *ngIf="(isLoggedIn | async) && !(isCandidate | async) && !(isAdmin | async)"
              class="white-to-green-simulated-hover"
              routerLink="/post-a-job"
            >
              Post a Job
              <i class="icofont-swoosh-right"></i>
            </a>
            <a
              *ngIf="((isLoggedIn | async) && (isCandidate | async) && !(isAdmin | async)) || !(isLoggedIn | async)"
              class="white-to-green-simulated-hover"
              routerLink="/authenticate/login"
            >
              Post a Job
              <i class="icofont-swoosh-right"></i>
            </a>
            <a  class="green-white-simulated-hover" routerLink="/job-list">Find a Job <i
                class="icofont-swoosh-right"></i></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="account-area account-area-two" *ngIf="!(isLoggedIn | async)">
  <div class="container">
    <div class="row account-wrap">
      <div class="col-sm-6 col-lg-4">
        <div class="account-item">
          <i class="flaticon-approved"></i>
          <span>Create Your Account</span>
        </div>
      </div>

      <div class="col-sm-6 col-lg-4">
        <div class="account-item">
          <i class="flaticon-cv"></i>
          <span>Upload Your Resume</span>
        </div>
      </div>

      <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
        <div class="account-item account-last">
          <i class="flaticon-customer-service"></i>
          <span>Apply for Dream Job</span>
        </div>
      </div>
    </div>

    <div class="banner-btn">
      <a routerLink="/authenticate/create-account">Create Your Account</a>
      <a routerLink="/authenticate/login">Sign in to proceed</a>
    </div>
  </div>
</div>

<section class="job-area pb-70">
  <div class="container">
    <div class="section-title">
      <h2>Recent Jobs</h2>
    </div>

    <!-- <div class="sorting-menu">
      <ul>
        <li class="filter active" data-filter="all">All</li>
        <li class="filter" data-filter=".web">New</li>
        <li class="filter" data-filter=".ui">Featured</li>
        <li class="filter" data-filter=".branding">Recent</li>
        <li class="filter" data-filter=".ux">Full Time</li>
        <li class="filter" data-filter=".wp">Part Time</li>
      </ul>
    </div> -->

    <div id="container">
      <div class="row">
        <div class="col-lg-6 web ui" *ngFor="let job of recentJobs">
          <div class="job-item" [ngClass]="isApplied(job) ? 'is-applied' : ''">
            <img src="{{getLogo(job) ? getLogo(job)['image'] : 'assets/img/default-image.png'}}" alt="job image">
            <div *ngIf="job.is_featured" class="featured">FEATURED</div>
            <div class="job-inner align-items-center">
              <div class="posted-on">{{'Posted on: ' + (job.created_at | date: 'longDate')}}</div>
              <div class="job-inner-left">
                <h3>{{job.title}}</h3>
                <a (click)="goToCompanyDetails(job.user_id)">{{job.company}}</a>
                <ul>
                  <li><i class="icofont-money-bag"></i>{{job.salary ? job.salary : 'N/A'}}</li>
                  <li><i class="icofont-location-pin"></i>{{job.location}}</li>
                  <li><i class="icofont-ui-calendar"></i>{{getDifferenceInDays(job)}}</li>
                </ul>
              </div>

              <div class="job-inner-right">
                <ul>
                  <li *ngIf="(isCandidate | async)">
                    <a *ngIf="!isApplied(job)" class="apply-btn white-to-green-simulated-hover"
                      (click)="goToJobDetails(job)">
                      Apply
                    </a>
                    <a *ngIf="isApplied(job)" class="apply-btn applied">
                      <i class="icofont-check-circled"></i>
                      Applied
                    </a>

                  </li>
                  <li><span>{{job.type}}</span></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 all-jobs-btn">
          <a class="green-active-white-hover" routerLink="/job-list">Find Jobs</a>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- TODO Shall implement and uncomment when jobs, or companies or profiles are more than 100 -->
<!--div class="counter-area pt-100 pb-70">
  <div class="container">
    <div class="row">
      <div class="col-6 col-sm-3 col-lg-3">
        <div class="counter-item">
          <i class="flaticon-employee"></i>
          <h3><span class="counter">14</span>k+</h3>
          <p>Job Available</p>
        </div>
      </div>

      <div class="col-6 col-sm-3 col-lg-3">
        <div class="counter-item">
          <i class="flaticon-curriculum"></i>
          <h3><span class="counter">18</span>k+</h3>
          <p>CV Submitted</p>
        </div>
      </div>

      <div class="col-6 col-sm-3 col-lg-3">
        <div class="counter-item">
          <i class="flaticon-enterprise"></i>
          <h3><span class="counter">9</span>k+</h3>
          <p>Companies</p>
        </div>
      </div>

      <div class="col-6 col-sm-3 col-lg-3">
        <div class="counter-item">
          <i class="flaticon-businessman-paper-of-the-application-for-a-job"></i>
          <h3><span class="counter">35</span>+</h3>
          <p>Appointed to Job</p>
        </div>
      </div>
    </div>
  </div>
</div-->

<div class="popular-area pt-100 pb-70">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="popular-item">
          <div class="row align-items-center">
            <div class="col-lg-7">
              <img src="assets/img/home-3.jpeg" alt="Popular">
            </div>

            <div class="col-lg-5">
              <div class="practice-inner">
                <img src="assets/img/about-4.jpg" alt="Popular">
                <img src="assets/img/about-3.jpeg" alt="Popular">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="popular-item popular-right">
          <div class="section-title text-start">
            <h2>Why We are Most Popular</h2>
          </div>
          <p>Applying for a job or finding the suitable employee is quite a stressful process for
            all parties involved. We offload this burden from you by matching you with the right job
            or employee easily without you stressing about it.</p>

          <div class="row popular-wrap">
            <div class="col-sm-6 col-lg-6">
              <ul>
                <li><i class="flaticon-approved"></i> Trusted & Quality Job</li>
                <li><i class="flaticon-no-money"></i> No Extra Charge</li>
              </ul>
            </div>

            <div class="col-sm-6 col-lg-6">
              <ul>
                <li><i class="flaticon-enterprise"></i> Top Companies</li>
                <li><i class="flaticon-employee"></i> International Job</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- TODO Shall decide if still needed -->
<!--section class="companies-area ptb-100">
  <div class="container">
    <div class="section-title">
      <h2>Popular Companies</h2>
    </div>

    <div class="companies-slider owl-carousel">
      <div class="companies-item">
        <img src="assets/img/home-1/companies/1.png" alt="Companies">
        <h3><a routerLink="/company-details">Infiniza.com</a></h3>
        <p><i class="icofont-location-pin"></i>North Street, California</p>
        <a class="companies-btn" (click)="goToCompanyDetails()">View</a>
      </div>

      <div class="companies-item">
        <img src="assets/img/home-1/companies/2.png" alt="Companies">
        <h3><a routerLink="/company-details">Infiniza.com</a></h3>
        <p><i class="icofont-location-pin"></i> North Street, California</p>
        <a class="companies-btn" routerLink="/create-account">Hiring</a>
      </div>

      <div class="companies-item">
        <img src="assets/img/home-1/companies/3.png" alt="Companies">
        <h3><a routerLink="/company-details">Glovibo.com</a></h3>
        <p><i class="icofont-location-pin"></i> Barming Road, UK</p>
        <a class="companies-btn" routerLink="/create-account">Hiring</a>
      </div>

      <div class="companies-item">
        <img src="assets/img/home-1/companies/4.png" alt="Companies">
        <h3><a routerLink="/company-details">Bizotic.com</a></h3>
        <p><i class="icofont-location-pin"></i> Washington, New York</p>
        <a class="companies-btn" routerLink="/create-account">Hiring</a>
      </div>

      <div class="companies-item">
        <img src="assets/img/home-1/companies/1.png" alt="Companies">
        <h3><a routerLink="/company-details">Winbrans.com</a></h3>
        <p><i class="icofont-location-pin"></i> Quadra, Street, Canada</p>
        <a class="companies-btn" routerLink="/create-account">Hiring</a>
      </div>

      <div class="companies-item">
        <img src="assets/img/home-1/companies/2.png" alt="Companies">
        <h3><a routerLink="/company-details">Infiniza.com</a></h3>
        <p><i class="icofont-location-pin"></i> North Street, California</p>
        <a class="companies-btn" routerLink="/create-account">Hiring</a>
      </div>

      <div class="companies-item">
        <img src="assets/img/home-1/companies/3.png" alt="Companies">
        <h3><a routerLink="/company-details">Glovibo.com</a></h3>
        <p><i class="icofont-location-pin"></i> Barming Road, UK</p>
        <a class="companies-btn" routerLink="/create-account">Hiring</a>
      </div>

      <div class="companies-item">
        <img src="assets/img/home-1/companies/4.png" alt="Companies">
        <h3><a routerLink="/company-details">Bizotic.com</a></h3>
        <p><i class="icofont-location-pin"></i> Washington, New York</p>
        <a class="companies-btn" routerLink="/create-account">Hiring</a>
      </div>

      <div class="companies-item">
        <img src="assets/img/home-1/companies/1.png" alt="Companies">
        <h3><a routerLink="/company-details">Winbrans.com</a></h3>
        <p><i class="icofont-location-pin"></i> Quadra, Street, Canada</p>
        <a class="companies-btn" routerLink="/create-account">Hiring</a>
      </div>

      <div class="companies-item">
        <img src="assets/img/home-1/companies/2.png" alt="Companies">
        <h3><a routerLink="/company-details">Infiniza.com</a></h3>
        <p><i class="icofont-location-pin"></i> North Street, California</p>
        <a class="companies-btn" routerLink="/create-account">Hiring</a>
      </div>

      <div class="companies-item">
        <img src="assets/img/home-1/companies/3.png" alt="Companies">
        <h3><a routerLink="/company-details">Glovibo.com</a></h3>
        <p><i class="icofont-location-pin"></i> Barming Road, UK</p>
        <a class="companies-btn" routerLink="/create-account">Hiring</a>
      </div>

      <div class="companies-item">
        <img src="assets/img/home-1/companies/4.png" alt="Companies">
        <h3><a routerLink="/company-details">Bizotic.com</a></h3>
        <p><i class="icofont-location-pin"></i> Washington, New York</p>
        <a class="companies-btn" routerLink="/create-account">Hiring</a>
      </div>
    </div>
  </div>
</section-->

<!-- <section class="profile-area pb-100">
  <div class="container">
    <div class="section-title">
      <h2>Featured Profiles</h2>
    </div>
    <div class="profile-slider owl-theme owl-carousel">
      <div class="profile-item" *ngFor="let candidate of candidates">
        <img src="{{candidate.image}}" alt="Profile">
        <div class="profile-inner">
          <span class="featured" *ngIf="candidate.is_featured">FEATURED</span>
          <h3>{{candidate.firstname + ' ' + candidate.lastname}}</h3>
          <span>{{candidate.job_title}}</span>
          <a class="view-profile" (click)="goToCandidateDetails(candidate)"> View Profile <i class="icofont-swoosh-right"></i></a>

          <div class="profile-heart">
            <a (click)="goToCandidateDetails(candidate)"><i class="icofont-heart-alt"></i></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->

<section class="profile-area pb-100" *ngIf="(isLoggedIn | async) && !(isCandidate | async)">
  <div class="container">
    <div class="section-title">
      <h2>Featured Profiles</h2>
    </div>

    <div class="profile-slider owl-theme owl-carousel">
        <div class="profile-item" *ngFor="let candidate of candidates">
          <img src="{{candidate.image}}" alt="Profile">
          <div class="profile-inner">
            <span class="featured" *ngIf="candidate.is_featured">FEATURED</span>
            <h3>{{candidate.firstname + ' ' + candidate.lastname}}</h3>
            <span>{{candidate.job_title}}</span>
            <a class="view-profile" (click)="goToCandidateDetails(candidate)"> View Profile <i class="icofont-swoosh-right"></i></a>
          </div>
        </div>
    </div>
  </div>
</section>


<div class="app-area">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="app-item app-left">
          <img src="assets/img/featured-job.png" alt="featured job">
          <img src="assets/img/featured-profile.png" alt="featured profile">
        </div>
      </div>

      <div class="col-lg-6">
        <div class="app-item">
          <div class="section-title text-start">
            <h2>Featured Profiles and Jobs</h2>
          </div>
          <p>Enjoy the benefits of having a featured profile or job. Featured profiles or jobs appear in the first 10 posts.</p>
          <div class="create-item">
            <div class="create-btn">
              <a routerLink="/pricing">Order Now</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- TODO: Shall uncomment after fixing blog and comments display issues -->
<section class="blog-area pt-100 pb-170">
  <!--div class="container">
    <div class="section-title">
      <h2>Latest News</h2>
    </div>

    <div class="row">
      <div class="col-sm-6 col-lg-4" *ngFor="let blogPost of blogPosts; let i = index;">
        <div *ngIf="i < 3" class="blog-item wow fadeInUp" data-wow-delay=".3s">
          <div class="blog-top">
            <a (click)="goToBlogDetails(blogPost.id)">
              <img
                *ngIf="blogPost?._embedded['wp:featuredmedia']"
                src="{{blogPost._embedded['wp:featuredmedia'][0].source_url}}"
                alt="Blog"
              >
            </a>
            <span>{{blogPost.date | date: 'longDate'}}</span>
          </div>

          <div class="blog-bottom">
            <h3>
              <a (click)="goToBlogDetails(blogPost.id)">
                {{blogPost.title.rendered}}
              </a>
            </h3>
            <ul>
              <li><img src="assets/img/blog-logo.png" alt="author image"> Skillcrawler</li>
              <li>
                <a (click)="goToBlogDetails(blogPost.id)">
                  Read More
                  <i class="icofont-simple-right"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!--div class="col-sm-6 col-lg-4">
        <div class="blog-item wow fadeInUp" data-wow-delay=".4s">
          <div class="blog-top">
            <a routerLink="/blog-details"><img src="assets/img/home-1/blog/2.jpg" alt="Blog"></a>
            <span>22 May, 2020</span>
          </div>

          <div class="blog-bottom">
            <h3><a routerLink="/blog-details">It is the most important sector in the world</a></h3>
            <ul>
              <li><img src="assets/img/home-1/blog/1.png" alt="Blog">Aikin Ward</li>
              <li><a routerLink="/blog-details">Read More<i class="icofont-simple-right"></i></a></li>
            </ul>
          </div>
        </div>
      </div>

      <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
        <div class="blog-item wow fadeInUp" data-wow-delay=".5s">
          <div class="blog-top">
            <a routerLink="/blog-details"><img src="assets/img/home-1/blog/3.jpg" alt="Blog"></a>
            <span>23 May, 2020</span>
          </div>

          <div class="blog-bottom">
            <h3><a routerLink="/blog-details">Nowadays IT is being most popular</a></h3>
            <ul>
              <li><img src="assets/img/home-1/blog/1.png" alt="Blog"> Aikin Ward</li>
              <li><a routerLink="/blog-details"> Read More<i class="icofont-simple-right"></i></a></li>
            </ul>
          </div>
        </div>
      </div-->
    <!--/div>
  </div-->

</section>
