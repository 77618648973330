import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { PagesModule } from '../pages/pages.module';

import { LoginComponent } from './login/login.component';
import { CreateAccountComponent } from './create-account/create-account.component';
import { PasswordStrengthComponent } from '../components/password-strength/password-strength.component';

/*import { SignupComponent } from './signup/signup.component';*/
import { AuthenticationRoutingModule } from './authentication-routing';

import { ConfirmComponent } from './confirm/confirm.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { EmailVerificationComponent } from './email-verification/email-verification.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';

@NgModule({
  declarations: [
    LoginComponent,
    CreateAccountComponent,
    PasswordStrengthComponent,
    ForgotPasswordComponent,
    EmailVerificationComponent,
    PasswordResetComponent,
    /*SignupComponent,
     ConfirmComponent,*/
    ConfirmComponent,
    /*SignupComponent,
     PasswordResetComponent,
     EmailVerificationComponent*/
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    AuthenticationRoutingModule,
    PagesModule,
		NgxIntlTelInputModule
  ]
})
export class AuthenticationModule { }
