<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="page-title-text">
          <h2>Profile</h2>
          <ul>
            <li><a routerLink="/">Home</a></li>
            <li><i class="icofont-simple-right"></i></li>
            <li>Profile</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="dashboard-area pt-5">
  <div class="container">
    <div class="create-information">
      <form>
        <h3>Basic Information</h3>

        <div class="create-information-btn">
          <a class="green-gradient-hover" routerLink="/">Upload Cover Photo</a>
          <a class="green-gradient-hover" routerLink="/">Upload Your CV</a>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label>Your Name</label>
              <input type="text" class="form-control" placeholder="Aikin Ward">
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label>Your Email</label>
              <input type="email" class="form-control" placeholder="hello@gable.com">
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label>Date of Birth</label>
              <input type="text" class="form-control" placeholder="21 / 2 / 1997">
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label>Your Phone</label>
              <input type="text" class="form-control" placeholder="+12345678910">
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label>Job Title</label>
              <input type="text" class="form-control" placeholder="UX/UI Designer">
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label>Address:</label>
              <input type="text" class="form-control" placeholder="210-27 Quadra, Market Street, Victoria Canada">
            </div>
          </div>

          <div class="col-lg-12">
            <div class="form-group">
              <div class="gender-area">
                <span>Gender</span>
                <input type="radio" name="gender" id="male" value="male" checked>
                <label for="male">Male</label>
                <input type="radio" name="gender" id="female" value="female">
                <label for="female">Female</label>
              </div>
            </div>
          </div>

          <div class="col-lg-12">
            <div class="form-group">
              <label>Description</label>
              <textarea id="your_message" class="form-control" rows="8" placeholder="Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate, quaerat aliquid. Aperiam reprehenderit, id dolorem reiciendis atque sit. Mollitia consequatur assumenda autem tempora cum quas blanditiis aspernatur dolore accusantium ipsam!"></textarea>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="create-education create-education-two">
      <div class="create-education-wrap">
        <div class="create-education-left">
          <h3>Education</h3>
        </div>

        <div class="create-education-right">
          <a routerLink="/">Edit</a>
          <a routerLink="/">Add Education</a>
        </div>
      </div>

      <form>
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label>Title</label>
              <input type="text" class="form-control" placeholder="Under Graduate">
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label>Degree</label>
              <input type="text" class="form-control" placeholder="BSC in Computer Science">
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label>Institute</label>
              <input type="text" class="form-control" placeholder="Gable University of Design & Arts">
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label>Year</label>
              <input type="text" class="form-control" placeholder="2016-2019">
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="create-skills">
      <div class="create-skills-wrap">
        <div class="create-skills-left">
          <h3>Skill</h3>
        </div>

        <div class="create-skills-right">
          <a routerLink="/">Edit</a>
          <a routerLink="/">Add Skill</a>
        </div>
      </div>

      <form>
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label>Title</label>
              <input type="text" class="form-control" placeholder="Frontend Design">
            </div>
          </div>

          <div class="col-lg-6">
            <div class="skill">
              <p>Percentage</p>
              <div class="skill-bar skill1 skill3 wow slideInLeft animated">
                <span class="skill-count1">60%</span>
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label>Name</label>
              <input type="text" class="form-control" placeholder="UX/UI Design">
            </div>
          </div>

          <div class="col-lg-6">
            <div class="skill">
              <p>Percentage</p>
              <div class="skill-bar skill1 skill4 wow slideInLeft animated">
                <span class="skill-count1">95%</span>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <!-- <div class="create-skills">
      <div class="create-skills-wrap">
        <div class="create-skills-left">
          <h3>Social Links</h3>
        </div>

        <div class="create-skills-right">
          <a routerLink="/">Edit</a>
          <a routerLink="/">Add New</a>
        </div>
      </div>

      <form>
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label>Facebook</label>
              <input type="text" class="form-control" placeholder="https://www.facebook,com">
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label>Instagram</label>
              <input type="text" class="form-control" placeholder="https://www.instagram,com">
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label>Linedin</label>
              <input type="text" class="form-control" placeholder="https://www.linkedin,com">
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label>Dribbble</label>
              <input type="text" class="form-control" placeholder="https://www.dribble,com">
            </div>
          </div>
        </div>
      </form>
    </div> -->

    <div class="text-left">
      <button type="submit" class=" btn green-gradient-hover">Save</button>
    </div>
  </div>
</div>
