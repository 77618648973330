<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="page-title-text">
          <h2>Company Details</h2>
          <ul>
            <li><a routerLink="/">Home</a></li>
            <li><i class="icofont-simple-right"></i></li>
            <li>Company Details</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="company-details-area pt-5">
  <div class="container">
    <div *ngIf="!user?.is_active" class="alert alert-warning" role="alert">
      <i class="icofont-warning-alt"></i>
      Your profile is not yet visible to <b>candidates</b>, because it is still under review.
    </div>
    <div class="row">
      <div class="col-lg-8">
        <div class="company-details-item">
          <div class="company-details-logo">
            <img src="{{user?.image}}" alt="Logo">

            <div class="company-logo-inner">
              <h3>{{user?.company_name}}</h3>
              <p><i class="icofont-location-pin"></i>{{user?.address}}</p>
            </div>
          </div>

          <div class="company-details-share">
            <span>Share:</span>

            <ul>
              <li><a href="https://www.facebook.com/" target="_blank"><i class="icofont-facebook"></i></a></li>
              <li><a href="https://www.twitter.com/" target="_blank"><i class="icofont-twitter"></i></a></li>
              <li><a href="https://www.instagram.com/" target="_blank"><i class="icofont-instagram"></i></a></li>
              <li><a href="https://www.linkedin.com/" target="_blank"><i class="icofont-linkedin"></i></a></li>
            </ul>
          </div>

          <div class="company-details-find">
            <h3>Find Us:</h3>

            <ul>
              <li><a href="#" target="_blank"><i class="icofont-web"></i> meshincentre.com</a></li>
              <li><a href="#" target="_blank"><i class="icofont-facebook"></i> Mesh innovation centre</a></li>
              <li><a href="#" target="_blank"><i class="icofont-twitter"></i> Mesh innovation centre</a></li>
            </ul>
          </div>

          <div class="company-details-description">
            <h3>Description</h3>
            <p>{{ user?.about_me }}</p>
          </div>
        </div>
      </div>

      <div class="col-lg-4">
        <div class="company-details-item">
          <div class="single-resume-category">
            <h3>Job Categories</h3>
            <ul>
              <li
                *ngFor="let category of categoryTitles; let i = index;"
                (click)="handleRoute('category', category)"
              >
                <a>{{formatTitle(category)}}</a>
                <span>({{getSize('category', category)}})</span>
              </li>
            </ul>
          </div>

          <div class="single-resume-category single-resume-types">
            <h3>Job Locations</h3>
              <ul >
                <li
                  *ngFor="let location of locationTitles; let i = index;"
                  (click)="handleRoute('location', location)"
                >
                  <div *ngIf="i < 6">
                    <a>{{location}}</a>
                    <span>({{getSize('location', location)}})</span>
                  </div>
                </li>
              </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
