<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="page-title-text">
          <h2>Admin</h2>
          <ul>
            <li><a routerLink="/">Home</a></li>
            <li><i class="icofont-simple-right"></i></li>
            <li>Admin</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="single-profile-area pt-5">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-3">
        <div class="single-profile-item">

          <div class="single-profile-left">
            <div class="single-profile-contact">
              <h3>Admin Panel</h3>
              <ul>
                <li><i class="icofont-users-social"></i>
                  <a (click)="getAllUsers()">
                    Users
                  </a>
                </li>
                <li><i class="icofont-archive"></i>
                  <a (click)="getAllJobs()">
                    Jobs
                  </a>
                </li>
                <li><i class="icofont-clip-board"></i>
                  <a (click)="getAllApplications()">
                    Applications
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-9">
        <div class="single-profile-item">
          <div class="single-profile-right">
            <div *ngIf="isUsers || isJobs">
              <a
                class="green-white-simulated-hover-btn"
                routerLink="{{isUsers ? '/authenticate/create-account' : '/post-a-job'}}"
              >
                <i class=".icofont-ui-add"></i> ADD
              </a>
            </div>
            <div class="admin-panel" id="admin-panel">
              <app-table
                *ngIf="isUsers"
                [tableTitle]="'Users'"
                [tableColumnTitles]="titles"
                [tableData]="tableData"
              >
                <div tablebody>
                  <ng-template let-data let-i="index" #table>
                    <td>{{i + 1}}</td>
                    <td>{{data['firstname']}}</td>
                    <td>{{data['lastname']}}</td>
                    <td>{{data['account_type']}}</td>
                    <td>{{data['is_active']}}</td>
                    <td>
                      <i class="icofont-edit" (click)="onEditUser(data)"></i>
                      <i
                        class="icofont-ui-delete"
                        [ngClass]="{
                        'is-admin': data['account_type'] === 'admin' ||
                         data['id'] === loggedInUser.id
                         }"
                        (click)="onDeleteUser(data)"
                      >
                      </i>
                    </td>
                  </ng-template>
                </div>
              </app-table>

              <app-table
                *ngIf="isJobs"
                [tableTitle]="'Jobs'"
                [tableColumnTitles]="titles"
                [tableData]="tableData"
              >
                <div tablebody>
                  <ng-template let-data let-i="index" #table>
                    <td>{{i + 1}}</td>
                    <td>{{data['title']}}</td>
                    <td>{{data['company']}}</td>
                    <td>{{data['status']}}</td>
                    <td>
                      <i class="icofont-edit" (click)="onEditJob(data)"></i>
                      <i class="icofont-ui-delete" (click)="onDeleteJob(data)"></i>
                    </td>
                  </ng-template>
                </div>
              </app-table>

              <app-table
                *ngIf="isJobApplications"
                [tableTitle]="'Applications'"
                [tableColumnTitles]="titles"
                [tableData]="tableData"
              >
                <div tablebody>
                  <ng-template let-data let-i="index" #table>
                    <td>{{i + 1}}</td>
                    <td>{{jobsAppliedTo[i] && jobsAppliedTo[i]['title']}}</td>
                    <td>{{appliedUsers[i] && jobsAppliedTo[i]['company']}}</td>
                    <td>{{appliedUsers[i] && appliedUsers[i]['firstname']}}</td>
                    <td>{{data['status']}}</td>
                    <td>
                      <i
                        class="icofont-edit"
                        (click)="onEditApplication(data, jobsAppliedTo[i]['title'])"
                      >
                      </i>
                      <!-- TODO: Shall decide if this is needed
                      <!--i class="icofont-ui-delete"></i-->
                    </td>
                  </ng-template>
                </div>
              </app-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
