<div class="slider-container">
  <div class="labels">
    <div>{{ minLabel }}</div>
    <div>{{ maxLabel }}</div>
  </div>
  <div class="range-wrap">
    <div class="range-value isHidden" id="{{ bubbleId }}">
      <span>{{ minLabel }}</span>
    </div>
    <input
      id="{{ id }}"
      class="slider"
      type="range"
      min="{{ min }}"
      max="{{ max }}"
      value="{{ value }}"
      [(ngModel)]="input"
      (ngModelChange)="valueChanged($event)"
    />
  </div>
</div>
