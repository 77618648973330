<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="page-title-text">
          <h2>Privacy Policy</h2>
          <ul>
            <li><a routerLink="/">Home</a></li>
            <li><i class="icofont-simple-right"></i></li>
            <li>Privacy Policy</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<section class="privacy-area pt-5">
  <div class="container">
    <div class="privacy-item">
      <p>
        This Privacy Policy document contains types of information that is collected and recorded by Mesh innovation Centre and how we use it.
      </p>
      <p>
        This Privacy Policy is a part of the Terms and Conditions of Use and is applicable to all users of the website. If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.
      </p>
    </div>

    <div class="privacy-item">
      <h2>1. Consent</h2>
      <p>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>
    </div>

    <div class="privacy-item">
      <h2>2. Information we collect</h2>
      <p>
        The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information. 
      </p>
      <p>
        If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.
      </p>
      <p>
        When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.
      </p>
    </div>

    <div class="privacy-item">
      <h2>3. How we use your information</h2>
      <p>
        We use the information we collect in various ways, including to:
      </p>
      <ul>
        <li><i class="icofont-simple-right"></i> Provide, operate, and maintain our website</li>
        <li><i class="icofont-simple-right"></i> Improve, personalize, and expand our website</li>
        <li><i class="icofont-simple-right"></i> Understand and analyze how you use our website</li>
        <li><i class="icofont-simple-right"></i> Develop new products, services, features, and functionality</li>
        <li><i class="icofont-simple-right"></i> Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes</li>
        <li><i class="icofont-simple-right"></i> Send you emails</li>
        <li><i class="icofont-simple-right"></i> Your browsing behaviour on the website such as information on your first visit</li>
        <li><i class="icofont-simple-right"></i> Find and prevent fraud</li>
      </ul>
    </div>

    <div class="privacy-item">
      <h2>4. Log Files</h2>
      <p>Mesh Innovation Centre follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services’ analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users’ movement on the website, and gathering demographic information.</p>
    </div>

    <div class="privacy-item">
      <h2>5. Cookies and Web Beacons</h2>
      <p>
        Like any other website, Mesh Innovation Centre uses ‘cookies’. These cookies are used to store information including visitors’ preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users’ experience by customizing our web page content based on visitors’ browser type and/or other information.
      </p>
      <p>
        For more general information on cookies, please read <a href="https://www.generateprivacypolicy.com/#cookies"> the Cookies article on Generate Privacy Policy website.</a>
      </p>
    </div>
  </div>
</section>
