import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-single-resume',
  templateUrl: './single-resume.component.html',
  styleUrls: ['./single-resume.component.scss']
})
export class SingleResumeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
