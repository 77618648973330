<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="page-title-text">
          <h2>Candidate List</h2>
          <ul>
            <li><a routerLink="/">Home</a></li>
            <li><i class="icofont-simple-right"></i></li>
            <li>Candidate List</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<section class="profile-area profile-area-two pt-5">
  <div class="container">
    <div class="row">
      <div class="col-sm-6 col-lg-3" *ngFor="let candidate of candidates | paginate: { itemsPerPage: 12, currentPage: p }">
        <div *ngIf="candidate.is_active" class="profile-item wow fadeInUp" data-wow-delay=".3s">
          <img src="{{ candidate.image }}" alt="Profile">

          <div class="profile-inner">
            <span class="featured" *ngIf="candidate.is_featured">FEATURED</span>
            <h3>{{candidate.firstname + ' ' + candidate.lastname}}</h3>
            <span>{{candidate.job_title}}</span>
            <a class="view-profile" (click)="goToCandidateDetails(candidate)"> View Profile <i class="icofont-swoosh-right"></i></a>
            <div class="profile-heart">
              <a (click)="goToCandidateDetails(candidate)"><i class="icofont-heart-alt"></i></a>
            </div>
          </div>
        </div>
      </div>
      <pagination-controls (pageChange)="p = $event"></pagination-controls>
    </div>
  </div>
</section>
