<h3>{{tableTitle}}</h3>
<table
  datatable
  class="row-border hover"
  width="100%"
  [ngClass]="isTableReady ? 'is-table-ready' : ''"
  [dtOptions]="dtOptions"
  [dtTrigger]="dtTrigger"
>
  <thead>
   <tr>
     <th *ngFor="let column of tableColumnTitles">{{column}}</th>
   </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of tableData; let i = index;">
      <ng-container
        [ngTemplateOutlet]="tableTemplateRef"
        [ngTemplateOutletContext]="{$implicit: data, index: i}"
      ></ng-container>
    </tr>
  </tbody>
</table>

