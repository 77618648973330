import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../../models/user.model';
import { Application } from '../../models/application.model';
import { Job } from '../../models/job.model';
import { JobService } from '../../services/job.service';
import { UserProfileService } from '../../services/user.service';
import { ApplicationService } from '../../services/application.service';
import { AuthenticationService } from '../../services/auth.service';
import Swal from 'sweetalert2';
import { first, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  users: User[] = [];
  isUsers: boolean = true;
  isJobs: boolean;
  isJobApplications: boolean;
  loggedInUser: User;
  titles: string[] = [];
  tableData: any[];
  type: string;
  appliedUsers: any[] = [];
  jobsAppliedTo: any[] = [];

  constructor(
    private router: Router,
    private jobService: JobService,
    private userProfileService: UserProfileService,
    private applicationService: ApplicationService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    if (this.authenticationService.currentUser()) {
      this.loggedInUser = this.authenticationService.currentUser()['user'];
    }
    this.getAllUsers();
  }

  getAllJobs() {
    this.titles = ['#', 'Title', 'Company', 'Status', 'Action'];
    this.isJobs = true;
    this.isUsers = false;
    this.isJobApplications = false;
    this.jobService.getJobs().pipe(first()).subscribe(response => {
      this.tableData = response['jobs'];
    });
  }

  getAllUsers() {
    this.titles = ['#', 'First Name', 'Last Name', 'Type', 'Status', 'Action'];
    this.isUsers = true;
    this.isJobs = false;
    this.isJobApplications = false;
    this.userProfileService.getUsers().pipe(first()).subscribe(response => {
      this.users = response['users'];
      this.tableData = this.users;
    });
  }

  getAllApplications() {
    this.titles = ['#', 'Title', 'Company', 'Candidate', 'Status', 'Action'];
    this.isUsers = false;
    this.isJobs = false;
    this.isJobApplications = true;
    this.applicationService.getApplications().pipe(
      mergeMap(response => {
        this.tableData = response['applications'];
        return this.jobService.getJobs();
      })).subscribe(res => {
      this.appliedUsers = this.getAppliedUsers(this.users, this.tableData);
      this.jobsAppliedTo = this.getAJobsAppliedTo(res['jobs'], this.tableData);
    })
  }

  getAppliedUsers(users: User[], applications: Application[]) {
    return applications.map(application => {
      return users.find(user => {
        if (user.id === application.user_id) {
          return user
        }
      });
    });
  }

  getAJobsAppliedTo(jobs: Job[], applications: Application[]) {
    return applications.map(application => {
      return jobs.find(job => {
        if (job.application_ids && job.application_ids.some(id => id === application.id)) {
          return job;
        }
      });
    })
  }

  onEditUser(user) {
    this.router.navigate(['edit-user'], {
      state: { data: user }
    });
  }

  onEditJob(job: Job) {
    this.router.navigate(['edit-job'], {
      state: { data: job }
    });
  }

  onEditApplication(application: Application, title: string) {
    const swal = Swal.fire({
      title: `Edit ${title}`,
      html: `<select
             id="status"
             class="swal2-input"
             placeholder="Enter new status"
            >
              <option value="">--Select Status--</option>
              <option value="In Review">In Review</option>
              <option value="Reviewed">Reviewed</option>
            </select>`,
      showCancelButton: true,
      confirmButtonText: 'Submit',
      confirmButtonColor: '#468ec0',
      focusConfirm: false,
      customClass: 'results-alert open',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        const status = (<HTMLInputElement>Swal.getPopup().querySelector('#status')).value;
        if (!status) {
          Swal.showValidationMessage(`Status required`);
        }
        return status.trim()
      }}).then(result => {
      if (result.isConfirmed) {
        this.applicationService.editApplication(application.id, result.value)
          .pipe(first()).subscribe(response => {
          Swal.fire({
            title: '',
            text: 'Application updated',
            icon: 'success',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#38a745',
          }).then(() => this.getAllApplications())
        }, error => {
          Swal.fire({
            title: 'An error occurred',
            html: 'Please contact us at: <b>info@meshincentre.com</b> or via the contact form',
            icon: 'error',
            confirmButtonColor: '#f37b7b',
          });
          console.error('error', error);
        });
      }
    })
  }

  onDeleteUser(user: User) {
    Swal.fire({
      title: '',
      text: `Are you sure you want to delete ${user.firstname} ${user.lastname}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete!',
      cancelButtonColor: '#f37b7b',
      confirmButtonColor: '#38a745',
    }).then((result) => {
      if (result.isConfirmed) {
        this.userProfileService.deleteUser(user.id).pipe(first()).subscribe(response => {
          this.isUsers = false;
          Swal.fire({
            title: '',
            text: 'User deleted!',
            icon: 'success',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#38a745',
          }).then(() => this.getAllUsers())
        }, error => {
          Swal.fire({
            title: 'An error occurred',
            html: 'Please contact us at: <b>info@meshincentre.com</b> or via the contact form',
            icon: 'error',
            confirmButtonColor: '#f37b7b',
          });
          console.error('error', error);
        });
      }
    });
  }

  onDeleteJob(job: Job) {
    Swal.fire({
      title: '',
      text: `Are you sure you want to delete the ${job.title} job?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonColor: '#f37b7b',
      confirmButtonColor: '#38a745',
    }).then((result) => {
      if (result.isConfirmed) {
        this.jobService.deleteJob(job.id).pipe(first()).subscribe(response => {
          this.isJobs = false;
          Swal.fire({
            title: '',
            text: 'Job deleted!',
            icon: 'success',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#38a745',
          }).then(() => this.getAllJobs())
        }, error => {
          Swal.fire({
            title: 'An error occurred',
            html: 'Please contact us at: <b>info@meshincentre.com</b> or via the contact form',
            icon: 'error',
            confirmButtonColor: '#f37b7b',
          });
          console.error('error', error);
        });
      }
    });
  }
}
