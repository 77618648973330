import { Component, OnInit } from '@angular/core';
import { User } from '../../models/user.model';
import { UserProfileService } from '../../services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-candidate-list',
  templateUrl: './candidate-list.component.html',
  styleUrls: ['./candidate-list.component.scss']
})
export class CandidateListComponent implements OnInit {
  candidates: User[];
  p: number = 1;
  collection: any[];

  constructor(
    private userProfileService: UserProfileService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.userProfileService.getCandidates().subscribe(response => {
      this.candidates = response['users'].sort((a, b) => {
        return b.is_featured - a.is_featured;
      });
    }
    )
  }

  goToCandidateDetails(user) {
    this.router.navigate(['candidate-details'], {
      state: { data: JSON.stringify(user) }
    });
  }

}
