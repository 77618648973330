<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="page-title-text">
          <h2>Pricing</h2>
          <ul>
            <li><a routerLink="/">Home</a></li>
            <li><i class="icofont-simple-right"></i></li>
            <li>Pricing</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="pricing-area pt-5">
  <div class="container">
    <div *ngIf="!(isLoggedIn | async) || (isAdmin | async)" class="switch-container">
      <span class="switch-label">Employer </span>
      <label class="switch">
        <input type="checkbox" (change)="onSwitchChange()">
        <span class="slider round"></span>
      </label>
      <span class="switch-label">Candidate </span>
    </div>

    <div class="row">
      <div class="col-sm-6 col-lg-4">
        <div class="pricing-item wow fadeInUp" data-wow-delay=".3s">
          <span id="basic">{{(isCandidate | async) || !this.isEmployer ? 'Basic Plan': 'Bronze Plan'}}</span>
          <h3>{{(isCandidate | async) || !this.isEmployer ? 'FREE' : formatPrice(50000) + ' UGX'}}</h3>
          <ul>
            <li>{{(isCandidate | async) || !this.isEmployer ? 'Post a Profile' : 'Up to 3 Jobs Posting'}}</li>
            <li>{{(isCandidate | async) || !this.isEmployer ? 'No Featured Badge' : '1 Featured Job'}}</li>
            <li>{{(isCandidate | async) || !this.isEmployer ? 'Response as soon as possible' : 'No Interview Process'}}</li>
            <li *ngIf="!(isLoggedIn | async) && this.isEmployer || (isLoggedIn | async) && !(isCandidate | async)">
              30 Days Duration
            </li>
          </ul>
          <a class="pricing-btn" (click)="goToContact('basic')">Get Started</a>
        </div>
      </div>

      <div class="col-sm-6 col-lg-4">
        <div class="pricing-item pricing-two wow fadeInUp" data-wow-delay=".4s">
          <span id="premium">{{(isCandidate | async) || !this.isEmployer ? 'Premium Plan': 'Silver Plan'}}</span>
          <h3>{{(isCandidate | async) || !this.isEmployer ? formatPrice(5000) + ' UGX' : formatPrice(100000) + ' UGX'}}</h3>

          <ul>
            <li>{{(isCandidate | async) || !this.isEmployer ? 'Listed in first 20 profiles' : 'Up to 5 Jobs Posting'}}</li>
            <li>{{(isCandidate | async) || !this.isEmployer ? 'Featured Badge' : '2 Featured Job'}}</li>
            <li>{{(isCandidate | async) || !this.isEmployer ? 'Quick Response' : 'Interview Process'}}</li>
            <li *ngIf="!(isLoggedIn | async) && this.isEmployer || (isLoggedIn | async) && !(isCandidate | async)"
            >
              30 Days Duration
            </li>
          </ul>
          <a class="pricing-btn" (click)="goToContact('premium')">Get Started</a>
        </div>
      </div>

      <!-- TODO: Shall decide on the candidate's third pricing -->
      <div
        *ngIf="!(isLoggedIn | async) && this.isEmployer || (isLoggedIn | async) && !(isCandidate | async)"
        class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4"
      >
        <div class="pricing-item wow fadeInUp" data-wow-delay=".5s">
          <span id="advanced">Gold Plan</span>
          <h3>Contract Based</h3>

          <ul>
            <li>Job Postings</li>
            <li>Contract Employees</li>
            <li>Featured Jobs</li>
            <li>Specified Duration</li>
          </ul>
          <a class="pricing-btn" (click)="goToContact('advanced')">Get Started</a>
        </div>
      </div>
    </div>
  </div>
</div>
