<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="page-title-text">
          <h2>Contact Us</h2>
          <ul>
            <li><a routerLink="/">Home</a></li>
            <li><i class="icofont-simple-right"></i></li>
            <li>Contact Us</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="contact-form-area pt-5 pb-5">
  <div class="container-fluid post-job-item">
    <form id="contactForm" [formGroup]="contactForm" (ngSubmit)="onSubmit()">
      <div class="row contact-wrap">
        <div class="col-md-6">
          <div class="form-group">
            <label>Firstname *</label>
            <input
              class="form-control"
              type="text"
              required
              placeholder="Enter Firstname"
              formControlName="firstname"
            >
            <div *ngIf="submitted && f.firstname.errors" class="is-invalid">
              <div *ngIf="f.firstname.errors.required">Firstname required</div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label>Lastname *</label>
            <input
              class="form-control"
              type="text"
              required
              placeholder="Enter Lastname"
              formControlName="lastname"
            >
            <div *ngIf="submitted && f.lastname.errors" class="is-invalid">
              <div *ngIf="f.lastname.errors.required">Lastname required</div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label>Email *</label>
            <input
              class="form-control"
              type="email"
              required
              placeholder="Enter Email"
              formControlName="email"
            >
            <div *ngIf="submitted && f.email.errors" class="is-invalid">
              <div *ngIf="f.email.errors.required">Email required</div>
              <div *ngIf="f.email.errors.email || f.email.errors.pattern">
                Email must be a valid email address</div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label>Phone Number *</label>
            <ngx-intl-tel-input
              [preferredCountries]="preferredCountries"
              [enableAutoCountrySelect]="true"
              [enablePlaceholder]="true"
              [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
              [selectedCountryISO]="CountryISO.Uganda"
              [phoneValidation]="true"
              [customPlaceholder]="772123456"
              [numberFormat]="772123456"
              name="phone"
              formControlName="telephone">
            </ngx-intl-tel-input>
            <div *ngIf="submitted && f.telephone.errors" class="is-invalid">
              <div *ngIf="f.telephone.errors.required">telephone required</div>
              <div *ngIf="f.telephone.errors?.validatePhoneNumber">Invalid Number</div>
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <div class="form-group">
            <label>Subject *</label>
            <input
              class="form-control"
              type="text"
              required
              placeholder="Enter Subject"
              formControlName="subject"
            >
            <div *ngIf="submitted && f.subject.errors" class="is-invalid">
              <div *ngIf="f.subject.errors.required">Subject required</div>
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <div class="form-group">
            <label>Message *</label>
            <textarea
              class="form-control"
              cols="30"
              rows="8"
              required
              placeholder="Enter Message"
              formControlName="message"
            >
            </textarea>
            <div *ngIf="submitted && f.message.errors" class="is-invalid">
              <div *ngIf="f.message.errors.required">Message required</div>
            </div>
          </div>
        </div>

        <div class="col-md-12 col-lg-12">
          <div class="text-center">
            <button type="submit" class="btn green-active-white-hover">Send</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="map-area pb-100">
  <iframe width="600" height="500" id="gmap_canvas"
    src="https://maps.google.com/maps?q=Mesh%20Innovation%20Kisakye%20complex%20&t=&z=13&ie=UTF8&iwloc=&output=embed"
    frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
</div>
