import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../services/auth.service';
import { UserProfileService } from '../../services/user.service';
import { JobService } from '../../services/job.service';
import { User } from '../../models/user.model';
import { PhonePipe } from '../../pipes/phone.pipe';
import { first } from "rxjs/operators";
import { Job } from '../../models/job.model';
import Swal from 'sweetalert2';
import { CountryISO } from 'ngx-intl-tel-input';
import { SearchCountryField } from 'ngx-intl-tel-input';
import { PhoneNumberFormat } from 'ngx-intl-tel-input';
import { SelectLabelsService } from '../../services/selectLabels.service';

declare const $;
@Component({
  selector: 'app-dashboard',
  templateUrl: './employer-dashboard.component.html',
  styleUrls: ['./employer-dashboard.component.scss']
})
export class EmployerDashboardComponent implements OnInit, AfterViewInit {
  employerDashboardForm: FormGroup;
  submitted = false;
  user: User;
  jobsPosted: Job[];
  isUser: boolean;
  imageSrc: string;
  uploadedImage: File;
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.Uganda
  ];

  //Add online status and admin role
  selectUserStatus = [];
  isAdmin: boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    private phonePipe: PhonePipe,
    private router: Router,
    private authenticationService: AuthenticationService,
    private userProfileService: UserProfileService,
    private jobService: JobService,
    private selectLabelsService: SelectLabelsService,
  ) { }

  ngOnInit(): void {
    this.selectUserStatus = this.selectLabelsService.getOnlineStatus();
    this.authenticationService.isLoggedIn().subscribe(isUser => {
      if (isUser) {
        this.isUser = true;
        this.user = this.authenticationService.currentUser()['user'];
        this.employerDashboardForm = this.formBuilder.group({
          firstName: [this.user.firstname],
          lastName: [this.user.lastname],
          email: [this.user.email],
          createdDate: [this.user.created_at],
          telephone: [this.phonePipe.transform(this.user.telephone), Validators.required],
          address: [this.user.address, Validators.required],
          aboutMe: [this.user.about_me, [Validators.required, Validators.minLength(100)]],
          lineOfWork: [this.user.line_of_work, [Validators.required]],
          is_active: [this.user.is_active, [Validators.required]]
        });
        this.setSelectValue($('select').val());
      }
    });

    if (this.isUser) {
      this.jobService.getJobs().subscribe(response => {
        this.jobsPosted = response['jobs'].filter(job =>
          job.user_id === this.user.id);
      });
    }
  }

  ngAfterViewInit() {
    $('select').val(this.f.is_active.value).niceSelect('update');
  }

  setSelectValue(data) {
    if (data) {
      this.f.is_active.setValue(data.value);
    } else {
      this.f.is_active.setValue(this.f.is_active.value);
    }
  }

  get f() {
    return this.employerDashboardForm.controls;
  }

  deleteJob(id: number) {
    Swal.fire({
      title: '',
      text: 'Are you sure you want to delete this job?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonColor: '#f37b7b',
      confirmButtonColor: '#38a745',
    }).then((result) => {
      if (result.isConfirmed) {
        this.jobService.deleteJob(id).subscribe(response => {
          Swal.fire({
            title: 'Success',
            text: 'Job deleted successfully',
            icon: 'success',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#38a745',
          }).then(() =>
            this.jobsPosted = response['jobs'].filter(job => job.user_id === this.user.id));
        }, error => {
          Swal.fire({
            title: 'An error occurred',
            html: 'Please contact us at: <b>info@meshincentre.com</b> or via the contact form',
            icon: 'error',
            confirmButtonColor: '#f37b7b',
          });
          console.error('error', error);
        });
      }
    });
  }

  editJob(job) {
    this.router.navigate(['edit-job'], {
      state: { data: job }
    });
  }

  onSubmit() {
    const formData: User = Object.assign({}, this.employerDashboardForm.value);
    this.submitted = true;
    if (this.employerDashboardForm.invalid) {
      return;
    }
    formData.telephone = this.f.telephone.value.e164Number;
    formData.is_featured = 0;
    this.userProfileService.editUser(formData, this.user.id)
      .pipe(first()).subscribe(response => {
        Swal.fire({
          title: 'Your Account has been updated',
          text: 'Please login to continue',
          icon: 'success',
          confirmButtonColor: '#38a745',
        }).then(() => {
          this.onLogout();
          this.router.navigate(['authenticate/login']);
        });
      },
        error => {
          // Handle error
          Swal.fire({
            title: 'An error occurred',
            html: 'Please contact us at: <b>info@meshincentre.com</b> or via the contact form',
            icon: 'error',
            confirmButtonColor: '#f37b7b',
          });
          console.error('error', error)
        });
  }

  goToProfile() {
    this.router.navigate(['employer-details'], {
      state: { data: JSON.stringify(this.user) }
    });
  }

  onLogout() {
    this.authenticationService.logout();
  }
}
