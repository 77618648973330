import { Component, OnInit} from '@angular/core';
import { Observable } from 'rxjs';
import { LoaderService } from './services/loader.service';
import { Router, NavigationEnd } from '@angular/router';

declare const $;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isLoading: Observable<boolean>;

  constructor(
    private loaderService: LoaderService,
    private router: Router
    ) {}

  ngOnInit() {
    this.isLoading = this.loaderService.isLoading$;
    //on Pagechange, start from top of page
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
    });
  }
}
