<div class="modal-overlay"></div>
<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="page-title-text">
          <h2>Create Account</h2>
          <ul>
            <li><a routerLink="/">Home</a></li>
            <li><i class="icofont-simple-right"></i></li>
            <li>Create Account</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="create-account-area pt-5 pb-100">
  <div class="container">
    <form [formGroup]="createAccountForm" (ngSubmit)="onSubmit()">
      <div class="create-photo">
        <div class="already-create">
          <span>Already have an account?</span>
          <a routerLink="/login">Sign In</a>
        </div>

        <div class="row align-items-center">
          <div class="col-lg-12">
            <div class="modal fade" tabindex="-1" role="dialog">
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">Crop Image</h5>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      (click)="onCloseHandled()"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <image-cropper
                      [imageChangedEvent]="imageChangedEvent"
                      [imageURL]="imageURL"
                      [maintainAspectRatio]="true"
                      [containWithinAspectRatio]="containWithinAspectRatio"
                      [aspectRatio]="aspectRatio"
                      [resizeToWidth]="400"
                      [cropperMinWidth]="400"
                      [onlyScaleDown]="true"
                      [roundCropper]="false"
                      [canvasRotation]="canvasRotation"
                      [transform]="transform"
                      [alignImage]="'center'"
                      [style.display]="showCropper ? null : 'none'"
                      format="png, jpeg"
                      (imageCropped)="imageCropped($event)"
                      (imageLoaded)="imageLoaded()"
                      (cropperReady)="cropperReady($event)"
                      (loadImageFailed)="loadImageFailed()"
                    ></image-cropper>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-primary"
                      (click)="cropImage()"
                    >
                      Crop
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="account-type">
                <span>Account Type: *</span>
                <input
                  type="radio"
                  name="account"
                  id="candidate"
                  value="candidate"
                  formControlName="account"
                  (change)="handleControlNames()"
                >
                <label for="male">Candidate</label>
                <input
                  type="radio"
                  name="account"
                  id="employer"
                  value="employer"
                  formControlName="account"
                  (change)="handleControlNames()"
                >
                <label for="female">Employer</label>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="create-photo-item">
              <div class="create-photo-left">
                <div class="d-table">
                  <div class="d-table-cell">
                    <div class="form-group">
                      <div *ngIf="!croppedImage" class="upload-text">{{isCandidate ? 'Upload your Photo *' : 'Upload Logo
                        *'}}
                      </div>
                      <i *ngIf="!croppedImage" class="icofont-photobucket"></i>
                      <input
                        *ngIf="!croppedImage"
                        class="form-control-file"
                        type="file"
                        accept="image/png, image/jpeg"
                        (change)="onImageChange($event)"
                      >
                    </div>
                    <div *ngIf="croppedImage" class="image-container">
                      <i class="icofont-close" (click)="onRemoveImge()"></i>
                      <img id="image" [src]="croppedImage" alt="profile image"/>
                    </div>
                  </div>
                </div>
                <div *ngIf="submitted && !croppedImage" class="is-invalid">
                  Photo required
                </div>
                <div *ngIf="!isAllowedImage" class="is-invalid">
                  width & height max 400px and not greater than 2 MB
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-8">
            <div class="create-photo-item">
              <div class="create-photo-right">
                <div *ngIf="isCandidate" class="form-group">
                  <label>Your Profession *</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="profession"
                  >
                  <div *ngIf="submitted && f.profession.errors" class="is-invalid">
                    <div *ngIf="f.profession.errors.required">Your Profession required</div>
                  </div>
                </div>

                <div *ngIf="!isCandidate" class="form-group">
                  <label>Company Name *</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="companyName"
                  >
                  <div *ngIf="submitted && f.companyName.errors" class="is-invalid">
                    <div *ngIf="f.companyName.errors.required">Company Name required</div>

                  </div>
                </div>

                <div *ngIf="!isCandidate" class="form-group">
                  <label>Line of Work *</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="lineOfWork"
                  >
                  <div *ngIf="submitted && f.lineOfWork?.errors" class="is-invalid">
                    <div *ngIf="f.lineOfWork.errors.required">Line or Work required</div>
                    <div *ngIf="f.lineOfWork.errors.minlength">Must be at least 6 characters</div>
                  </div>
                </div>

                <div class="form-group" [ngClass]="{'not-candidate' : !isCandidate}">
                  <label>Availability *</label>
                  <app-select
                    [defaultText]="'--- Select Availability ---'"
                    [selectTexts]="selectLabels"
                    [invalidInput]="submitted && f.availability?.invalid"
                    formControlName="availability"
                    [formGroup]="createAccountForm"
                    (valueChangedEvent)="setSelectValue($event)"
                  ></app-select>
                  <div *ngIf="submitted && f.availability?.errors?.required" class="is-invalid">
                    Availability required
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="create-information">
        <h3>{{isCandidate ? 'Personal Information' : 'Contact Person'}}</h3>

        <div *ngIf="isCandidate" class="create-information-btn">
          <a (click)="uploadCV()">Upload Your CV</a>
          <span>{{cvImage}}</span>
          <span *ngIf="submitted && f.cv.errors?.required" class="is-invalid">
            CV required
          </span>
          <input
            type="file"
            class="form-control cv-upload"
            accept="application/doc, application/pdf"
            formControlName="cv"
            (change)="onCVChange($event)"
          >
        </div>

        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label>First Name: *</label>
              <input
                type="text"
                class="form-control"
                formControlName="firstName"
              >
              <div *ngIf="submitted && f.firstName.errors" class="is-invalid">
                <div *ngIf="f.firstName.errors.required">Firstname required</div>
                <div
                  *ngIf="f.firstName.errors.minlength"
                >
                  Must be at least 2 characters
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label>Last Name: *</label>
              <input
                type="text"
                class="form-control"
                formControlName="lastName"
              >
              <div *ngIf="submitted && f.lastName.errors" class="is-invalid">
                <div *ngIf="f.lastName.errors.required">Lastname required</div>
                <div
                  *ngIf="f.lastName.errors.minlength"
                >
                  Must be at least 2 characters
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label>Password: *</label>
              <span class="password-container">
                <input
                  [type]="isPasswordHidden ? 'password' : 'text'"
                  class="form-control"
                  formControlName="password"
                >
              <i [ngClass]="isPasswordHidden ? 'icofont-eye-blocked' : 'icofont-eye'" (click)="hidePassword(true)"></i>
              </span>
              <div *ngIf="submitted && f.password.errors" class="is-invalid">
                <div *ngIf="f.password.errors.required">Password required</div>
                <div *ngIf="f.password.errors.minlength">Must be at least 6 characters</div>
              </div>
              <app-password-strength
                [barLabel]="'Password Strength'"
                [passwordToCheck]="f.password.value"
                (passwordStrong)="passwordStrong($event)"
              ></app-password-strength>
              <div class="is-invalid" *ngIf="submitted && !isPasswordStrong">Password not strong</div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label>Confirm Password: *</label>
              <span class="password-container">
                 <input
                   [type]="isConfirmPasswordHidden ? 'password' : 'text'"
                   class="form-control"
                   formControlName="confirmPassword"
                 >
                <i [ngClass]="isConfirmPasswordHidden ? 'icofont-eye-blocked' : 'icofont-eye'" (click)="hidePassword(false)"></i>
              </span>
              <div *ngIf="submitted && (f.confirmPassword.invalid || createAccountForm.hasError('notSame'))"
                   class="is-invalid">
                Passwords should match
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label>Your Email: *</label>
              <input
                type="email"
                class="form-control"
                formControlName="email"
              >
              <div *ngIf="submitted && f.email.errors" class="is-invalid">
                <div *ngIf="f.email.errors.required">Email required</div>
                <div *ngIf="f.email.errors.email ||
                         f.email.errors.pattern"
                >Email must be a valid email address
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label>Date of Birth: *</label>
              <input
                type="date"
                class="form-control"
                formControlName="birthDate"
              >
              <div *ngIf="submitted && f.birthDate.errors" class="is-invalid">
                <div *ngIf="f.birthDate.errors.required">Date of Birth required</div>
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label>Your Phone: *</label>
              <ngx-intl-tel-input
                [preferredCountries]="preferredCountries"
                [enableAutoCountrySelect]="true"
                [enablePlaceholder]="true"
                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                [selectedCountryISO]="CountryISO.Uganda"
                [phoneValidation]="true"
                [customPlaceholder]="772123456"
                [numberFormat]="772123456"
                name="phone"
                formControlName="telephone">
              </ngx-intl-tel-input>
              <div *ngIf="submitted && f.telephone.errors" class="is-invalid">
                <div *ngIf="f.telephone.errors.required">Telephone required</div>
                <div *ngIf="f.telephone.errors?.validatePhoneNumber">Invalid Number</div>
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label>Address: *</label>
              <input
                type="text"
                class="form-control"
                formControlName="address"
              >
              <div *ngIf="submitted && f.address.errors" class="is-invalid">
                <div *ngIf="f.address.errors.required">Address required</div>
              </div>
            </div>
          </div>

          <div class="col-lg-12">
            <div class="form-group">
              <div class="gender-area">
                <span>Gender: *</span>
                <input
                  type="radio"
                  name="gender"
                  id="male"
                  value="male"
                  formControlName="gender"
                >
                <label for="male">Male</label>
                <input
                  type="radio"
                  name="gender"
                  id="female"
                  value="female"
                  formControlName="gender"
                >
                <label for="female">Female</label>
              </div>
            </div>
          </div>

          <div *ngIf="!isCandidate" class="col-lg-6">
            <div class="form-group">
              <label>Job Title: *</label>
              <input
                type="text"
                class="form-control"
                formControlName="jobTitle"
              >
              <div *ngIf="submitted && f.jobTitle.errors" class="is-invalid">
                <div *ngIf="f.jobTitle.errors.required">Job Title required</div>
                <div *ngIf="f.jobTitle.errors.minlength">Must be at least 6 characters</div>
              </div>
            </div>
          </div>

          <div *ngIf="!isCandidate" class="col-lg-6">
            <div class="form-group">
              <label>Responsibility: *</label>
              <input
                type="text"
                class="form-control"
                formControlName="responsibility"
              >
              <div *ngIf="submitted && f.responsibility.errors" class="is-invalid">
                <div *ngIf="f.responsibility.errors.required">Responsibility required</div>
                <div *ngIf="f.responsibility.errors.minlength">Must be at least 6 characters</div>
              </div>
            </div>
          </div>

          <div class="col-lg-12">
            <div class="form-group">
              <label>{{isCandidate ? 'About Me' : 'About the Company'}}: *</label>
              <textarea
                id="your_message"
                class="form-control"
                rows="8"
                formControlName="aboutMe"
              ></textarea>
              <div *ngIf="submitted && f.aboutMe.errors" class="is-invalid">
                <div *ngIf="f.aboutMe.errors.required">About Me required</div>
                <div *ngIf="f.aboutMe.errors.minlength">Must be at least 100 characters</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="isCandidate" class="create-education">
        <div class="create-education-wrap">
          <div class="create-education-left">
            <h3>Highest Education</h3>
          </div>

          <!--div class="create-education-right">
            <a (click)="generateEducation($event)">Add Education</a>
          </div-->
        </div>

        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label>Title: *</label>
              <input
                type="text"
                class="form-control"
                formControlName="educationTitle"
              >
              <div *ngIf="submitted && f.educationTitle.errors" class="is-invalid">
                <div *ngIf="f.educationTitle.errors.required">Education Title required</div>
                <div *ngIf="f.educationTitle.errors.minlength">Must be at least 6 characters</div>
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label>Qualification: *</label>
              <input
                type="text"
                class="form-control"
                formControlName="qualification"
              >
              <div *ngIf="submitted && f.qualification.errors" class="is-invalid">
                <div *ngIf="f.qualification.errors.required">Qualification required</div>
                <div *ngIf="f.qualification.errors.minlength">Must be at least 6 characters</div>
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label>Institute: *</label>
              <input
                type="text"
                class="form-control"
                formControlName="institute"
              >
              <div *ngIf="submitted && f.institute.errors" class="is-invalid">
                <div *ngIf="f.institute.errors.required">Institute required</div>
                <div *ngIf="f.institute.errors.minlength">Must be at least 6 characters</div>
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label>Year: *</label>
              <input
                type="text"
                class="form-control"
                formControlName="year"
              >
              <div *ngIf="submitted && f.year.errors" class="is-invalid">
                <div *ngIf="f.year.errors.required">Year required</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--div *ngIf="isCandidate" class="create-skills">
        <div class="create-skills-wrap">
          <div class="create-skills-left">
            <h3>Skills</h3>
          </div>

          <div class="create-skills-right">
            <a routerLink="/">Edit</a>
            <a routerLink="/">Add Skill</a>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label>Name:</label>
              <input
                type="text"
                class="form-control"
                formControlName="skillName"
              >
              <div *ngIf="submitted && f.skillName.errors" class="is-invalid">
                <div *ngIf="f.skillName.errors.required">Skill Name required</div>
                <div *ngIf="f.skillName.errors.minlength">Must be at least 6 characters</div>
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="skill">
              <p>Percentage:</p>
              <div class="skill-bar skill1 wow slideInLeft animated">
                <span class="skill-count1">70%</span>
              </div>
            </div>
          </div>
        </div>
      </div-->

      <div class="col-lg-12 terms">
        <div class="form-group">
          <input
            type="checkbox"
            name="terms"
            id="terms"
            formControlName="terms"
          >
          <label class="checkbox-label" for="terms">
            I have read and accept the
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://meshincentre.com/terms-and-conditions"
            >
              Terms and Conditions
            </a>
            of Mesh Innovation Centre *
          </label>
          <div
            class="is-invalid"
            *ngIf="submitted && !f.terms.value"
          >
            Terms required
          </div>
        </div>

      </div>
      <div *ngIf="submitted && createAccountForm.invalid" class="alert alert-danger" role="alert">
        <i class="icofont-warning-alt"></i>
        The form contains one or more invalid input.
      </div>
      <div class="text-left">
        <button type="submit" class="btn create-ac-btn">Save</button>
      </div>
    </form>
  </div>
</div>
