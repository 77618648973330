import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/auth.service';
import { User } from '../../models/user.model';
import { Observable } from 'rxjs/index';

declare const $;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  isLoggedIn: Observable<boolean>;
  isCandidate: Observable<boolean>;
  isAdmin: Observable<boolean>;
  user: User;

  constructor(
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.isCandidate = this.authenticationService.isCandidateLoggedIn();
    this.isAdmin = this.authenticationService.isAdminLoggedIn();
    this.isLoggedIn = this.authenticationService.isLoggedIn();
    this.isLoggedIn.subscribe(isUser => {
      if (isUser) {
        this.user = this.authenticationService.currentUser()['user'];
      }
      this.handleMobileMenu();
    });
  }

  ngAfterViewInit() {
    this.handleMobileMenu();
  }

  handleMobileMenu() {
    // START MENU JS
    $(window).on('scroll', function() {
      if ($(this).scrollTop() > 50) {
        $('.main-nav').addClass('menu-shrink');
      } else {
        $('.main-nav').removeClass('menu-shrink');
      }
    });

    // Needed to enable the changes in the main nav to reflect before copying to mobile nav
    setTimeout(() => {
      // Mean Menu
      $('.mean-menu').meanmenu({
        meanScreenWidth: '991'
      });
    }, 1000);
  }

  onLogout() {
    this.authenticationService.logout();
  }
}
