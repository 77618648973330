<div class="form-group">
  <label>{{textLabel}}</label>
  <div class="input-group input-group-merge">
    <div class="input-group-prepend">
      <span class="input-group-text" *ngIf="isIconDisplayed">
        <i class="icon-dual" [attr.data-feather]="inputIcon" appFeatherIcon></i>
      </span>
    </div>
    <input
      name="email"
      type="{{type}}"
      class="form-control"
      placeholder="{{placeHolder}}"
      autocomplete="new-password"

      [ngClass]="{'is-invalid': invalidInput}"
      [disabled]="isDisabled"
      [(ngModel)]="input"
      (ngModelChange)="onChange($event)"
      (blur)="onTouch()"
    >
  </div>
</div>
