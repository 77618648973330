<div class="account-pages">
  <div class="container">
    <div class="row pt-5 justify-content-center">
      <div class="col-xl-10 pt-5 pb-5 password-reset-area">
        <app-card [bodyClass]="'pt-5'">
          <div class="password-reset-area" body>
            <div class="row">
              <div class="col-6 password-reset-form">
                <h6 class="h5 mb-0 mt-4 password-reset-label">Reset Password</h6>
                <p class="text-muted mt-2">
                  Enter your new password.
                </p>
                <form
                  action="#"
                  class="authentication-form"
                  name="resetForm"
                  [formGroup]="resetForm"
                  (ngSubmit)="onSubmit()"
                  novalidate>
                  <!-- <app-ui-preloader [display]="loading"></app-ui-preloader> -->
                  <!-- <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert> -->
                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-12">
                        <app-input-text
                          [textLabel]="'New Password: *'"
                          [type]="'password'"
                          [invalidInput]="submitted && f.newPassword.invalid"
                          formControlName="newPassword"
                          [formGroup]="resetForm"
                        ></app-input-text>
                        <div *ngIf="submitted && f.newPassword.errors" class="is-invalid">
                          <div *ngIf="f.newPassword.errors.required">New password is required</div>
                          <div
                            *ngIf="f.newPassword.errors.minlength"
                          >
                            Must be at least 8 characters
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <app-input-text
                          [textLabel]="'Confirm new Password: *'"
                          [type]="'password'"
                          [invalidInput]="submitted && f.confirmNewPassword.invalid"
                          formControlName="confirmNewPassword"
                          [formGroup]="resetForm"
                        ></app-input-text>
                        <div
                          class="is-invalid"
                          *ngIf="submitted && (f.confirmNewPassword.invalid || resetForm.hasError('notSame'))">
                          Passwords should match
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="text-center">
                    <button class="btn green-active-white-hover" type="submit"> Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </app-card>
      </div>
    </div>
  </div>
</div>
