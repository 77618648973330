import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/auth.service';
import { UserProfileService } from '../../services/user.service';
import { User } from '../../models/user.model';
import { Application } from '../../models/application.model';
import { PhonePipe } from '../../pipes/phone.pipe';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray
} from '@angular/forms';
import { Job } from '../../models/job.model';
import { first, mergeMap } from "rxjs/operators";
import Swal from 'sweetalert2';
import { CountryISO } from 'ngx-intl-tel-input';
import { SearchCountryField } from 'ngx-intl-tel-input';
import { PhoneNumberFormat } from 'ngx-intl-tel-input';
import { Role } from '../../models/role.model';
import { SelectLabelsService } from '../../services/selectLabels.service';

declare const $;

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit, AfterViewInit {
  EditUserForm: FormGroup;
  submitted = false;
  cvImage: string;
  uploadedCV: File;
  user: User;
  jobsAppliedTo: Job[];
  applications: Application[];
  isUser: boolean;
  sliderMin: number = 0;
  sliderMax: number = 100;
  selectLabels = [];
  selectStatus = [];
  selectFeature = [];
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.Uganda
  ];
  isOnline: boolean;
  isCandidate: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private phonePipe: PhonePipe,
    private authenticationService: AuthenticationService,
    private userProfileService: UserProfileService,
    private selectLabelsService: SelectLabelsService,
  ) { }

  ngOnInit(): void {
    const filteredUsers = history.state.data;
    if (filteredUsers) {
      this.user = filteredUsers;
    } else {
      this.router.navigate(['admin']);
    }
    this.selectLabels = this.selectLabelsService.getAvailability();
    this.selectStatus = this.selectLabelsService.getOnlineStatus();
    this.selectFeature = this.selectLabelsService.getJobFeature();
    this.isCandidate = this.user?.account_type === Role.Candidate;
    this.EditUserForm = this.formBuilder.group({
      availability: [this.user?.availability],
      active: [this.user?.is_active ? 'Online' : 'Review', [Validators.required]],
      feature: [this.user?.is_featured ? 'Featured' : 'Not featured', [Validators.required]],
      firstName: [this.user?.firstname],
      lastName: [this.user?.lastname],
      companyName: [this.user?.company_name],
      accountType: [this.user?.account_type, [Validators.required]],
      email: [this.user?.email],
      birthDate: [this.user?.birth_date],
      telephone: [this.phonePipe.transform(this.user?.telephone), Validators.required],
      jobTitle: [this.user?.job_title, [Validators.minLength(6)]],
      address: [this.user?.address, Validators.required],
      aboutMe: [this.user?.about_me, [Validators.required, Validators.minLength(100)]],
      educationTitle: [this.user?.education_title],
      institute: [this.user?.education_institute],
      year: [this.user?.education_year],
      qualification: [this.user?.education_qualification],
      lineOfWork: [this.user?.line_of_work],
      skills: this.formBuilder.array([this.newElement()]),
    });
    if (this.isCandidate) {
      this.user?.skills.forEach((skill, i) => {
        if (i > 0) {
          this.addElement(null, 'skills');
        }
        this.skills.controls[i].setValue({
          skillTitle: skill['skillTitle'],
          skillLevel: skill['skillLevel']
        })
      });
    } else {
      this.EditUserForm.removeControl('skills')
    }
  }

  ngAfterViewInit() {
    $('select.availability').val(this.f.availability.value).niceSelect('update');
    $('select.active').val(this.f.active.value).niceSelect('update');
    $('select.feature').val(this.f.feature.value).niceSelect('update');
    $('select').next().addClass('default-select-option');
  }


  get f() {
    return this.EditUserForm.controls;
  }

  onHandleSelect(data: any, controlName: string) {
    if (data && data.value && data.elements.contains(controlName)) {
      this.f[controlName].setValue(data.value);
    } else if (!data) {
      this.f[controlName].setValue(null);
    }
  }

  newElement(): FormGroup {
    return this.formBuilder.group({
      skillTitle: ['', Validators.required],
      skillLevel: [50]
    })
  }

  removeElement(index: number, element: string) {
    this[element].removeAt(index);
  }

  addElement(e, element: string) {
    if (e) {
      e.preventDefault();
    }
    this[element].push(this.newElement());
  }

  get skills(): FormArray {
    return this.EditUserForm.controls['skills'] as FormArray;
  }

  onSubmit() {
    const formData: User = Object.assign({}, this.EditUserForm.value);
    this.submitted = true;
    if (this.EditUserForm.invalid) {
      return;
    }
    formData.telephone = this.f.telephone.value.e164Number;
    formData.is_active = this.f.active.value === 'Online' ? 1 : 0;
    delete formData['active'];
    formData.is_featured = this.f.feature.value === 'Featured' ? 1 : 0;
    delete formData['feature'];
    this.userProfileService.editUser(formData, this.user.id)
      .pipe(first()).subscribe(response => {
        this.handleResponse(true);
      },
        error => {
          this.handleResponse(false);
          console.error('error', error)
        });
  }

  handleResponse(isSuccess: boolean) {
    if (isSuccess) {
      Swal.fire({
        title: '',
        text: 'Account has been updated successfully',
        icon: 'success',
        confirmButtonColor: '#38a745',
      }).then(() => {
        this.router.navigate(['admin']);
      });
    }
  }
}
