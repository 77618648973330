import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Job } from '../models/job.model';

@Injectable({ providedIn: 'root' })
export class ApplicationService {
  headers = new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' });
  constructor(
    private http: HttpClient,
  ) { }

  /**
    * Gets All Applications
    */
  getApplications() {
    return this.http.get(
      'applications',
      { headers: this.headers })
      .pipe(map(response => response));
  }

  /**
   * Get my applications
   */
  getMyApplications() {
    return this.http.get(
      'user-applications',
      { headers: this.headers })
      .pipe(map(response => response));
  }

  /**
   * Search the jobs
   *
   * @param search
   */
  searchJobs(search: Job) {
    return this.http.post(
      'search-jobs',
      search,
      { headers: this.headers })
      .pipe(map(response => response));
  }

  /**
   * Post application
   *
   * @param job
   */
  postApplication(job: Job) {
    return this.http.post(
      'application',
      job,
      { headers: this.headers })
      .pipe(map(response => response));
  }

  /**
   * Edit application
   * @param id
   * @param status
   */
  editApplication(id: number, status: string) {
    return this.http.put(
      `application/${id}`,
      {status})
      .pipe(map(response => response));
  }
}
