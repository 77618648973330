import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../../models/user.model';
import { Job } from '../../models/job.model';
import { JobService } from '../../services/job.service';
import { first } from 'rxjs/operators';


@Component({
  selector: 'app-employer-details',
  templateUrl: './employer-details.component.html',
  styleUrls: ['./employer-details.component.scss']
})
export class EmployerDetailsComponent implements OnInit {
  user: User;
  jobs: Job[];
  categoryTitles = [];
  locationTitles = [];

  constructor(
    private router: Router,
    private jobService: JobService
  ) { }

  ngOnInit(): void {
    const filterUsers = history.state.data;
    if (filterUsers) {
      this.user = JSON.parse(filterUsers);
    } else {
      this.router.navigate(['employer-dashboard']);
    }
    this.jobService.getJobs().pipe(first())
      .subscribe(response => {
        this.jobs = response['jobs'];
        this.categoryTitles = [...new Set(this.jobs.map(job => job.category))];
        this.categoryTitles.push(this.categoryTitles.splice(this.categoryTitles.indexOf('Other'), 1)[0]);
        this.locationTitles = [...new Set(this.jobs.map(job => job.location))];
      });
  }

  formatTitle(category) {
    return category.indexOf('Services') > -1 ? category.split('Services')[0] : category;
  }

  getSize(type: string, title: string) {
    return this.jobs.filter(job => job[type] === title).length;
  }

  handleRoute(type: string, title: string) {
    const jobs = this.jobs.filter(job => job[type] === title);
    this.router.navigate(['job-list'], {
      state: {data: jobs}
    });
  }
}
