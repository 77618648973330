<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="page-title-text">
          <h2>Candidate Details</h2>
          <ul>
            <li><a routerLink="/">Home</a></li>
            <li><i class="icofont-simple-right"></i></li>
            <li>Candidate Details</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="single-profile-area pt-5">
  <div class="container">
    <div *ngIf="!user?.is_active" class="alert alert-warning" role="alert">
      <i class="icofont-warning-alt"></i>
      Your profile is not yet visible to <b>employers</b>, because it is still under review.
    </div>
    <div class="row align-items-center">
      <div class="col-lg-5">
        <div class="single-profile-item">
          <img src="{{ user?.image }}" alt="Profile">

          <div class="single-profile-left">
            <div class="single-profile-contact">
              <h3>Contact Info</h3>

              <ul>
                <li><i class="icofont-location-pin"></i> {{ user?.address }}</li>
                <li><i class="icofont-email"></i>
                  <a href="mailto:hello@peof.com">
                    {{user?.email}}
                  </a>
                </li>
                <li><i class="icofont-ui-call"></i>
                  <a href="{{ 'tel:' + user?.telephone }}">
                    {{ user?.telephone }}
                  </a>
                </li>
              </ul>
            </div>

            <!-- <div class="single-profile-social">
              <h3>Social Links</h3>

              <ul>
                <li><i class="icofont-facebook"></i> <a href="#" target="_blank">https://www.facebook.com</a></li>
                <li><i class="icofont-instagram"></i> <a href="#" target="_blank">https://www.instagram.com</a></li>
                <li><i class="icofont-linkedin"></i> <a href="#" target="_blank">https://www.linkedin.com</a></li>
                <li><i class="icofont-twitter"></i> <a href="#" target="_blank">https://www.twitter.com</a></li>
              </ul>
            </div> -->

            <div class="single-profile-skills">
              <h3>My Skills</h3>

              <div class="skill" *ngFor="let skill of user?.skills">
                <p> {{ skill.skillTitle }}</p>
                <div class="skill-bar skill1 wow slideInLeft animated">
                  <span class="skill-count1">{{ skill.skillLevel }}%</span>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-7">
        <div class="single-profile-item">
         <div class="single-profile-right">
            <div class="single-profile-name">
              <h2>{{ user?.firstname + ' ' + user?.lastname }}</h2>
              <div class="row">
                <div class="col-lg-6">
                  <span>{{ user?.job_title }}</span>
                  <p>{{ user?.education_title }}</p>
                </div>
                <div class="col-lg-6">
                  Availability:
                  <p>{{ user?.availability }}</p>
                </div>
              </div>
              <a
                class="green-gradient-hover"
                href="{{ user?.cv }}"
                target="_blank"
              >
                View CV
                <i class="icofont-eye-alt"></i>
              </a>
            </div>

            <div class="single-profile-textarea">
              <div class="single-profile-heading">
                <span></span>
                <h3>Description</h3>
              </div>

              <div class="single-profile-paragraph">
                <p class="single-profile-p">{{ user?.about_me }}</p>
              </div>

              <div class="single-profile-heading">
                <span></span>
                <h3>Highest Education</h3>
              </div>

              <div class="single-profile-paragraph">
                <!-- //TODO: Add education incase it is an array -->
                <!-- <ul *ngFor="let education of user.education"> -->
                <ul>
                  <li><span>Title: </span>{{user?.education_title}}</li>
                  <li><span>Institute: </span>{{user?.education_institute}}</li>
                  <li><span>Qualification: </span>{{user?.education_qualification}}</li>
                  <li><span>Year: </span>{{user?.education_year}}</li>
                </ul>
              </div>

              <!-- TODO: Check if still needed -->

              <!--div class="single-profile-heading">
                <span></span>
                <h3>Research</h3>
              </div>

              <div class="single-profile-paragraph">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra</p>
              </div>

              <div class="single-profile-heading">
                <span></span>
                <h3>Work Experiences</h3>
              </div>

              <div class="single-profile-paragraph">
                <ul>
                  <li>Hand On experience with Wordpress</li>
                  <li> Better knowledge of front-end technologies, including HTML5, CSS3, JavaScript, jQuery</li>
                  <li>Belief – believing in yourself and those around you</li>
                  <li>Experience designing and developing responsive design websites</li>
                </ul>
              </div-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
