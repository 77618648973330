import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { JobService } from '../../services/job.service';
import { AuthenticationService } from '../../services/auth.service';
import { UserProfileService } from '../../services/user.service';
import { ApplicationService } from '../../services/application.service';
import { Job } from '../../models/job.model';
import { User } from '../../models/user.model';
import { ActivatedRoute, Router } from '@angular/router';
import { first, mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs/index';
import { SelectLabelsService } from '../../services/selectLabels.service';
import { UtilsService } from '../../services/utils.service';

declare const $;

@Component({
  selector: 'app-job-list',
  templateUrl: './job-list.component.html',
  styleUrls: ['./job-list.component.scss']
})
export class JobListComponent implements OnInit {
  @ViewChild('selectElement') select;
  searchForm: FormGroup;
  filteredJobs: any;
  locationDefaultText: string = 'Location';
  categoryDefaultText: string = 'Category';
  jobTypeDefaultText: string = 'Job type';
  placeOfWorkDefaultText: string = 'Place of work';
  jobs: Job[] = [];
  keyword: string = 'title';
  isFromAnotherPage: boolean;
  isCandidate: Observable<boolean>;
  p: number = 1;
  collection: any[];
  employers: User[] = [];
  appliedToJobs: Job[];
  user: User;
  isFeatured: boolean;
  selectLocation: string[] = [];
  selectJobType: string[] = [];
  selectPlaceOfWork: string[] = [];
  selectCategory: string[] = [];
  timeFrame: any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private jobService: JobService,
    private userProfileService: UserProfileService,
    private authenticationService: AuthenticationService,
    private applicationService: ApplicationService,
    private selectLabelsService: SelectLabelsService,
    private utilsService: UtilsService
  ) { }

  ngOnInit(): void {
    this.selectCategory = this.selectLabelsService.getJobCategory();
    this.selectJobType = this.selectLabelsService.getJobType();
    this.selectPlaceOfWork = this.selectLabelsService.getJobPlaceOfWork();
    this.isCandidate = this.authenticationService.isCandidateLoggedIn();
    this.searchForm = this.formBuilder.group({
      location: [''],
      category: [''],
      jobType: [''],
      placeOfWork: [''],
      searchInput: ['']
    });
    setTimeout(() => {
      if (history.state.data) {
        this.filteredJobs = history.state.data;
        this.isFromAnotherPage = true;
      }
      this.initialiseFilters();
    });
  }

  getDifferenceInDays(job: Job) {
    const currentDaysAfterPost = this.utilsService.daysLeft(
      new Date(job.created_at.split(' ')[0]), new Date());
    const days = this.utilsService.daysLeft(
      new Date(job.created_at.split(' ')[0]), new Date(job.expiry_at));
    const daysLeft = Math.round(days - currentDaysAfterPost);
    return daysLeft > 0 ? `${daysLeft} days left` : `A few hours left`;
  }

  onHandleSelect(data: any, controlName: string) {
    if (data && data.value && data.elements.contains(controlName)) {
      this.f[controlName].setValue(data.value);
      this.onSubmit();
    } else if (!data) {
      this.f[controlName].setValue(null);
    }
  }

  onReset() {
    this.isFromAnotherPage = false;
    this.filteredJobs = this.jobs;
    this.select.onResetSelect('location', this.locationDefaultText);
    this.select.onResetSelect('category', this.categoryDefaultText);
    this.select.onResetSelect('jobType', this.jobTypeDefaultText);
    this.select.onResetSelect('placeOfWork', this.placeOfWorkDefaultText);
    this.initialiseFilters();
  }

  initialiseFilters() {
    this.onHandleSelect($('.location').val(), 'location');
    this.onHandleSelect($('.category').val(), 'category');
    this.onHandleSelect($('.jobType').val(), 'jobType');
    this.onHandleSelect($('.placeOfWork').val(), 'placeOfWork');
    if (!this.isFromAnotherPage) {
      this.getTopJobs()
    }
  }

  get f() {
    return this.searchForm.controls;
  }

  getTopJobs() {
    this.jobService.getJobs().pipe(
      mergeMap(response => {
        this.jobs = response['jobs'];
        this.selectLocation = [...new Set(response['jobs'].map(job => job.location))] as string[];
        this.filteredJobs = this.jobs.sort((a, b) =>
          b.is_featured - a.is_featured);
        this.filteredJobs = this.filteredJobs.filter(job => job.status === 'Reviewed');
        if (this.authenticationService.currentUser()) {
          this.setAppliedToJobs(this.jobs);
        }
        return this.userProfileService.getEmployers()
      })
    ).pipe(first()).subscribe(response => this.employers = response['users'])
  }

  setAppliedToJobs(jobs: Job[]) {
    this.applicationService.getMyApplications().pipe(first())
      .subscribe(response => {
        this.appliedToJobs = response['applications'].map(application => {
          return jobs.find(job => {
            if (job.application_ids && job.application_ids.some(id => id === application.id)) {
              return job.id;
            }
          });
        });
      })
  }

  getLogo(job: Job) {
    return this.employers.find(employer => employer.id === job.user_id)
  }

  isApplied(job: Job) {
    if (this.appliedToJobs) {
      return this.appliedToJobs.some(appliedJob => appliedJob && appliedJob.id === job.id)
    }
  }

  onSubmit() {
    this.jobService.searchJobs(this.searchForm.value)
      .pipe(first()).subscribe(response => this.filteredJobs = response);
  }

  goToJobDetails(job) {
    this.router.navigate(['job-details'], {
      state: { data: JSON.stringify(job) }
    });
  }

  goToCompanyDetails(id: number) {
    if (this.authenticationService.currentUser()) {
      this.userProfileService.getJobEmployer(id)
        .pipe(first()).subscribe(response => {
          this.router.navigate(['company-details'], {
            state: { data: JSON.stringify(response['user'][0]) }
          });
        });
    } else {
      this.router.navigate(['authenticate/login']);
    }
  }

  onSelectItem(item: Job) {
    this.f.searchInput.setValue(item.title);
    this.onSubmit();
  }

  onChangeSearch(keyword: string) {
    this.f.searchInput.setValue(keyword);
  }

  onCleared() {
    this.filteredJobs = this.jobs;
    this.f.searchInput.setValue('');
  }
}
