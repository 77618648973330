export * from './home/home.component';
export * from './about/about.component';
export * from './job-list/job-list.component';
export * from './job-results/job-results.component';
export * from './favourite-job/favourite-job.component';
export * from './job-details/job-details.component';
export * from './post-a-job/post-a-job.component';
export * from './edit-job/edit-job.component';
export * from './candidate-list/candidate-list.component';
export * from './candidate-details/candidate-details.component';
export * from './single-resume/single-resume.component';
export * from './submit-resume/submit-resume.component';
export * from './pricing/pricing.component';
export * from './candidate-dashboard/candidate-dashboard.component';
export * from './employer-dashboard/employer-dashboard.component';
export * from './company-list/company-list.component';
export * from './employer-details/employer-details.component';
export * from './profile/profile.component';
export * from './single-profile/single-profile.component';
export * from './faq/faq.component';
export * from './terms-and-conditions/terms-and-conditions.component';
export * from './privacy-policy/privacy-policy.component';
export * from './contact/contact.component';
export * from './blog-details/blog-details.component';
export * from './blog/blog.component';
export * from './settings/settings.component';
export * from './edit-user/edit-user.component';
export * from './admin/admin.component';
export * from './comment/comment.component';
