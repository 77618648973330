<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="page-title-text">
          <h2>Company List</h2>
          <ul>
            <li><a routerLink="/">Home</a></li>
            <li><i class="icofont-simple-right"></i></li>
            <li>Company List</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<section class="companies-area companies-area-two pt-5">
  <div class="container">
    <div class="row">
      <div class="col-sm-6 col-lg-3" *ngFor="let employer of employers | paginate: { itemsPerPage: 12, currentPage: p }">
        <div *ngIf="employer.is_active" class="companies-item wow fadeInUp" data-wow-delay=".3s">
          <img src="{{ employer.image }}" alt="Companies">
          <h3><a routerLink="/company-details">{{ employer.company_name }}</a></h3>
          <p><i class="icofont-location-pin"></i> {{ employer.address }}</p>
          <a class="companies-btn" (click)="goToCompanyDetails(employer)">View</a>
        </div>
      </div>
      <pagination-controls (pageChange)="p = $event"></pagination-controls>
    </div>
  </div>
</section>
