import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { UserProfileService } from '../../services/user.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userProfileService: UserProfileService,
  ) { }

  ngOnInit() {
    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  get f() { return this.forgotPasswordForm.controls; }

  /**
   * On submit form
   */
  onSubmit() {
    this.submitted = true;
    if (this.forgotPasswordForm.invalid) {
      return;
    }
    this.userProfileService.forgotPassword(this.forgotPasswordForm.value)
      .subscribe(
        data => {
          if (data.message) {
            Swal.fire({
              title: 'Check your email!',
              text: 'The password reset link has been sent to your email',
              icon: 'success',
              confirmButtonColor: '#38a745',
            });
          }
        },
        error => {
          Swal.fire({
            title: 'Oops...',
            html: 'Something went wrong!',
            icon: 'error',
            confirmButtonColor: '#f37b7b',
          });
        });
  }
}
