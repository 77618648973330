import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AuthenticationService } from '../../services/auth.service';
import { UserProfileService } from '../../services/user.service';
import { User } from '../../models/user.model';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  @ViewChild('select', { static: true }) select: ElementRef;
  user: User;
  settingsForm: FormGroup;
  isPasswordStrong: boolean;
  submitted = false;
  isOnline: boolean;
  passwordFields: string[] = [
    'currentPassword',
    'confirmCurrentPassword',
    'newPassword',
    'confirmNewPassword'
  ];

  constructor(
    private router: Router,
    private userProfileService: UserProfileService,
    private fb: FormBuilder,
    private authenticationService: AuthenticationService
  ) {
  }

  ngOnInit(): void {
    this.user = this.authenticationService.currentUser()['user'];
    if (this.user) {
      this.isOnline = this.user.is_active > 0;
    }
    this.settingsForm = this.fb.group(
      {
        newsletters: ['yes', [Validators.required]],
        currentPassword: ['', [Validators.required]],
        confirmCurrentPassword: ['', Validators.required],
        newPassword: ['', [Validators.required, Validators.minLength(6)]],
        confirmNewPassword: ['', Validators.required],
      },
      { validator: this.checkPasswords }
    );
  }

  get f() {
    return this.settingsForm.controls;
  }

  /**
   * Checks for same passwords
   * @param formGroup element
   * @return notSame: boolean
   */

  checkPasswords(formGroup: FormGroup) {
    const currentPass = formGroup.get('currentPassword').value;
    const confirmCurrentPass = formGroup.get('confirmCurrentPassword').value;
    const newPass = formGroup.get('newPassword').value;
    const confirmNewPass = formGroup.get('confirmNewPassword').value;
    if (currentPass !== confirmCurrentPass) {
      return {isCurrentPasswordNotSame: true}
    } else if (newPass !== confirmNewPass ) {
      return {isNewPasswordNotSame: true}
    }
    return null;
  }

  handleControls() {
    if (this.passwordFields.some(field => this.f[field].value.length > 0)) {
      this.passwordFields.forEach(field => {
        this.settingsForm.get(field).setValidators([Validators.required]);
      })
    } else {
      this.passwordFields.forEach(field => {
        this.settingsForm.get(field).clearValidators();
        this.settingsForm.get(field).setErrors(null);
      });
    }
  }

  onSubmit() {
    this.submitted = true;
    this.handleControls();
    if (this.settingsForm.invalid || this.isPasswordStrong) {
      return;
    }
    this.userProfileService.changePassword(this.settingsForm.value)
      .subscribe(response => {
        Swal.fire({
          title: 'Settings Updated',
          text: 'Please login in to continue',
          icon: 'success',
          confirmButtonColor: '#38a745',
        }).then(() => {
          this.onLogout();
          this.router.navigate(['authenticate/login']);
        });
      },
        error => {
          Swal.fire({
            title: 'An error occurred',
            html: 'Please contact us at: <b>info@meshincentre.com</b> or via the contact form',
            icon: 'error',
            confirmButtonColor: '#f37b7b',
          });
          console.error('error', error)
        }
      );
  }

  onLogout() {
    this.authenticationService.logout();
  }
}
