import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PagesRoutingModule } from './pages-routing.module';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { ImageCropperModule } from 'ngx-image-cropper';

import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgxPaginationModule } from 'ngx-pagination';
import { DataTablesModule } from "angular-datatables";

import {
  HomeComponent,
  AboutComponent,
  JobListComponent,
  JobResultsComponent,
  FavouriteJobComponent,
  JobDetailsComponent,
  PostAJobComponent,
  EditJobComponent,
  CandidateListComponent,
  CandidateDetailsComponent,
  SingleResumeComponent,
  SubmitResumeComponent,
  PricingComponent,
  CandidateDashboardComponent,
  EmployerDashboardComponent,
  CompanyListComponent,
  EmployerDetailsComponent,
  ProfileComponent,
  SingleProfileComponent,
  FaqComponent,
  TermsAndConditionsComponent,
  PrivacyPolicyComponent,
  ContactComponent,
  BlogDetailsComponent,
  BlogComponent,
  SettingsComponent,
  EditUserComponent,
  AdminComponent,
  CommentComponent
} from './index';

import {
  SelectComponent,
  CardComponent,
  InputTextComponent,
  SliderComponent,
  AccordionComponent,
  TableComponent
} from '../components';

@NgModule({
  declarations: [
    ProfileComponent,
    HomeComponent,
    AboutComponent,
    JobListComponent,
    JobResultsComponent,
    FavouriteJobComponent,
    JobDetailsComponent,
    PostAJobComponent,
    EditJobComponent,
    CandidateListComponent,
    CandidateDetailsComponent,
    SingleResumeComponent,
    SubmitResumeComponent,
    PricingComponent,
    CandidateDashboardComponent,
    EmployerDashboardComponent,
    CompanyListComponent,
    EmployerDetailsComponent,
    ProfileComponent,
    SingleProfileComponent,
    FaqComponent,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent,
    ContactComponent,
    BlogDetailsComponent,
    BlogComponent,
    SettingsComponent,
    EditUserComponent,
    AdminComponent,
    SelectComponent,
    CardComponent,
    InputTextComponent,
    SliderComponent,
    AccordionComponent,
    TableComponent,
    CommentComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PagesRoutingModule,
    SweetAlert2Module,
    AutocompleteLibModule,
    ImageCropperModule,
    NgxIntlTelInputModule,
    NgxPaginationModule,
    DataTablesModule
  ],
  exports: [
    SelectComponent,
    CardComponent,
    InputTextComponent,
    AccordionComponent,
    ImageCropperModule,
    TableComponent
  ],
})
export class PagesModule { }
