<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="page-title-text">
          <h2>Employer Dashboard</h2>
          <ul>
            <li><a routerLink="/">Home</a></li>
            <li><i class="icofont-simple-right"></i></li>
            <li>Employer Dashboard</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="dashboard-area pt-5">
  <div class="container">
    <div *ngIf="!user?.is_active" class="alert alert-warning" role="alert">
      <i class="icofont-warning-alt"></i>
      Your profile is not yet visible to <b>candidates</b>, because it is still under review.
    </div>
    <form [formGroup]="employerDashboardForm" (ngSubmit)="onSubmit()">
      <div class="row align-items-center">
        <div class="col-lg-4">
          <div class="dashboard-img">
            <img src="{{user.image}}" alt="Dashboard">
            <div class='status-circle' [ngClass]="user?.is_active === 1 ? 'online' : 'offline'">
            </div>
            <h3>{{user.company_name}}</h3>
            <p>{{user.line_of_work}}</p>
          </div>
        </div>

        <div class="col-lg-8">
          <div class="dashboard-nav">
            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="pills-home-tab"
                  data-toggle="pill"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                  (click)="goToProfile()"
                >
                  My Profile
                </a>
              </li>

              <li class="nav-item">
                <a
                  class="nav-link"
                  id="pills-profile-tab"
                  data-toggle="pill"
                  routerLink="/settings"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                >
                  Change Password
                </a>
              </li>

              <!-- TODO: Decided if still needed -->
              <!--li class="nav-item">
                <a
                 class="nav-link"
                  id="pills-contact-tab"
                   data-toggle="pill"
                    href="#pills-contact"
                     role="tab"
                      aria-controls="pills-contact"
                       aria-selected="false"
                       >
                       Saved Jobs
                       </a>
              </li-->

              <li class="nav-item">
                <a class="nav-link" id="pills-logout-tab" data-toggle="pill" role="tab" aria-controls="pills-logout"
                  aria-selected="false" (click)="onLogout()">
                  Logout
                </a>
              </li>
            </ul>
          </div>

          <div class="create-skills">
            <div class="create-skills-wrap">
              <div class="create-skills-left">
                <h3>Jobs Posted <span>({{jobsPosted?.length}})</span></h3>
              </div>
            </div>
            <div class="row title-container">
              <div class="col-sm-5">
                <div class="form-group">
                  <p>Jobs</p>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <p>Status</p>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group">
                  <p>Applicants</p>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group">
                  <p>Action</p>
                </div>
              </div>
            </div>
            <ul>
              <li *ngFor="let job of jobsPosted; let i = index;">
                <div class="row">
                  <div class="col-md-5">
                    <div class="form-group">
                      <div class="mobile-element title">{{(i + 1) + '. ' + job.title}}</div>
                      <label [ngClass]="{'reviewed': job.status === 'Reviewed'}"> {{(i + 1) + '. ' + job.title}}</label>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group">
                      <label [ngClass]="{'reviewed': job.status === 'Reviewed'}">
                        <i [ngClass]="job.status === 'Reviewed' ? 'icofont-check-circled' : 'icofont-clock-time'"></i>
                        {{job.status}}
                      </label>
                    </div>
                  </div>
                  <div class="col-md-2 applicants">
                    <div class="form-group">
                      <label>
                        <span class="mobile-element">Applicants: </span>
                        <span> {{job.application_ids ? job.application_ids.length : 0}} </span>
                      </label>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label [ngClass]="{'not-reviewed': job.status !== 'Reviewed'}">
                        <i class="icofont-edit" (click)="editJob(job)"></i>
                        <i class="icofont-ui-delete" (click)="deleteJob(job.id)"></i>
                      </label>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
          <div class="create-information">
            <div class="account-created-container">
              <label>Account Created On:</label>
              <div>{{ user.created_at | date: 'longDate' }}</div>
            </div>
            <h3>Contact Person</h3>
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group">
                  <label>First Name:</label>
                  <input type="text" class="form-control" [attr.disabled]="true" formControlName="firstName">
                </div>
              </div>

              <div class="col-lg-6">
                <div class="form-group">
                  <label>Last Name:</label>
                  <input type="text" class="form-control" [attr.disabled]="true" formControlName="lastName">
                </div>
              </div>

              <div class="col-lg-6">
                <div class="form-group">
                  <label>Email: *</label>
                  <input type="email" class="form-control" [attr.disabled]="true" formControlName="email">
                  <div *ngIf="submitted && f.email.errors" class="is-invalid">
                    <div *ngIf="f.email.errors.required">Email required</div>
                    <div *ngIf="f.email.errors.email ||
                               f.email.errors.pattern">Email must be a valid email address
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="form-group">
                  <label>Telephone: *</label>
                  <ngx-intl-tel-input [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true"
                    [enablePlaceholder]="true" [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                    [selectedCountryISO]="CountryISO.Uganda" [phoneValidation]="true" [customPlaceholder]="772123456"
                    [numberFormat]="772123456" name="phone" formControlName="telephone">
                  </ngx-intl-tel-input>
                  <div *ngIf="submitted && f.telephone.errors" class="is-invalid">
                    <div *ngIf="f.telephone.errors.required">Phone required</div>
                    <div *ngIf="f.telephone.errors?.validatePhoneNumber">Invalid Number</div>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="form-group">
                  <label>Address:</label>
                  <input type="text" class="form-control" formControlName="address">
                  <div *ngIf="submitted && f.address.errors" class="is-invalid">
                    <div *ngIf="f.address.errors.required">Address required</div>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="form-group">
                  <label>Line of Work:</label>
                  <input type="text" class="form-control" formControlName="lineOfWork">
                  <div *ngIf="submitted && f.lineOfWork.errors" class="is-invalid">
                    <div *ngIf="f.lineOfWork.errors.required">Address required</div>
                  </div>
                </div>
              </div>

              <!-- <div class="col-lg-12">
                <div class="form-group">
                  <div class="gender-area">
                    <span>Gender</span>
                    <input
                      type="radio"
                      name="gender"
                      id="male"
                      value="male"
                      checked
                      formControlName="gender"
                    >
                    <label for="male">Male</label>
                    <input
                      type="radio"
                      name="gender"
                      id="female"
                      value="female"
                      formControlName="gender"
                    >
                    <label for="female">Female</label>
                  </div>
                </div>
              </div> -->

              <div class="col-lg-12">
                <div class="form-group">
                  <label>About the Company</label>
                  <textarea id="your_message" class="form-control" rows="8" formControlName="aboutMe">
                  </textarea>
                  <div *ngIf="submitted && f.aboutMe.errors" class="is-invalid">
                    <div *ngIf="f.aboutMe.errors.required">Description required</div>
                    <div *ngIf="f.aboutMe.errors.minlength">Must be at least 100 characters</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="submitted && employerDashboardForm.invalid" class="alert alert-danger" role="alert">
            <i class="icofont-warning-alt"></i>
            The form contains one or more invalid input.
          </div>
          <div class="text-left">
            <button type="submit" (tap)="onSubmit()" class="btn green-gradient-hover">Save</button>
          </div>
        </div>

        <!-- TODO: implement later -->
        <!-- <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
          <div class="change-password-area">
            <h2>Change Your Password</h2>

            <div class="form-group">
              <label>Current Password:</label>
              <input type="password" class="form-control">
            </div>

            <div class="form-group">
              <label>New Password:</label>
              <input type="password" class="form-control">
            </div>

            <div class="form-group">
              <label>Confirm Password:</label>
              <input type="password" class="form-control">
            </div>

            <div class="text-left">
              <button type="submit" class="btn change-pass-btn">Save</button>
            </div>
          </div>
        </div> -->

        <!--div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
          <div class="dashboard-saved-job">
            <div class="row">
              <div class="col-lg-6">
                <div class="job-item wow fadeInUp" data-wow-delay=".3s">
                  <img src="assets/img/home-1/jobs/1.png" alt="Job">

                  <div class="job-inner align-items-center">
                    <div class="job-inner-left">
                      <h3>UI/UX Designer</h3>
                      <a routerLink="/company-details">Winbrans.com</a>
                      <ul>
                        <li><i class="icofont-money-bag"></i> $20k - $25k</li>
                        <li><i class="icofont-location-pin"></i> Location 210-27 Quadra, Market Street, Victoria Canada
                        </li>
                      </ul>
                    </div>

                    <div class="job-inner-right">
                      <ul>
                        <li><a routerLink="/create-account">Apply</a></li>
                        <li><span>Full Time</span></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="job-item wow fadeInUp" data-wow-delay=".4s">
                  <img src="assets/img/home-1/jobs/2.png" alt="Job">

                  <div class="job-inner align-items-center">
                    <div class="job-inner-left">
                      <h3>Android Developer</h3>
                      <a routerLink="/company-details">Infiniza.com</a>
                      <ul>
                        <li><i class="icofont-money-bag"></i> $20k - $25k</li>
                        <li><i class="icofont-location-pin"></i> Location 210-27 Quadra, Market Street, Victoria Canada
                        </li>
                      </ul>
                    </div>

                    <div class="job-inner-right">
                      <ul>
                        <li><a routerLink="/create-account">Apply</a></li>
                        <li><span>Part Time</span></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="job-item wow fadeInUp" data-wow-delay=".3s">
                  <img src="assets/img/home-1/jobs/3.png" alt="Job">

                  <div class="job-inner align-items-center">
                    <div class="job-inner-left">
                      <h3>Senior Manager</h3>
                      <a routerLink="/company-details">Glovibo.com</a>
                      <ul>
                        <li><i class="icofont-money-bag"></i> $20k - $25k</li>
                        <li><i class="icofont-location-pin"></i> Location 210-27 Quadra, Market Street, Victoria Canada
                        </li>
                      </ul>
                    </div>

                    <div class="job-inner-right">
                      <ul>
                        <li><a routerLink="/create-account">Apply</a></li>
                        <li><span>Intern</span></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="job-item wow fadeInUp" data-wow-delay=".4s">
                  <img src="assets/img/home-1/jobs/4.png" alt="Job">

                  <div class="job-inner align-items-center">
                    <div class="job-inner-left">
                      <h3>Product Designer</h3>
                      <a routerLink="/company-details">Bizotic.com</a>
                      <ul>
                        <li><i class="icofont-money-bag"></i> $20k - $25k</li>
                        <li><i class="icofont-location-pin"></i> Location 210-27 Quadra, Market Street, Victoria Canada
                        </li>
                      </ul>
                    </div>

                    <div class="job-inner-right">
                      <ul>
                        <li><a routerLink="/create-account">Apply</a></li>
                        <li><span>Part Time</span></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div-->

        <!-- <div class="tab-pane fade" id="pills-logout" role="tabpanel" aria-labelledby="pills-logout-tab">
          <div class="login-area dashboard-logout-area">
            <div class="login-wrap">
              <div class="row">
                <div class="col-sm-6 col-lg-6">
                  <div class="jobseeker-item">
                    <div class="jobseeker-icon">
                      <i class="flaticon-job-search"></i>
                    </div>

                    <div class="jobseeker-inner">
                      <span>Candidate</span>
                      <h3>Login as a Candidate</h3>
                    </div>

                    <a routerLink="/login">Get Started
                      <i class="icofont-arrow-right"></i>
                    </a>
                  </div>
                </div>

                <div class="col-sm-6 col-lg-6">
                  <div class="jobseeker-item">
                    <div class="jobseeker-icon">
                      <i class="flaticon-recruitment"></i>
                    </div>

                  <div class="jobseeker-inner">
                    <span>Employer</span>
                    <h3>Login as a Employer</h3>
                  </div>

                  <a routerLink="/login">Get Started
                    <i class="icofont-arrow-right"></i>
                  </a>
                  </div>
                </div>
              </div>
            </div>

            <form>
              <div class="form-group">
                <input type="text" class="form-control" placeholder="Username, Phone Number or Email">
              </div>

              <div class="form-group">
                <input type="password" class="form-control" placeholder="Password">
              </div>
            </form>

            <div class="login-sign-in">
              <a href="#">Forgot Password?</a>
              <ul>
                <li>Don’t Have Account ?</li>
                <li><a routerLink="/create-account">Sign Up Here</a></li>
              </ul>
              <div class="text-center">
                <button type="submit" class="btn login-btn">Sign In</button>
              </div>
            </div>

            <div class="login-social">
              <a href="#" target="_blank"><i class="icofont-facebook"></i> Login With Facebook</a>
              <a class="login-google" href="#" target="_blank"><i class="icofont-google-plus"></i> Login With Google</a>
            </div>
          </div>
        </div> -->
      </div>
    </form>
  </div>
</div>
