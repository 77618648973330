import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { JobService } from '../../services/job.service';
import { AuthenticationService } from '../../services/auth.service';
import { User } from '../../models/user.model';
import { first } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Job } from 'src/app/models/job.model';
import { SelectLabelsService } from '../../services/selectLabels.service';
import { UtilsService } from '../../services/utils.service';

declare const $;

@Component({
  selector: 'app-post-a-job',
  templateUrl: './post-a-job.component.html',
  styleUrls: ['./post-a-job.component.scss']
})
export class PostAJobComponent implements OnInit {
  postAJobForm: FormGroup;
  submitted = false;
  job: Job;
  jobs: Job[] = [];
  user: User;
  isUser: boolean;
  dateToCheck: Date;
  selectLocation: string[] = [];
  selectJobType = [];
  selectCategory = [];
  selectPlaceOfWork = [];
  isOthers: boolean;
  minDate: string;
  maxDate: string;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private jobService: JobService,
    private authenticationService: AuthenticationService,
    private selectLabelsService: SelectLabelsService,
    private utilsService: UtilsService
  ) { }

  ngOnInit(): void {
    const salaryReg = new RegExp(/^[0-9]\d*$/);
    this.minDate = this.utilsService.getMinDate();
    this.maxDate = this.utilsService.getMaxDate();
    this.selectCategory = this.selectLabelsService.getJobCategory();
    this.getLocations();
    this.selectJobType = this.selectLabelsService.getJobType();
    this.selectPlaceOfWork = this.selectLabelsService.getJobPlaceOfWork();
    this.authenticationService.isLoggedIn().subscribe(isUser => {
      if (isUser) {
        this.isUser = true;
        this.user = this.authenticationService.currentUser()['user'];
        if (this.user) {
          this.postAJobForm = this.formBuilder.group({
            title: ['', [Validators.required]],
            category: ['', [Validators.required]],
            companyName: [this.user.company_name, [Validators.required]],
            email: [this.user.email],
            address: [this.user.address],
            phoneNumber: [this.user.telephone],
            location: ['', [Validators.required]],
            others: ['', [Validators.required]],
            jobType: ['', [Validators.required]],
            placeOfWork: ['', [Validators.required]],
            salary: ['', [Validators.pattern(salaryReg)]],
            expiry_at: ['', [Validators.required]],
            description: ['', [Validators.required, Validators.minLength(100)]],
            skills: this.formBuilder.array([this.newElement('skill')]),
            qualifications: this.formBuilder.array([this.newElement('qualification')])
          });
          this.initialiseFilters();
        }
      }
    })
  }

  initialiseFilters() {
    this.onHandleSelect($('.location').val(), 'location');
    this.onHandleSelect($('.category').val(), 'category');
    this.onHandleSelect($('.jobType').val(), 'jobType');
    this.onHandleSelect($('.placeOfWork').val(), 'placeOfWork');
  }

  getLocations() {
    this.jobService.getJobs().subscribe(response => {
      this.selectLocation = [...new Set(response['jobs'].map(job => job.location))] as string[];
      this.selectLocation.push('Others');
    })
  };

  onHandleSelect(data: any, controlName: string) {
    if (data && data.value && data.elements.contains(controlName)) {
      this.f[controlName]?.setValue(data.value);
    } else if (!data) {
      this.f[controlName]?.setValue(null);
    }
    this.isOthers = this.f.location?.value === 'Others';
    if (controlName === 'location' && !this.isOthers && this.f[controlName]?.value) {
      this.postAJobForm.removeControl('others');
    } else {
      this.postAJobForm.addControl('others', this.formBuilder.control('', [Validators.required]));
    }
  }

  newElement(item?: string): FormGroup {
    return this.formBuilder.group({
      [item]: ['', [Validators.required]]
    })
  }

  removeElement(index: number, element: string) {
    this[element].removeAt(index);
  }

  addElement(e, element: string) {
    e.preventDefault();
    this[element].push(this.newElement(element.slice(0, -1)));
  }

  get skills(): FormArray {
    return this.postAJobForm.get('skills') as FormArray;
  }

  get qualifications(): FormArray {
    return this.postAJobForm.get('qualifications') as FormArray;
  }

  get f() {
    return this.postAJobForm.controls;
  }

  onSubmit() {
    const formData = Object.assign({}, this.postAJobForm.value);
    this.submitted = true;
    if (this.postAJobForm.invalid) {
      return;
    }
    if (this.isOthers) {
      formData.location = this.f.others.value;
    }
    delete formData.others;
    this.jobService.postJob(formData)
      .pipe(first()).subscribe(response => {
        Swal.fire({
          title: 'Job submitted',
          text: 'It will be online after the review process',
          icon: 'success',
          confirmButtonColor: '#38a745',
        }).then(() => {
          this.router.navigate(['job-details'], {
            state: { data: JSON.stringify(response['job']) }
          });
        });
      },
        error => {
          Swal.fire({
            title: 'An error occurred',
            html: 'Please contact us at: <b>info@meshincentre.com</b> or via the contact form',
            icon: 'error',
            confirmButtonColor: '#f37b7b',
          }).then(() =>
            this.router.navigateByUrl('contact'));
        })
  }
}
