import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../services/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss'],
  // encapsulation: ViewEncapsulation.None
})

export class EmailVerificationComponent implements OnInit {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService
    ) {}

  ngOnInit() {
    this.authenticationService.verifyEmail(this.BuildQueryURL({
       id: this.activatedRoute.snapshot.params['id'],
       token: this.activatedRoute.snapshot.params['token'],
       signature: this.activatedRoute.snapshot.queryParams['signature']}))
       .subscribe(response => {
         this.router.navigateByUrl('authenticate/login');
       },
       error => {
         Swal.fire({
           title: 'Email verification failed!',
           html: 'Please contact us at: <b>info@meshincentre.com</b>',
           icon: 'error',
           confirmButtonColor: '#f37b7b',
         }).then(() =>
          this.router.navigateByUrl('authenticate/create-account'));
       });
  }

  /**
   * Builds the query string
   *
   * @param data
   * @return {string}
   */
  BuildQueryURL(data: any): string {
    return 'email/verify/' + data.id + '/' + data.token + '?signature=' + data.signature;
  }
}
