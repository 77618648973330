import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JobService } from '../../services/job.service';
import { SendMailService } from '../../services/send.mail.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  newsletterForm: FormGroup;
  copyYear: number = new Date().getFullYear();
  submitted = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private jobService: JobService,
    private sendMailService: SendMailService
  ) {}

  ngOnInit(): void {
    this.newsletterForm = this.formBuilder.group({
      email: ['', [
        Validators.required,
        Validators.email,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]]
    });
  }

  handleCategory(category: string) {
    this.jobService.getJobs().pipe(first())
      .subscribe(response => {
        const jobs = response['jobs'].filter(job => job.category === category);
        this.router.navigate(['job-list'], {
          state: {data: jobs}
        });
      });
  }

  get f() {
    return this.newsletterForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.newsletterForm.invalid) {
      return;
    }
    this.sendMailService.sendNewsletterRegistration(this.newsletterForm.value)
      .pipe(first()).subscribe(response => {
      if (response['message'] === 'success') {
        Swal.fire({
          title: 'Email Registered!',
          text: 'Thank you for subscribing',
          icon: 'success',
          confirmButtonColor: '#38a745',
        }).then(() => {
          this.newsletterForm.reset('');
          this.submitted = false;
        });
      }
    },error => {
      let errorMessage =
        (error && error.length > 0 && error.indexOf('The email has already been taken.') > -1) ?
      error[0] : 'Please try again later';
      Swal.fire({
        title: 'An error occurred',
        html: errorMessage,
        icon: 'error',
        confirmButtonColor: '#f37b7b',
      }).then(() => {
        this.newsletterForm.reset('');
        this.submitted = false;
      });
    })
  }
}
