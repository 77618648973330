import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/auth.service';
import { SendMailService } from '../../services/send.mail.service';
import { UserProfileService } from '../../services/user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CountryISO } from 'ngx-intl-tel-input';
import { SearchCountryField } from 'ngx-intl-tel-input';
import { PhoneNumberFormat } from 'ngx-intl-tel-input';
import { User } from '../../models/user.model';
import { PhonePipe } from '../../pipes/phone.pipe';
import {first} from "rxjs/operators";
import Swal from "sweetalert2";

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contactForm: FormGroup;
  submitted = false;
  user: User;
  isUser: boolean;
  subject: string;
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.Uganda
  ];

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private userProfileService: UserProfileService,
    private sendMailService: SendMailService,
    private phonePipe: PhonePipe,
  ) {
  }

  ngOnInit(): void {
    this.subject  = history.state.data;
    this.contactForm = this.formBuilder.group({
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      email: ['', [
        Validators.required,
        Validators.email,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      telephone: [this.phonePipe.transform('0772123456'), Validators.required],
      subject: ['', [Validators.required]],
      message: ['', [Validators.required]]
    });
    this.authenticationService.isLoggedIn().subscribe(isUser => {
      this.user = isUser ? this.authenticationService.currentUser()['user'] : null;
      this.f.firstname.setValue(isUser ? this.user.firstname : '');
      this.f.lastname.setValue(isUser ? this.user.lastname : '');
      this.f.email.setValue(isUser ? this.user.email : '');
      this.f.telephone.setValue(isUser ? this.phonePipe.transform(this.user.telephone) : '');
      this.f.subject.setValue(this.subject || '');
      this.f.message.setValue(this.subject ?
        `Could you please send me more information about the ${this.subject} price` : '');
    });
  }

  get f() {
    return this.contactForm.controls;
  }

  onSubmit() {
    const formData: any = Object.assign({}, this.contactForm.value);
    this.submitted = true;
    if (this.contactForm.invalid) {
      return;
    }
    formData.telephone = this.f.telephone.value.e164Number;
    this.sendMailService.sendInquiry(formData)
      .pipe(first()).subscribe(response => {
        if (response['message'] === 'success') {
          Swal.fire({
            title: 'Message sent!',
            text: 'We shall get back to you shortly',
            icon: 'success',
            confirmButtonColor: '#38a745',
          }).then(() => {
            this.router.navigate(['home']);
          });
        }
    },error => {
      Swal.fire({
        title: 'An error occurred',
        html: 'Please try again later',
        icon: 'error',
        confirmButtonColor: '#f37b7b',
      }).then(() => {
        this.router.navigate(['home']);
      });
    });
  }
}
