<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="page-title-text">
          <h2>Edit Job</h2>
          <ul>
            <li><a routerLink="/">Home</a></li>
            <li><i class="icofont-simple-right"></i></li>
            <li>Edit Job</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="dashboard-area pt-5">
  <div class="container">
    <!-- <div *ngIf="!user?.is_active" class="alert alert-warning" role="alert">
      <i class="icofont-warning-alt"></i>
      Your profile is not yet visible to <b>employers</b>, because it is still under review.
    </div> -->
    <form [formGroup]="editJobForm" (ngSubmit)="onSubmit()">
      <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
          <div class="create-information">
            <h3>Edit</h3>
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group">
                  <label>Job Title</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="title"
                  >
                  <div *ngIf="submitted && f.title.errors" class="is-invalid">
                    <div *ngIf="f.title.errors.required">Title required</div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6" *ngIf="!(isAdmin | async)">
                <div class="form-group">
                  <label>Job Status</label>
                  <input
                    type="text"
                    class="form-control"
                    [attr.disabled]="true"
                    formControlName="status"
                  >
                </div>
              </div>

              <div class="col-lg-6 select-container" *ngIf="(isAdmin | async)">
                <div class="form-group">
                  <label>Job Status</label>
                  <app-select
                    [defaultText]="'--- Select status---'"
                    [selectTexts]="selectJobStatus"
                    [selectClass]="'status'"
                    [invalidInput]="submitted && f.status.invalid"
                    formControlName="status"
                    [formGroup]="editJobForm"
                    (valueChangedEvent)="onHandleSelect($event, 'status')"
                  >
                  </app-select>
                  <div *ngIf="submitted && f.status?.errors?.required" class="is-invalid">
                    Status required
                  </div>
                </div>
              </div>

              <div class="col-lg-6 select-container" *ngIf="(isAdmin | async)">
                <div class="form-group">
                  <label>Feature Job</label>
                  <app-select
                    [defaultText]="'--- Select feature---'"
                    [selectTexts]="selectFeature"
                    [selectClass]="'feature'"
                    [invalidInput]="submitted && f.feature.invalid"
                    formControlName="feature"
                    [formGroup]="editJobForm"
                    (valueChangedEvent)="onHandleSelect($event, 'feature')"
                  >
                  </app-select>
                  <div *ngIf="submitted && f.feature?.errors?.required" class="is-invalid">
                    Feature status required
                  </div>
                </div>
              </div>

              <div class="col-lg-6 select-container">
                <div class="form-group">
                  <label>Category</label>
                  <app-select
                    [defaultText]="'--- Select category ---'"
                    [selectTexts]="selectCategory"
                    [selectClass]="'category'"
                    [invalidInput]="submitted && f.category.invalid"
                    formControlName="category"
                    [formGroup]="editJobForm"
                    (valueChangedEvent)="onHandleSelect($event, 'category')"
                  >
                  </app-select>
                  <div *ngIf="submitted && f.category?.errors?.required" class="is-invalid">
                    Category required
                  </div>
                </div>
              </div>

              <div class="col-lg-6 select-container">
                <div class="form-group">
                  <label>Location</label>
                  <app-select
                    *ngIf="selectLocation.length > 0 && !isOthers"
                    [defaultText]="'--- Select Location ---'"
                    [selectTexts]="selectLocation"
                    [selectClass]="'location'"
                    [invalidInput]="submitted && f.location?.invalid"
                    formControlName="location"
                    [formGroup]="editJobForm"
                    (valueChangedEvent)="onHandleSelect($event, 'location')"
                  >
                  </app-select>
                  <div *ngIf="submitted && f.location?.errors?.required" class="is-invalid">
                    Location required
                  </div>

                  <input
                    *ngIf="isOthers"
                    type="text"
                    class="form-control"
                    formControlName="others"
                    placeholder="Kampala"
                  >
                  <div *ngIf="isOthers && submitted && f.others?.errors" class="is-invalid">
                    Job Location required
                  </div>
                </div>
              </div>

              <div class="col-lg-6 select-container">
                <div class="form-group">
                  <label>Job Type</label>
                  <app-select
                    [defaultText]="'--- Select Job Type ---'"
                    [selectTexts]="selectJobType"
                    [selectClass]="'jobType'"
                    [invalidInput]="submitted && f.jobType.invalid"
                    formControlName="jobType"
                    [formGroup]="editJobForm"
                    (valueChangedEvent)="onHandleSelect($event, 'jobType')"
                  >
                  </app-select>
                  <div *ngIf="submitted && f.jobType?.errors?.required" class="is-invalid">
                    Job Type required
                  </div>
                </div>
              </div>

              <div class="col-lg-6 select-container">
                <div class="form-group">
                  <label>Place of work</label>
                  <app-select
                    [defaultText]="'--- Select place of work ---'"
                    [selectTexts]="selectPlaceOfWork"
                    [selectClass]="'placeOfWork'"
                    [invalidInput]="submitted && f.placeOfWork.invalid"
                    formControlName="placeOfWork"
                    [formGroup]="editJobForm"
                    (valueChangedEvent)="onHandleSelect($event, 'placeOfWork')"
                  >
                  </app-select>
                  <div *ngIf="submitted && f.placeOfWork?.errors?.required" class="is-invalid">
                    Place Of Work required
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="form-group">
                  <label>Salary: </label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="salary"
                    placeholder="1000000"
                  >
                  <div *ngIf="submitted && f.salary?.errors?.pattern" class="is-invalid">
                    Only numbers required
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="form-group">
                  <label>Job deadline </label>
                  <input
                    type="date"
                    class="form-control"
                    [min]="minDate"
                    [max]="maxDate"
                    formControlName="expiry_at"
                  >
                  <div *ngIf="submitted && f.expiry_at.errors" class="is-invalid">
                    Job Deadline required
                  </div>
                </div>
              </div>

              <div class="col-lg-12">
                <div class="form-group">
                  <label>Description</label>
                  <textarea
                    id="your_message"
                    class="form-control"
                    rows="8"
                    formControlName="description"
                  >
                    </textarea>
                  <div *ngIf="submitted && f.description.errors" class="is-invalid">
                    <div *ngIf="f.description.errors.required">About Me required</div>
                    <div *ngIf="f.description.errors.minlength">Must be at least 100 characters</div>
                  </div>
                </div>
              </div>

              <div class="col-lg-12">
                <div class="form-group is-block">
                  <label>Job Skills: *</label>
                  <div class="skill-qualification-container" formArrayName="skills"
                    *ngFor="let skill of skills.controls; let i = index">
                    <div class="skill-qualification" [formGroupName]="i">
                      <input
                        class="form-control"
                        type="text" placeholder="{{'Enter skill ' + (i + 1)}}"
                        formControlName="skill"
                      >
                      <button
                        *ngIf="i > 0"
                        class="white-to-green-simulated-hover-btn remove-btn"
                        (click)="removeElement(i, 'skills')"
                      >
                        Remove
                      </button>
                    </div>
                    <div *ngIf="submitted && f.skills.status === 'INVALID'" class="is-invalid">
                      {{'Skill required ' + (i + 1)}}
                    </div>
                  </div>
                  <button
                    class="green-white-simulated-hover-btn add-btn"
                    (click)="addElement($event, 'skills')"
                  >
                    Add Item
                  </button>
                </div>
              </div>

              <div class="col-lg-12">
                <div class="form-group is-block">
                  <label>Job Qualifications: *</label>
                  <div class="skill-qualification-container" formArrayName="qualifications"
                    *ngFor="let qualification of qualifications.controls; let i = index">
                    <div class="skill-qualification" [formGroupName]="i">
                      <input
                        class="form-control"
                        type="text"
                        placeholder="{{'Enter Qualification ' + (i + 1)}}"
                        formControlName="qualification"
                      >
                      <button
                        *ngIf="i > 0"
                        class="white-to-green-simulated-hover-btn remove-btn"
                        (click)="removeElement(i, 'qualifications')"
                      >
                        Remove
                      </button>
                    </div>
                    <div *ngIf="submitted && f.qualifications.status === 'INVALID'" class="is-invalid">
                      {{'Qualification required ' + (i + 1)}}
                    </div>
                  </div>
                  <button
                    class="green-white-simulated-hover-btn add-btn"
                    (click)="addElement($event, 'qualifications')"
                  >
                    Add Item
                  </button>
                </div>
              </div>

            </div>
          </div>
          <div *ngIf="submitted && editJobForm.invalid" class="alert alert-danger" role="alert">
            <i class="icofont-warning-alt"></i>
            The form contains one or more invalid input.
          </div>
          <div class="text-left">
            <button type="submit" class=" btn green-gradient-hover"> Save </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
