import {
  Component,
  Input,
  Output,
  ContentChild,
  TemplateRef,
  OnInit,
  EventEmitter,
  ViewChild,
  OnDestroy
} from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})

export class TableComponent implements OnInit, OnDestroy {
  @Input() tableTitle: string;
  @Input() tableColumnTitles: string[];
  @Input() tableData: any;
  @Input() filterColumn: number;
  @Input() rowColourClass: string;
  @Output() filteredData = new EventEmitter<any>();
  @ContentChild('table', {static: false}) tableTemplateRef: TemplateRef<any>;
  @ViewChild(DataTableDirective, {static: false}) datatableElement: DataTableDirective;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject();
  isTableReady: boolean;

  constructor() {}

  ngOnInit() {
    this.dtOptions = {
      responsive: true
    };
    setTimeout(() => {
      this.loadTransactionData();
      this.isTableReady = true;
    }, 1000);
  }

  loadTransactionData() {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
}
