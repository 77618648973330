<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="page-title-text">
          <h2>Post A Job</h2>
          <ul>
            <li><a routerLink="/">Home</a></li>
            <li><i class="icofont-simple-right"></i></li>
            <li>Post A Job</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="post-job-area pt-5">
  <div class="container">
    <div class="post-job-item">
      <form [formGroup]="postAJobForm" (ngSubmit)="onSubmit()">
        <div class="post-job-heading">
          <h2>Post Your Job</h2>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label>Job Title: *</label>
                <input
									type="text"
									class="form-control"
									formControlName="title"
									placeholder="UX/UI Designer"
								>
								<div *ngIf="submitted && f.title.errors" class="is-invalid">
									<div *ngIf="f.title.errors.required">Job Title required</div>
								</div>

            </div>
          </div>

          <div class="col-lg-6 select-col">
            <div class="form-group">
              <label>Job Category: *</label>
              <div class="job-category-area">
                <app-select
                  [defaultText]="'--- Select Category --- *'"
                  [selectTexts]="selectCategory"
                  [invalidInput]="submitted && f.category.invalid"
                  [selectClass]="'category'"
                  [formGroup]="postAJobForm"
                  formControlName="category"
                  (valueChangedEvent)="onHandleSelect($event, 'category')"
                ></app-select>
								<div *ngIf="submitted && f.category.errors" class="is-invalid">
									<div *ngIf="f.category.errors.required">Job Category required</div>
								</div>

              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label>Company Name:</label>
              <input
								type="text"
								class="form-control"
								placeholder="Winbrans.com"
                [attr.disabled]="true"
								formControlName="companyName"
							>
							<div *ngIf="submitted && f.companyName.errors" class="is-invalid">
								<div *ngIf="f.companyName.errors.required">Company name required</div>
							</div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <div class="job-currency-area">
                <label>Email:</label>
                <input
									type="email"
									class="form-control"
									placeholder="email@email.com"
                  [attr.disabled]="true"
									formControlName="email"
								>
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label>Your phone:</label>
              <input
								type="text"
								class="form-control"
								placeholder="256789123456"
                [attr.disabled]="true"
								formControlName="phoneNumber"
							>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label>Address:</label>
                <input
									type="text"
									class="form-control"
									placeholder="Bugolobi, Kisakye Complex"
                  [attr.disabled]="true"
									formControlName="address"
								>
            </div>
          </div>

          <!--div class="col-lg-6">
            <div class="form-group">
              <div class="job-type-area">
                <span>Job Type: *</span>

                <div class="form-check form-check-inline">
                  <input
										type="radio"
										name="jobType"
										id="inlineRadio1"
										value="Full time"
										formControlName="jobType"
										checked
									>
                  <label class="form-check-label" for="inlineRadio1">Full Time</label>
                </div>

                <div class="form-check form-check-inline">
                  <input
										type="radio"
										name="jobType"
										id="inlineRadio2"
										value="Part time"
										formControlName="jobType"
									>
                  <label class="" for="inlineRadio2">Part time</label>
                </div>

                <div class="form-check form-check-inline">
                  <input
										type="radio"
										name="jobType"
										id="inlineRadio3"
										value="Internship"
										formControlName="jobType"
									>
                  <label class="form-check-label" for="inlineRadio3">Internship</label>
                </div>
              </div>
            </div>
          </div-->

          <div class="col-lg-6 location">
            <div class="form-group">
              <label>Location: *</label>
              <div class="job-category-area">
                <app-select
                  *ngIf="selectLocation.length > 0 && !isOthers"
                  class=""
                  [defaultText]="'--- Select Location --- *'"
                  [selectTexts]="selectLocation"
                  [invalidInput]="submitted && f.location?.invalid"
                  [selectClass]="'location'"
                  [formGroup]="postAJobForm"
                  formControlName="location"
                  (valueChangedEvent)="onHandleSelect($event, 'location')"
                ></app-select>
                <div *ngIf="!isOthers && submitted && f.location?.errors" class="is-invalid">
                  <div *ngIf="!isOthers && f.location?.errors.required">Job Location required</div>
                </div>

                <input
                  *ngIf="isOthers"
                  type="text"
                  class="form-control"
                  formControlName="others"
                  placeholder="Kampala"
                >
                <div *ngIf="isOthers && submitted && f.others?.errors" class="is-invalid">
                  Job Location required
                </div>

              </div>
            </div>
          </div>

          <div class="col-lg-6 select-col">
            <div class="form-group">
              <label>Job Type: *</label>
              <div class="job-category-area">
                <app-select
                  class=""
                  [defaultText]="'--- Select Job Type --- *'"
                  [selectTexts]="selectJobType"
                  [invalidInput]="submitted && f.jobType.invalid"
                  [selectClass]="'jobType'"
                  [formGroup]="postAJobForm"
                  formControlName="jobType"
                  (valueChangedEvent)="onHandleSelect($event, 'jobType')"
                ></app-select>
                <div *ngIf="submitted && f.jobType.errors" class="is-invalid">
                  <div *ngIf="f.jobType.errors.required">Job Type required</div>
                </div>

              </div>
            </div>
          </div>

          <div class="col-lg-6 select-col">
            <div class="form-group">
              <label>Place of Work: *</label>
              <div class="job-category-area">
                <app-select
                  class=""
                  [defaultText]="'--- Select Place of Work --- *'"
                  [selectTexts]="selectPlaceOfWork"
                  [invalidInput]="submitted && f.placeOfWork.invalid"
                  [selectClass]="'placeOfWork'"
                  [formGroup]="postAJobForm"
                  formControlName="placeOfWork"
                  (valueChangedEvent)="onHandleSelect($event, 'placeOfWork')"
                ></app-select>
                <div *ngIf="submitted && f.placeOfWork.errors" class="is-invalid">
                  <div *ngIf="f.placeOfWork.errors.required">Place of Work required</div>
                </div>

              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label>Salary: </label>
              <input
                type="text"
                class="form-control"
                formControlName="salary"
                placeholder="1000000"
              >
              <div *ngIf="submitted && f.salary.errors?.pattern" class="is-invalid">
                Only numbers required
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label>Job deadline </label>
              <input
                type="date"
                class="form-control"
                [min]="minDate"
                [max]="maxDate"
                formControlName="expiry_at"
              >
              <div *ngIf="submitted && f.expiry_at.errors" class="is-invalid">
                Job Deadline required
              </div>
            </div>
          </div>


          <div class="col-lg-12">
            <div class="form-group">
              <label>Job Description: *</label>
              <textarea
								id="your_message"
								rows="8"
								class="form-control"
								formControlName="description"
							></textarea>
							<div *ngIf="submitted && f.description.errors" class="is-invalid">
								<div *ngIf="f.description.errors.required">Job Description required</div>
                <div *ngIf="f.description.errors.minlength">Must be at least 100 characters</div>
							</div>
            </div>
          </div>

          <div class="col-lg-12">
            <div class="form-group is-block">
              <label>Job Skills: *</label>
              <div
                class="skill-qualification-container"
                formArrayName="skills"
                *ngFor="let skill of skills.controls; let i = index"
              >
                <div class="skill-qualification" [formGroupName]="i">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="{{'Enter skill ' + (i + 1)}}"
                    formControlName="skill"
                  >
                  <button *ngIf="i > 0" class="white-to-green-simulated-hover-btn remove-btn" (click)="removeElement(i, 'skills')">Remove</button>
                </div>
                <div *ngIf="submitted && f.skills.status === 'INVALID'" class="is-invalid">
                  {{'Skill required ' + (i + 1)}}
                </div>
              </div>
              <button class="green-white-simulated-hover-btn add-btn" (click)="addElement($event, 'skills')">Add Item</button>
            </div>
          </div>

          <div class="col-lg-12">
            <div class="form-group is-block">
              <label>Job Qualifications: *</label>
              <div
                class="skill-qualification-container"
                formArrayName="qualifications"
                *ngFor="let qualification of qualifications.controls; let i = index"
              >
                <div class="skill-qualification" [formGroupName]="i">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="{{'Enter Qualification ' + (i + 1)}}"
                    formControlName="qualification"
                  >
                  <button *ngIf="i > 0" class="white-to-green-simulated-hover-btn remove-btn" (click)="removeElement(i, 'qualifications')">Remove</button>
                </div>
                <div *ngIf="submitted && f.qualifications.status === 'INVALID'" class="is-invalid">
                  {{'Qualification required ' + (i + 1)}}
                </div>
              </div>
              <button class="green-white-simulated-hover-btn add-btn" (click)="addElement($event, 'qualifications')">Add Item</button>
            </div>
          </div>
        </div>
        <div *ngIf="submitted && postAJobForm.invalid" class="alert alert-danger" role="alert">
          <i class="icofont-warning-alt"></i>
          The form contains one or more invalid input.
        </div>
      	<div class="text-left">
        	<button type="submit" class="btn green-gradient-hover">Post</button>
      	</div>
    	</form>
  	</div>
	</div>
</div>
