<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>Blog</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>Blog</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="blog-area pt-5 pb-170">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4" *ngFor="let blogPost of blogPosts; let i = index;">
                <div class="blog-item wow fadeInUp" data-wow-delay=".3s">
                    <div class="blog-top">
                        <a (click)="goToBlogDetails(blogPost.id)">
                          <img
                            *ngIf="blogPost?._embedded['wp:featuredmedia']"
                            src="{{blogPost._embedded['wp:featuredmedia'][0].source_url}}"
                            alt="Blog image"
                          >
                        </a>
                        <span>{{blogPost.date | date: 'longDate'}}</span>
                    </div>

                    <div class="blog-bottom">
                        <h3>
                          <a (click)="goToBlogDetails(blogPost.id)">
                            {{blogPost.title.rendered}}
                          </a>
                        </h3>
                        <ul>
                          <li><img src="assets/img/blog-logo.png" alt="author image"> Skillcrawler</li>
                          <li>
                            <a (click)="goToBlogDetails(blogPost.id)">
                              Read More
                              <i class="icofont-simple-right"></i>
                            </a>
                          </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
