import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray
} from '@angular/forms';
import { JobService } from '../../services/job.service';
import { AuthenticationService } from '../../services/auth.service';
import { Job } from '../../models/job.model';
import { first } from "rxjs/operators";
import Swal from 'sweetalert2';
import { Observable } from 'rxjs/index';
import { SelectLabelsService } from '../../services/selectLabels.service';
import { UtilsService } from '../../services/utils.service';

declare const $;

@Component({
  selector: 'app-edit-job',
  templateUrl: './edit-job.component.html',
  styleUrls: ['./edit-job.component.scss']
})
export class EditJobComponent implements OnInit, AfterViewInit {
  editJobForm: FormGroup;
  submitted = false;
  cvImage: string;
  uploadedCV: File;
  job: Job;
  isAdmin: Observable<boolean>;
  selectCategory = [];
  selectPlaceOfWork = [];
  selectLocation: string[] = [];
  selectJobType: string[] = [];
  selectJobStatus: string[] = [];
  selectFeature: string[] = [];
  isOthers: boolean;
  minDate: string;
  maxDate: string;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private jobService: JobService,
    private authenticationService: AuthenticationService,
    private selectLabelsService: SelectLabelsService,
    private utilsService: UtilsService
  ) { }

  ngOnInit(): void {
    const salaryReg = new RegExp(/^[0-9]\d*$/);
    const jobsPosted = history.state.data;
    if (jobsPosted) {
      this.job = jobsPosted;
    } else {
      this.router.navigate(['employer-dashboard'])
    }
    this.minDate = this.utilsService.getMinDate();
    this.maxDate = this.utilsService.getMaxDate();
    this.selectCategory = this.selectLabelsService.getJobCategory();
    this.selectPlaceOfWork = this.selectLabelsService.getJobPlaceOfWork();
    this.getLocations();
    this.selectJobType = this.selectLabelsService.getJobType();
    this.selectJobStatus = this.selectLabelsService.getJobStatus();
    this.selectFeature = this.selectLabelsService.getJobFeature();
    this.isAdmin = this.authenticationService.isAdminLoggedIn();
    this.editJobForm = this.formBuilder.group({
      feature: [this.job?.is_featured ? 'Featured' : 'Not featured', [Validators.required]],
      title: [this.job?.title, [Validators.required]],
      status: [this.job?.status, [Validators.required]],
      category: [this.job?.category, [Validators.required]],
      location: [this.job?.location, [Validators.required]],
      jobType: [this.job?.type, Validators.required],
      placeOfWork: [this.job?.place_of_work, [Validators.required]],
      salary: [this.job?.salary, [Validators.pattern(salaryReg)]],
      other: [this.job?.location, [Validators.required]],
      expiry_at: [this.job?.expiry_at, [Validators.required]],
      description: [this.job?.description, [Validators.required, Validators.minLength(100)]],
      qualifications: this.formBuilder.array([this.newElement('qualification')]),
      skills: this.formBuilder.array([this.newElement('skill')]),
    });
    this.job?.skills.forEach((skill, i) => {
      if (i > 0) {
        this.addElement(null, 'skills');
      }
      this.skills.controls[i].setValue({
        skill: skill['skill']
      })
    });
    this.job?.qualifications.forEach((qualification, i) => {
      if (i > 0) {
        this.addElement(null, 'qualifications');
      }
      this.qualifications.controls[i].setValue({
        qualification: qualification['qualification']
      })
    });
  }

  ngAfterViewInit() {
    $('select.location').val(this.f.location.value).niceSelect('update');
    $('select.jobType').val(this.f.jobType.value).niceSelect('update');
    $('select.status').val(this.f.status.value).niceSelect('update');
    $('select.placeOfWork').val(this.f.placeOfWork.value).niceSelect('update');
    $('select.category').val(this.f.category.value).niceSelect('update');
    $('select.feature').val(this.f.feature.value).niceSelect('update');
    $('select').next().addClass('default-select-option');
  }

  onHandleSelect(data: any, controlName: string) {
    if (data && data.value && data.elements.contains(controlName)) {
      this.f[controlName].setValue(data.value);
    } else if (!data) {
      this.f[controlName].setValue(null);
    }
    this.isOthers = this.f.location?.value === 'Others';

    if (controlName === 'location' && !this.isOthers && this.f[controlName]?.value) {
      this.editJobForm.removeControl('others');
    } else {
      this.editJobForm.addControl('others', this.formBuilder.control('', [Validators.required]));
    }
  }

  get f() {
    return this.editJobForm.controls;
  }

  deadlineValidator() {
    const today = new Date();
    const dateToCheck = new Date(this.f.expiry_at.value);
    if (dateToCheck < today) {
      this.f.expiry_at.setErrors({ 'Date must be greater than today': true });
    }
  }

  getLocations() {
    this.jobService.getJobs().subscribe(response => {
      this.selectLocation = [...new Set(response['jobs'].map(job => job.location))] as string[];
      this.selectLocation.push('Others');
    })
  };

  newElement(element: string): FormGroup {
    return this.formBuilder.group({
      [element]: ['', [Validators.required]]
    })
  }

  addElement(e, element: string) {
    if (e) {
      e.preventDefault();
    }
    this[element].push(this.newElement(element.slice(0, -1)));
  }

  removeElement(index: number, element: string) {
    this[element].removeAt(index);
  }

  get skills(): FormArray {
    return this.editJobForm.get('skills') as FormArray;
  }

  get qualifications(): FormArray {
    return this.editJobForm.get('qualifications') as FormArray;
  }

  onSubmit() {
    const formData: Job = Object.assign({}, this.editJobForm.value);
    this.submitted = true;
    if (this.editJobForm.invalid) {
      return;
    }
    formData.is_featured = this.f.feature.value === 'Featured' ? 1 : 0;
    delete formData['feature'];
    this.jobService.editJob(this.job.id, formData)
      .pipe(first()).subscribe(response => {
        Swal.fire({
          title: '',
          text: 'Job updated successfully!',
          icon: 'success',
          confirmButtonColor: '#38a745',
        }).then(() => {
          this.router.navigate(['job-details'], {
            state: { data: JSON.stringify(response['job']) }
          });
        });
      }, error => {
        Swal.fire({
          title: 'An error occurred',
          html: 'Please contact us at: <b>info@meshincentre.com</b> or via the contact form',
          icon: 'error',
          confirmButtonColor: '#f37b7b',
        }).then(() =>
          this.router.navigateByUrl('contact')
        );
      })
  }
}
