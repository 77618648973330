import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SendMailService {
  headers: HttpHeaders = new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' });

  constructor(private http: HttpClient) { }

  sendInquiry(data: any): Observable<any> {
    return this.http.post(
      'send-inquiry',
      data)
      .pipe(map( (response: Response) => response));
  }

  sendNewsletterRegistration(data: any): Observable<any> {
    return this.http.post(
      'send-newsletter-registration',
      data)
      .pipe(map( (response: Response) => response));
  }
}
