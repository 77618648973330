<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="page-title-text">
          <h2>Edit User</h2>
          <ul>
            <li><a routerLink="/">Home</a></li>
            <li><i class="icofont-simple-right"></i></li>
            <li>Edit User</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="dashboard-area pt-5">
  <div class="container">
    <form [formGroup]="EditUserForm" (ngSubmit)="onSubmit()">
      <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
          <div class="create-information">
            <h3>Basic Information</h3>
            <h4>Contact Person</h4>
            <div class="row availability">
              <div *ngIf="isCandidate" class="col-lg-6">
                <div class="form-group">
                  <label>Availability</label>
                  <app-select
                    [defaultText]="'--- Select Availability ---'"
                    [selectTexts]="selectLabels"
                    [selectClass]="'availability'"
                    [invalidInput]="submitted && f.availability.invalid"
                    formControlName="availability"
                    [formGroup]="EditUserForm"
                    (valueChangedEvent)="onHandleSelect($event, 'availability')"
                  >
                  </app-select>
                  <div *ngIf="submitted && f.availability?.errors?.required" class="is-invalid">
                    Availability required
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="form-group">
                  <label>Account Status</label>
                  <app-select
                    [defaultText]="'--- Select Status ---'"
                    [selectTexts]="selectStatus"
                    [selectClass]="'active'"
                    [invalidInput]="submitted && f.active.invalid"
                    formControlName="active"
                    [formGroup]="EditUserForm"
                    (valueChangedEvent)="onHandleSelect($event, 'active')"
                  >
                  </app-select>
                  <div *ngIf="submitted && f.active?.errors?.required" class="is-invalid">
                    Account Status required
                  </div>
                </div>
              </div>

              <div *ngIf="isCandidate" class="col-lg-6">
                <div class="form-group">
                  <label>Feature Account</label>
                  <app-select
                    [defaultText]="'--- Select Status ---'"
                    [selectTexts]="selectFeature"
                    [selectClass]="'feature'"
                    [invalidInput]="submitted && f.feature.invalid"
                    formControlName="feature"
                    [formGroup]="EditUserForm"
                    (valueChangedEvent)="onHandleSelect($event, 'feature')"
                  >
                  </app-select>
                  <div *ngIf="submitted && f.feature?.errors?.required" class="is-invalid">
                    Feature Status required
                  </div>
                </div>
              </div>

              <div *ngIf="!isCandidate" class="col-lg-6">
                <div class="form-group">
                  <label class="employer-account-type">Account Type</label>
                  <input
                    type="text"
                    class="form-control"
                    [attr.disabled]="true"
                    formControlName="accountType"
                  >
                  <div *ngIf="submitted && f.accountType.errors" class="is-invalid">
                    <div *ngIf="f.accountType.errors.required">Name required</div>
                  </div>
                </div>
              </div>

            </div>

            <div class="row">
              <div *ngIf="isCandidate" class="col-lg-6">
                <div class="form-group">
                  <label>Account Type</label>
                  <input
                    type="text"
                    class="form-control"
                    [attr.disabled]="true"
                    formControlName="accountType"
                  >
                  <div *ngIf="submitted && f.accountType.errors" class="is-invalid">
                    <div *ngIf="f.accountType.errors.required">Name required</div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <label>Firstname</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="firstName"
                  >
                  <div *ngIf="submitted && f.firstName.errors" class="is-invalid">
                    <div *ngIf="f.firstName.errors.required">Firstname required</div>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="form-group">
                  <label>Lastname</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="lastName"
                  >
                  <div *ngIf="submitted && f.lastName.errors" class="is-invalid">
                    <div *ngIf="f.lastName.errors.required">Lastname required</div>
                  </div>
                </div>
              </div>

              <div class="col-lg-6" *ngIf="!isCandidate">
                <div class="form-group">
                  <label>Company</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="companyName"
                  >
                  <div *ngIf="submitted && f.companyName.errors" class="is-invalid">
                    <div *ngIf="f.companyName.errors.required">Company Name required</div>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="form-group">
                  <label>Email</label>
                  <input
                    type="email"
                    [attr.disabled]="true"
                    class="form-control"
                    formControlName="email"
                  >
                </div>
              </div>

              <div class="col-lg-6">
                <div class="form-group">
                  <label>Date of Birth</label>
                  <input
                    type="text"
                    class="form-control"
                    [attr.disabled]="true"
                    formControlName="birthDate"
                  >
                </div>
              </div>

              <div class="col-lg-6">
                <div class="form-group">
                  <label>Telephone</label>
                  <ngx-intl-tel-input
                    [preferredCountries]="preferredCountries"
                    [enableAutoCountrySelect]="true"
                    [enablePlaceholder]="true"
                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                    [selectedCountryISO]="CountryISO.Uganda"
                    [phoneValidation]="true"
                    [customPlaceholder]="772123456"
                    [numberFormat]="772123456"
                    name="phone"
                    formControlName="telephone"
                  >
                  </ngx-intl-tel-input>
                  <div *ngIf="submitted && f.telephone.errors" class="is-invalid">
                    <div *ngIf="f.telephone.errors.required">telephone required</div>
                    <div *ngIf="f.telephone.errors?.validatePhoneNumber">Invalid Number</div>
                  </div>
                </div>
              </div>

              <div *ngIf="!isCandidate" class="col-lg-6">
                <div class="form-group">
                  <label>Job Title</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="jobTitle"
                  >
                  <div *ngIf="submitted && f.jobTitle.errors" class="is-invalid">
                    <div *ngIf="f.jobTitle.errors.required">Job Title required</div>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="form-group">
                  <label>Address:</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="address"
                  >
                  <div *ngIf="submitted && f.address.errors" class="is-invalid">
                    <div *ngIf="f.address.errors.required">Address required</div>
                  </div>
                </div>
              </div>

              <div *ngIf="!isCandidate" class="col-lg-6">
                <div class="form-group">
                  <label>Line of Work:</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="lineOfWork"
                  >
                  <div *ngIf="submitted && f.lineOfWork.errors" class="is-invalid">
                    <div *ngIf="f.lineOfWork.errors.required">Line of work required</div>
                  </div>
                </div>
              </div>

              <div class="col-lg-12">
                <div class="form-group">
                  <label>About Profile</label>
                  <textarea
                    id="your_message"
                    class="form-control"
                    rows="8" formControlName="aboutMe"
                  >
                  </textarea>
                  <div *ngIf="submitted && f.aboutMe.errors" class="is-invalid">
                    <div *ngIf="f.aboutMe.errors.required">About Me required</div>
                    <div *ngIf="f.aboutMe.errors.minlength">Must be at least 100 characters</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="create-education" *ngIf="isCandidate">
            <div class="create-education-wrap">
              <div class="create-education-left">
                <h3>Highest Education</h3>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-6">
                <div class="form-group">
                  <label>Title: *</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="educationTitle"
                  >
                  <div *ngIf="submitted && f.educationTitle.errors" class="is-invalid">
                    <div *ngIf="f.educationTitle.errors.required">Education Title required</div>
                    <div *ngIf="f.educationTitle.errors.minlength">Must be at least 6 characters</div>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="form-group">
                  <label>Qualification: *</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="qualification"
                  >
                  <div *ngIf="submitted && f.qualification.errors" class="is-invalid">
                    <div *ngIf="f.qualification.errors.required">Qualification required</div>
                    <div *ngIf="f.qualification.errors.minlength">Must be at least 6 characters</div>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="form-group">
                  <label>Institute: *</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="institute"
                  >
                  <div *ngIf="submitted && f.institute.errors" class="is-invalid">
                    <div *ngIf="f.institute.errors.required">Institute required</div>
                    <div *ngIf="f.institute.errors.minlength">Must be at least 6 characters</div>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="form-group">
                  <label>Year: *</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="year"
                  >
                  <div *ngIf="submitted && f.year.errors" class="is-invalid">
                    <div *ngIf="f.year.errors.required">Year required</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="create-skills" *ngIf="isCandidate">
            <div class="create-skills-wrap">
              <div class="create-skills-left">
                <h3>Skill</h3>
              </div>
            </div>
            <div class="skill-qualification-container" formArrayName="skills"
              *ngFor="let skill of skills.controls; let i = index">
              <div class="row" [formGroup]="skill">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label>{{'Title ' + (i + 1)}}</label>
                    <div class="skill-qualification">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="{{'Enter skill ' + (i + 1)}}"
                        formControlName="skillTitle"
                      >
                      <div *ngIf="submitted && f.skills?.status === 'INVALID'" class="is-invalid">
                        {{'Skill title ' + (i + 1) + ' required' }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="skill-level">
                    <label>{{'Skill Level ' + (i + 1)}}</label>
                    <app-slider
                      [id]="'skillLevelSlider' + i"
                      [bubbleId]="'skillLevelId' + i"
                      [minLabel]="sliderMin"
                      [maxLabel]="sliderMax"
                      [min]="sliderMin"
                      [max]="sliderMax"
                      [value]="sliderMin"
                      formControlName="skillLevel">
                    </app-slider>
                  </div>
                </div>
                <div class="remove-btn-container">
                  <button *ngIf="i > 0" class="white-to-green-simulated-hover-btn" (click)="removeElement(i, 'skills')">
                    <i class="icofont-ui-close"></i> Remove
                  </button>
                </div>
              </div>
            </div>
            <button class="green-white-simulated-hover-btn" (click)="addElement($event, 'skills')">
              <i class="icofont-ui-add"></i> Add Skill
            </button>
          </div>
          <div *ngIf="submitted && EditUserForm.invalid" class="alert alert-danger" role="alert">
            <i class="icofont-warning-alt"></i>
            The form contains one or more invalid input.
          </div>
          <div class="text-left">
            <button type="submit" class=" btn green-gradient-hover"> Save </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
