import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class UtilsService {
  /**
   * Formats the value to millions, billions, etc
   * @param value The number to be formatted
   * @return string
   */
  formatMoney(value: number): any {
    return Math.abs(Number(value)) >= 1.0e+15
      ? (Math.abs(Number(value)) / 1.0e+15).toFixed(2) + 'Q'
      : Math.abs(Number(value)) >= 1.0e+12
        ? (Math.abs(Number(value)) / 1.0e+12).toFixed(2) + 'T'
        : Math.abs(Number(value)) >= 1.0e+9
          ? (Math.abs(Number(value)) / 1.0e+9).toFixed(2) + 'B'
          : Math.abs(Number(value)) >= 1.0e+6
            ? (Math.abs(Number(value)) / 1.0e+6).toFixed(2) + 'M'
            : Math.abs(Number(value)) >= 1.0e+3
              ? (Math.abs(Number(value)) / 1.0e+3) + 'K'
              : Math.abs(Number(value));
  }

  /**
   * Formats the date
   * @param date contains parts of a date
   * @return a date
   */
  formatDate(date: any): Date {
    return Array.isArray(date) ?
      (new Date(date[1] + '.' + date[2] + '.' + date[0])) :
      (new Date(date.month + '.' + date.day + '.' + date.year));
  }

  /**
   * Reverses the date to format
   * @param date contains parts of a date
   * @return a date
   */
  reverseDate(date: string[]): string {
    if (Array.isArray(date)) {
      return date.reverse().join('-');
    }
  }

  /**
   * Get the corresponding month
   * @param index for the month
   * @return a date
   */
  getMonth(index: number): string {
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
    ];
    return months[index];
  }

  /**
   * Get the corresponding month index
   * @param month for the month
   * @return an index
   */
  getMonthIndex(month: string): number {
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
    ];
    return months.indexOf(month);
  }

  /**
   * Get the corresponding month short text
   * @param index for the month
   * @return a date
   */
  getMonthShort(index: number): string {
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'
    ];
    return months[index];
  }

  /**
   * Gets the difference of days between two dates
   * @param firstDate for comparison
   * @param secondDate for comparison
   * @return the number of days
   */
  daysLeft(firstDate: Date, secondDate: Date): number {
    const timeDifference = secondDate.getTime() - firstDate.getTime();
    return timeDifference / (1000 * 3600 * 24);
  }

  /**
   * Gets the minimum date
   * @return the date
   */
  getMinDate(): string {
    return new Date().toISOString().split('T')[0];
  }

  /**
   * Gets the maximum date
   * @return the date
   */
  getMaxDate(): string {
    const currentDate = new Date();
    return new Date(currentDate.setDate(currentDate.getDate() + 29))
      .toISOString().split('T')[0];
  }

  /**
   * Sums up the value of the same properties and changes the date to the current date
   * @param data consists of the item data
   * @return updated data array
   */
  sumSameItemData(data: any) {
    return data.reduce((acc, current) => {
      const date = new Date();
      const index = acc.findIndex(el => el['item_category'] === current['item_category']);
      if (index !== -1) {
        acc[index]['item_quantity'] += current['item_quantity'];
        acc[index]['item_cost'] += current['item_cost'];
        acc[index]['item_date'] =
          `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
      } else {
        acc.push(current);
      }
      return acc;
    }, []);
  }
}
