import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class SelectLabelsService {
  /**
     * Gets the candidate profile status options
     * @return an array of texts
     */
  getOnlineStatus() {
    return [
      'Online',
      'Review'
    ];
  }

  /**
   * Gets the candidate profile availability options
   * @return an array of texts
   */
  getAvailability() {
    return [
      'Available',
      'Not available'
    ];
  }

  /**
   * Gets the job category options
   * @return an array of texts
   */
  getJobCategory() {
    return [
      'IT & Technology',
      'Technical Support',
      'Restaurant Services',
      'Real Estate Services',
      'Finance & Banking',
      'Home Support Services',
      'Business Management Services',
      'Other',
    ];
  }

  /**
   * Gets the job place of work options
   * @return an array of texts
   */
  getJobPlaceOfWork() {
    return [
      'Remote',
      'On site',
      'Hybrid',
    ];
  }

  /**
   * Gets the job location options
   * @return an array of texts
   */
  getJobLocation() {
    return [
      'Kampala',
      'Entebbe',
      'Kabarole',
      'Masaka',
      'Jinja',
      'Gulu',
    ];
  }

  /**
   * Gets the job type options
   * @return an array of texts
   */
  getJobType() {
    return [
      'Full time',
      'Part time',
      'Internship'
    ];
  }

  /**
   * Gets the job status options
   * @return an array of texts
   */
  getJobStatus() {
    return [
      'In review',
      'Reviewed',
    ];
  }

  /**
   * Gets the job feature options   
   * @return an array of texts
   */
  getJobFeature() {
    return [
      'Featured',
      'Not Featured'
    ];
  }

}