import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { JobService } from '../../services/job.service';
import { AuthenticationService } from '../../services/auth.service';
import { UserProfileService } from '../../services/user.service';
import { ApplicationService } from '../../services/application.service';
import { UtilsService } from '../../services/utils.service';
import { BlogService } from '../../services/blog.service';
import { Job } from '../../models/job.model';
import { User } from '../../models/user.model';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/index';
import { first, mergeMap } from 'rxjs/operators';

declare const $;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  topJobs: Job[] = [];
  searchForm: FormGroup;
  filteredJobs: any;
  titleKeyword: string = 'title';
  cityKeyword: string = 'location';
  submitted: boolean;
  recentJobs: Job[] = [];
  isCandidate: Observable<boolean>;
  employers: User[] = [];
  appliedToJobs: Job[];
  isAdmin: Observable<boolean>;
  user: User;
  candidates: User[];
  jobs: Job[] = [];
  isLoggedIn: Observable<boolean>;
  blogPosts: any[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private jobService: JobService,
    private userProfileService: UserProfileService,
    private authenticationService: AuthenticationService,
    private applicationService: ApplicationService,
    private utilsService: UtilsService,
    private blogService: BlogService
  ) { }

  ngOnInit(): void {
    this.isLoggedIn = this.authenticationService.isLoggedIn();
    this.isCandidate = this.authenticationService.isCandidateLoggedIn();
    this.isAdmin = this.authenticationService.isAdminLoggedIn();
    this.searchForm = this.formBuilder.group({
      searchInput: ['',],
      location: ['',],
      title: [''],
    });
    this.getTopJobs();
    this.isLoggedIn.subscribe(isUser => {
      if (isUser) {
        this.getTopCandidates();
      }
    });
    this.filteredJobs = this.topJobs;
    this.getBlogPosts();
  }

  getDifferenceInDays(job: Job) {
    const currentDaysAfterPost = this.utilsService.daysLeft(
      new Date(job.created_at.split(' ')[0]), new Date());
    const days = this.utilsService.daysLeft(
      new Date(job.created_at.split(' ')[0]), new Date(job.expiry_at));
    const daysLeft = Math.round(days - currentDaysAfterPost);
    return daysLeft > 0 ? `${daysLeft} days left` : `A few hours left`;
  }

  getBlogPosts() {
    this.blogService.getBlogPosts()
      .pipe(first()).subscribe(posts => {
        this.blogPosts = posts as any[]
      });
  }

  loadSlider() {
    // Required here in order to load the profile slider after the component initializes
    setTimeout(() => {
      $('.profile-slider').owlCarousel({
        loop: $('.profile-item img').length > 4,
        margin: 0,
        nav: false,
        dots: true,
        smartSpeed: 1000,
        autoplay: true,
        autoplayTimeout: 4000,
        autoplayHoverPause: true,
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 2,
          },
          1000: {
            items: 4,
          }
        }
      });
    }, 1000);
  }

  get f() {
    return this.searchForm.controls;
  }

  getTopJobs() {
    this.jobService.getJobs().pipe(
      mergeMap(response => {
        this.recentJobs = response['jobs'];
        this.filteredJobs = response['jobs'];
        if (this.recentJobs.length > 4) {
          //   this.recentJobs = this.recentJobs.slice(0, 4);
          this.recentJobs = this.recentJobs.slice(1).slice(-4);
        }
        if (this.authenticationService.currentUser()) {
          this.setAppliedToJobs(this.recentJobs);
        }
        return this.userProfileService.getEmployers()
      })
    ).pipe(first()).subscribe(response => {
      this.employers = response['users'];
      this.loadSlider();
    })
  }

  getTopCandidates() {
    this.userProfileService.getCandidates().subscribe(response => {
      this.candidates = response['users'].filter((candidate, index) =>
        index < 20 && candidate.is_featured);
    });
  }

  goToCandidateDetails(user: User) {
    this.router.navigate(['candidate-details'], {
      state: { data: JSON.stringify(user) }
    });
  }

  goToJobDetails(job: Job) {
    this.router.navigate(['job-details'], {
      state: { data: job }
    });
  }

  goToBlogDetails(id: number) {
    this.router.navigate(['blog-details'], {
      state: {data: id}
    });
  }

  goToCompanyDetails(id: number) {
    if (this.authenticationService.currentUser()) {
      this.userProfileService.getJobEmployer(id)
        .pipe(first()).subscribe(response => {
          this.router.navigate(['company-details'], {
            state: { data: JSON.stringify(response['user'][0]) }
          });
        });
    } else {
      this.router.navigate(['authenticate/login']);
    }
  }

  setAppliedToJobs(jobs: Job[]) {
    this.applicationService.getMyApplications().pipe(first())
      .subscribe(response => {
        this.appliedToJobs = response['applications'].map(application => {
          return jobs.find(job => {
            if (job.application_ids && job.application_ids.some(id => id === application.id)) {
              return job.id;
            }
          });
        });
      })
  }

  getLogo(job: Job) {
    return this.employers.find(employer => employer.id === job.user_id)
  }

  isApplied(job: Job) {
    if (this.appliedToJobs) {
      return this.appliedToJobs.some(appliedJob => appliedJob && appliedJob.id === job.id)
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.searchForm.invalid) {
      return;
    }
    this.jobService.searchJobs(this.searchForm.value)
      .subscribe(response => {
        this.filteredJobs = response;
        this.router.navigate(['job-list'], {
          state: { data: response }
        });
      });
  }

  onClickCategory(category: string) {
    this.router.navigate(['job-list'], {
      state: {
        data: this.filteredJobs.filter(job =>
          job.category === category)
      }
    });
  }

  onSelect(filteredJob: Job, searchType: string) {
    const isTitle = searchType === 'title';
    this.f.searchInput.setValue(isTitle ? filteredJob.title : filteredJob.location);
    this.onSubmit();
  }

  onChangeSearch(titleKeyword: string) {
    this.f.searchInput.setValue(titleKeyword);
  }

  onChangeSearchLocation(cityKeyword: string) {
    this.f.searchInput.setValue(cityKeyword);
  }

  onCleared() {
    this.filteredJobs = this.topJobs;
  }
}
