<div class="account-pages">
  <div class="container">
    <div class="row pt-5 justify-content-center">
      <div class="col-xl-10 pt-5 pb-5 forgot-password-area">
        <app-card [bodyClass]="'pt-5'">
          <div class="forgot-password-area" body>
            <div class="row">
              <div class="col-6 forgot-password-form">
                <h6 class="h5 mb-0 mt-4 forgot-password-label ">Enter Your Email</h6>
                <p class="text-muted mt-2">
                  Your email is required in order to proceed with the password reset process.
                </p>
                <form
                  action="#"
                  class="authentication-form"
                  name="resetForm"
                  [formGroup]="forgotPasswordForm"
                  (ngSubmit)="onSubmit()"
                  novalidate>

                  <!-- <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>
                  <ngb-alert type="success" *ngIf="success" [dismissible]="false">{{ success }}</ngb-alert> -->

                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-12">
                        <app-input-text
                          [textLabel]="'Email: *'"
                          [type]="'email'"
                          [invalidInput]="submitted && f.email.invalid"
                          formControlName="email"
                          [formGroup]="forgotPasswordForm"
                        ></app-input-text>
                        <div *ngIf="submitted && f.email.errors" class="is-invalid">
                          <div *ngIf="f.email.errors.required">Email is required</div>
                          <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group mb-0 text-center">
                    <button class="btn forgot-password-btn" type="submit"> Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </app-card>
      </div>
    </div>
  </div>
</div>
