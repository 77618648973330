import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { mergeMap } from 'rxjs/operators'
import { AuthenticationService } from '../../services/auth.service';
import { UserProfileService } from  '../../services/user.service';
import { Role } from '../../models/role.model';
import { Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { CountryISO } from 'ngx-intl-tel-input';
import { SearchCountryField } from 'ngx-intl-tel-input';
import { PhoneNumberFormat } from 'ngx-intl-tel-input';
import { User } from '../../models/user.model';

declare const $;

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss']
})
export class CreateAccountComponent implements OnInit {
  @ViewChild('select', {static: true}) select: ElementRef;
  createAccountForm: FormGroup;
  isPasswordStrong: boolean;
  isAllowedImage = true;
  isCandidate = true;
  submitted = false;
  cvImage: string;
  uploadedImage: File;
  uploadedCV: File;
  imageChangedEvent: any = '';
  tempCroppedImage: string;
  croppedImage: string;
  canvasRotation = 0;
  rotation?: number;
  translateH = 0;
  translateV = 0;
  scale = 1;
  aspectRatio = 4 / 3;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  imageURL?: string;
  loading: boolean = false;
  isPasswordHidden: boolean = true;
  isConfirmPasswordHidden: boolean = true;
  selectLabels = ['Available', 'Not available'];
  candidateControlNames = [
    'availability',
    'profession',
    'cv',
    'educationTitle',
    'qualification',
    'institute',
    'year'
  ];
  employerControlNames = [
    'companyName',
    'lineOfWork',
    'jobTitle',
    'responsibility'
  ];
  separateDialCode = false;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
	PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [
		CountryISO.Uganda
	];

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userProfileService: UserProfileService,
    private authenticationService: AuthenticationService
  ) {
  }

  ngOnInit(): void {
    this.createAccountForm = new FormGroup({
      account: new FormControl(Role.Candidate, Validators.required),
      availability: new FormControl('', Validators.required),
      profession: new FormControl('', Validators.required),
      companyName: new FormControl('', Validators.required),
      lineOfWork: new FormControl('', Validators.required),
      gender: new FormControl('male', Validators.required),
      cv: new FormControl('', Validators.required),
      firstName: new FormControl('', [Validators.required, Validators.minLength(2)]),
      lastName: new FormControl('', [Validators.required, Validators.minLength(2)]),
      email: new FormControl('', [
        Validators.required,
        Validators.email,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
      birthDate: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required, Validators.minLength(6)]),
      confirmPassword: new FormControl('', Validators.required),
      telephone: new FormControl('', Validators.required),
      address: new FormControl('', Validators.required),
      jobTitle: new FormControl('', Validators.required),
      responsibility: new FormControl('', Validators.required),
      aboutMe: new FormControl('', [Validators.required, Validators.minLength(100)]),
      educationTitle: new FormControl('', Validators.required),
      qualification: new FormControl('', Validators.required),
      institute: new FormControl('', Validators.required),
      year: new FormControl('', Validators.required),
      // skillName: ['', [Validators.required, Validators.minLength(6)]], TODO: Will be completed from the profile page by the user during interview
      terms: new FormControl('', Validators.required)
    }, this.checkPasswords);
    this.setSelectValue($('select').val());

    this.handleControlNames();
  }

  setSelectValue(data) {
    if (data) {
      this.f.availability.setValue(data.value);
    } else {
      this.f.availability.setValue(data);
    }
  }

  handleControlNames() {
    this.createAccountForm.patchValue({});
    this.isCandidate = this.f.account.value === Role.Candidate;
    if (!this.createAccountForm.contains('availability')) {
      this.candidateControlNames.forEach(control =>
        this.createAccountForm.addControl(control, new FormControl('', Validators.required)));
    }
    if (this.isCandidate) {
      this.employerControlNames.forEach(control =>
        this.createAccountForm.removeControl(control));
    } else {
      this.employerControlNames.forEach(control =>
        this.createAccountForm.addControl(control, new FormControl('', Validators.required)));
      this.candidateControlNames.forEach(control =>
        this.createAccountForm.removeControl(control));
    }
  }

  uploadCV() {
    const link = document.querySelector('.cv-upload') as HTMLElement;
    link.click();
  }

  hidePassword(isPassword: boolean) {
    if (isPassword) {
      this.isPasswordHidden = !this.isPasswordHidden;
    } else {
      this.isConfirmPasswordHidden = !this.isConfirmPasswordHidden;
    }

  }

  get f() {
    return this.createAccountForm.controls;
  }

  /**
   * Checks for same passwords
   * @param formGroup element
   * @return notSame: boolean
   */
  checkPasswords(formGroup: FormGroup) {
    const pass = formGroup.get('password').value;
    const confirmPass = formGroup.get('confirmPassword').value;
    return pass === confirmPass ? null : {notSame: true};
  }

  /**
   * Sets the strength of the password
   * @param strength of the password
   * @return password strength: boolean
   */
  passwordStrong(strength: boolean) {
    this.isPasswordStrong = strength;
  }

  onImageChange(event: any): void {
    this.loading = true;
    this.imageChangedEvent = event;
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if (file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/jpg') {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        this.isAllowedImage = true;
        reader.onload = () => {
          const img = new Image();
          img.src = reader.result as string;
          img.onload = () => {
            if ((img.naturalWidth <= 400) && (img.naturalHeight <= 400) && file.size < 2000000) {
              this.isAllowedImage = true;
              this.croppedImage = img.src
            } else {
              this.isAllowedImage = false;
              this.openModal();
            }
          };
        }
      }
      this.uploadedImage = event.target.files[0];
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.tempCroppedImage = event.base64;
  }

  cropImage() {
    this.croppedImage = this.tempCroppedImage
    this.isAllowedImage = true;
    this.onCloseHandled();
  }

  onCVChange(event: any): void {
    if (event.target.files && event.target.files[0]) {
      this.cvImage = event.target.files[0].name;
      this.uploadedCV = event.target.files[0];
    }
  }

  imageLoaded() {
    this.showCropper = true;
  }

  cropperReady(sourceImageDimensions: Dimensions) {
    this.loading = false;
  }

  loadImageFailed() {
    console.error('Load image failed');
  }

  openModal() {
    document.querySelector('.modal').classList.add('is-open');
    document.querySelector('.modal-overlay').classList.add('is-open');
  }

  onCloseHandled() {
    document.querySelector('.modal').classList.remove('is-open');
    document.querySelector('.modal-overlay').classList.remove('is-open');
  }

  rotateLeft() {
    this.loading = true;
    setTimeout(() => { // Use timeout because rotating image is a heavy operation and will block the ui thread
      this.canvasRotation--;
      this.flipAfterRotate();
    });
  }

  rotateRight() {
    this.loading = true;
    setTimeout(() => {
      this.canvasRotation++;
      this.flipAfterRotate();
    });
  }

  moveLeft() {
    this.transform = {
      ...this.transform,
      translateH: ++this.translateH
    };
  }

  moveRight() {
    this.transform = {
      ...this.transform,
      translateH: --this.translateH
    };
  }

  moveTop() {
    this.transform = {
      ...this.transform,
      translateV: ++this.translateV
    };
  }

  moveBottom() {
    this.transform = {
      ...this.transform,
      translateV: --this.translateV
    };
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
    this.translateH = 0;
    this.translateV = 0;
  }

  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV
    };
  }

  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }

  zoomOut() {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  zoomIn() {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  toggleContainWithinAspectRatio() {
    this.containWithinAspectRatio = !this.containWithinAspectRatio;
  }

  updateRotation() {
    this.transform = {
      ...this.transform,
      rotate: this.rotation
    };
  }

  toggleAspectRatio() {
    this.aspectRatio = this.aspectRatio === 4 / 3 ? 16 / 5 : 4 / 3;
  }

  onRemoveImge() {
    this.croppedImage= null;
  }

  /**
   * On submit form
   */
  onSubmit() {
    const formData: User = Object.assign({}, this.createAccountForm.value);
    const formInfo = new FormData();
    this.submitted = true;
    if (this.createAccountForm.invalid ||
      !this.f.terms.value ||
      !this.isPasswordStrong) {
      return;
    }
    formData.telephone = this.f.telephone.value.e164Number;
    formInfo.append('image', this.uploadedImage, this.uploadedImage.name);
    if (this.isCandidate) {
      formInfo.append('cv', this.uploadedCV, this.uploadedCV.name);
    }
    this.authenticationService.register(formData).pipe(
      mergeMap(response =>
        this.userProfileService.uploadDocuments(formInfo, response['id']))
    ).subscribe(data => {
        Swal.fire({
          title: '',
          text: 'Your Account is Created!',
          icon: 'success',
          confirmButtonColor: '#38a745',
        }).then(() => {
          if (data['message'] === 'Documents uploaded!') {
            this.sendVerification(data['id']);
          }
        });
      },
      error => {
        Swal.fire({
          title: 'An error occurred',
          html: 'Please contact us at: <b>info@meshincentre.com</b> or via the contact form',
          icon: 'error',
          confirmButtonColor: '#f37b7b'
        }).then(() =>
          this.router.navigateByUrl('contact'));
      });
  }

  sendVerification(id) {
    this.authenticationService.sendVerification(id)
      .subscribe(response => {
          this.router.navigate(['authenticate/confirm']);
        },
        error => {
          Swal.fire('Oops...', 'Sending activation code failed', 'error');
        });
  }
}
