import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Job } from '../models/job.model';

@Injectable({providedIn: 'root'})
export class JobService {
  headers = new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'});

  constructor(
    private http: HttpClient,
  ) {}

  /**
   * Get all jobs
   */
  getJobs() {
    return this.http.get(
      'job',
      {headers: this.headers})
      .pipe(map(response => response));
  }

  /**
   * Search the jobs
   *
   * @param search
   */
  searchJobs(search: Job) {
    return this.http.post(
      'search-jobs',
      search,
      {headers: this.headers})
      .pipe(map(response => response));
  }

  /**
   * Post a job
   *
   * @param job
   */
  postJob(job: Job) {
    return this.http.post(
      'job',
      job,
      {headers: this.headers})
      .pipe(map(response => response));
  }

  /**
   * Updates the job in the db
   *
   * @param jobData to edit
   * @param id to job's id
   * @returns Observable<any>
   */
  editJob(id: number, jobData: any) {
    return this.http.put(`job/${id}`, jobData);
  }

  /**
   * Deleted the job from the db
   *
   * @param id to edit
   * @returns Observable<any>
   */
  deleteJob(id: number) {
    return this.http.delete(
     `job/${id}`,
    {headers: this.headers});
  }
}
