import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BlogService } from '../../services/blog.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  blogPosts: any[] = [];

  constructor(
    private blogService: BlogService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.blogService.getBlogPosts()
      .pipe(first()).subscribe(posts => {
      this.blogPosts = posts as any[]
    });
  }

  goToBlogDetails(id: number) {
    this.router.navigate(['blog-details'], {
      state: {data: id}
    });
  }
}
